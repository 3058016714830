import { Component, OnInit } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { LoginService } from './services/login.service';
import { UserService } from './services/UserService';
import { User } from 'dto/UserModel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'MSAL - Angular 6 Sample App';
  isIframe = false;
  loggedIn = false;
  user: string;
  private userDetails = {} as User;

  constructor(private broadcastService: BroadcastService, private authService: MsalService,private loginService: LoginService,private userService: UserService) { }

  ngOnInit() {
    
    this.isIframe = window !== window.parent && !window.opener;
    this.checkoutAccount();

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkoutAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response);
      console.log(response.idToken.rawIdToken);
      // let value = this.retrieve();
      console.log(JSON.parse(localStorage.getItem('user')));
      this.getUserDetais();
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
    
    this.getUserDetais();
  }


  

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }



  // public getUserDetais() {
  //   this.loginService.loginDetails().subscribe(item => {
  //     console.log("login",item);
  //     this.userDetails=item;
  //     this.userService.setUser=this.userDetails;
  //     this.userService.emitConfig(item);
  //     localStorage.setItem("user",JSON.stringify(this.userDetails) );
  //     console.log("get user",this.userService.getUser);
  //   });
  // }
  public getUserDetais() {
    this.loginService.loginDetails().subscribe({
  next: (data)=>{
    this.userDetails = data;
        this.userService.setUser=this.userDetails;
        this.userService.emitConfig(data);
        localStorage.setItem("user",JSON.stringify(this.userDetails) );
    console.log(data);
  },
  error:(err:Error)=>{
    console.log(err);
    this.logout();
  }

});
}



  login() {
      this.authService.loginRedirect();
  }

  logout() {
    this.authService.logout();
  }
}
