import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/services/login.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss']
})
export class LoginComponentComponent implements OnInit {

  username: string;
  password: string;

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit() {
  }

  public login(){
    var unameAndpwd = this.username + "," + this.password;
    this.loginService.login(unameAndpwd).subscribe(item => {
      var response ;
        if(item === true){
          this.router.navigate(['/dashboard']);
        } else{
      }
    });
  }


}
