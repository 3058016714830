import { Component, OnInit } from '@angular/core';
import { MenuService } from 'app/menu/menu.service';
import { TabViewService } from 'app/tab-view/tab-view.service';
import { ModelSeries } from 'dto/ModelSeries';

@Component({
  selector: 'app-datei-zuordnung',
  templateUrl: './datei-zuordnung.component.html',
  styleUrls: ['./datei-zuordnung.component.scss']
})
export class DateiZuordnungComponent implements OnInit {
  dateiZurdng: boolean;
  baureiheRes: any[] = [];
  baureiheList: ModelSeries[] = [];
  selectedBaureihes: ModelSeries[] = [];
  baureiheSelRes: any[] = [];
  baureiheSelList: ModelSeries[] = [];
  constructor(private menuService: MenuService, private tabViewService: TabViewService) {
    this.menuService.dateiZuord.subscribe(item => {
      this.getDateiBaureiheData();
    });

  }

  ngOnInit() {
  }
  getDateiBaureiheData() {
    this.dateiZurdng = true;
    this.tabViewService.getDateiBaureiheData().subscribe(res => {
      this.baureiheRes = res.data;
      var baureiheRes: ModelSeries[] = [];
      this.baureiheRes.forEach(element => {
        var bauvar: ModelSeries = new ModelSeries();
    bauvar.id = element.id['baureihenId'];
    bauvar.name = element.benennung;
        baureiheRes.push(bauvar);
      });
      this.baureiheList = baureiheRes;
    });
    this.getSelectedBaureiheData();
  }

  getSelectedBaureiheData() {
    this.tabViewService.getDateiBaureiheSelData().subscribe(res => {
      this.baureiheSelRes = res.data;
      var baureiheSelRes: ModelSeries[] = [];
      this.baureiheSelRes.forEach(element => {
        var bauvar: ModelSeries = new ModelSeries();
        console.log("this is log"+bauvar);
        bauvar.id = element.id['baureihenId'];
        bauvar.name = element.benennung;
        baureiheSelRes.push(bauvar);
      });
      this.selectedBaureihes = baureiheSelRes;
    });
  }

  saveSelection() {
    this.tabViewService.saveSelectedBaureihe(this.selectedBaureihes).subscribe(
      item => {
        if(item){
          this.dateiZurdng = false;
        }
      });
  }

  closeDateiZuordPopup() {
    this.dateiZurdng = false;
  }

  selectBaureihesMethod() {
  }
}


