import { Injectable } from '@angular/core';
//import { Observable } from '../../../node_modules/rxjs';
import { Observable } from 'rxjs/Rx';
import { Constants } from '../constants';

import { HttpEvent, HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { Http, RequestOptions,Headers} from '@angular/http';

@Injectable({
    providedIn: 'root'
})
export class CsvService {

    constructor(    private https: Http,private http: HttpClient) {

    }

    httpOptions = new RequestOptions({ headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('msal.idtoken')}`}) });
    headers = new HttpHeaders()
    .set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`) 

    public uploadImportFile(file: any): Observable<any> {
        var formdata = new FormData();
        formdata.append('file', file);;

        return this.http.post(`${Constants.HOST}/importArticleText`, formdata,{headers:this.headers})
            .map((response) => response)
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }


    public uploadImportFileEk(file: any, year: any): Observable<any> {
        var formdata = new FormData();
        formdata.append('file', file);
        formdata.append('year', year);

        return this.http.post(`${Constants.HOST}/importEkPreis`, formdata,{headers:this.headers})
            .map((response) => response)
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }


    public abangeImportFile(file: any, date: any, year: any): Observable<any> {
        var formdata = new FormData();
        formdata.append('file', file);
        formdata.append('date', date);
        formdata.append('year', year);
        return this.http.post(`${Constants.HOST}/importAbgang`, formdata,{headers:this.headers})
            .map((response) => response)
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }


    public BestandImportFile(inputFile: any, date: any): Observable<any> {
        var formdata = new FormData();
        formdata.append('inputFile', inputFile);
        formdata.append('date', date);
        return this.http.post(`${Constants.HOST}/importBestand`, formdata,{headers:this.headers})
            .map((response) => response)
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }






    public csvRead(file: any): Observable<any> {

        const reader = new FileReader();
        reader.onload = file;
        reader.readAsText(file, "UTF-8");

        return Observable.create(observer => {
            reader.onload = (e) => {
                let lines = [];
                let csv: any = reader.result;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');


                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma  
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }
                        lines.push(tarr);
                    }
                }
                observer.next(lines);
            }
        });



    }
}