import { Injectable } from '@angular/core';
import { Http, Response,RequestOptions ,Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from '../../../node_modules/rxjs';

import { Kontakt, Lieferant, RaberttGruppen } from '../../dto/Lieferant';
import { HttpEvent, HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Constants } from '../constants';


@Injectable()
export class DocumentService {
  constructor(
    private http: Http, private httpClient: HttpClient
  ) { }
  httpOptions = new RequestOptions({ headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('msal.idtoken')}s`,'withCredentials': `true`}) });

  getTabDocumentById(id): Observable<any> {
    
    return this.http.get(`${Constants.HOST}/getAllDateisByID/${id}`,this.httpOptions);
  }
 
  uploadFileArticleID(id,data): Observable<any> {
      return this.http.post(`${Constants.HOST}/uploadDatei/${id}`,data,this.httpOptions);

  }

  updateFile(data): Observable<any> {
    return this.http.post(`${Constants.HOST}/updateDatei`,data,this.httpOptions);

}

  downloadFile(id):Observable<any> {
    
    return this.http.get(`${Constants.HOST}/downloadFile/${id}`,this.httpOptions);

}
deletFile(id):Observable<any> {
   
  return this.http.delete(`${Constants.HOST}/deleteFile/${id}`,this.httpOptions);

}
deletFileLink(fileid,artickeID):Observable<any> {
   
    return this.http.delete(`${Constants.HOST}/deleteFileLink/${fileid}/${artickeID}`,this.httpOptions);
  
  }

copythProsesDocument(fromArtikelName,fromArtikelPartNo,toArtikelID,processID){
  return this.http.get(`${Constants.HOST}/copyProcessLink/${fromArtikelName}/${fromArtikelPartNo}/${toArtikelID}/${processID}`,this.httpOptions);
}

copythALLDocument(fromArtikelName,fromArtikelPartNo,toArtikelID){
  return this.http.get(`${Constants.HOST}/copyAllLink/${fromArtikelName}/${fromArtikelPartNo}/${toArtikelID}`,this.httpOptions);
}

  
}
