import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public configObservable = new Subject<String>();
  public searchArtical = new Subject<any>();
  public rabattgruppen = new Subject<any>();
  public liefrent = new Subject<any>();
  public aufbrauch = new Subject<any>();
  public reichweit = new Subject<any>();
  public korrek = new Subject<any>();
  public ubermit = new Subject<any>();
  public preis = new Subject<any>();
  public partNum = new Subject<any>();
  public dateiZuord = new Subject<any>();
  public baureiheZuord = new Subject<any>();
  public deckung = new Subject<any>();
  public partNumAssignment = new Subject<any>();
  public ChangePriceLanguage = new Subject<any>();

  public importMenu = new Subject<any>();
  public archive = new Subject<any>();
  public email = new Subject<any>();
  public Articleuber = new Subject<any>();

  public dialog = new Subject<String>();
  public AssignStaff = new Subject<any>();

  constructor() { }


  emitConfig(val) {
    this.configObservable.next(val);
  }

  emitDialog(val) {
    this.dialog.next(val);
  }

  emiteSearchArtical() {
    this.searchArtical.next();
  }

  emiteRabattgruppen() {

    this.rabattgruppen.next();
  }

  emiteLeifrent() {
    this.liefrent.next();
  }
  emitAufbrauch() {
    this.aufbrauch.next();
  }
  emitReichweit() {
    this.reichweit.next();
  }

  emitKorrek() {
    this.korrek.next();
  }
  emitUbermit() {
    this.ubermit.next();
  }
  emitPreis() {
    this.preis.next();
  }

  emiteImport(vlaue) {
    this.importMenu.next(vlaue);
  }

  emitPartNum() {
    this.partNum.next();
  }
  emitArchive() {
    this.archive.next();
  }
  emitEmail() {
    this.email.next();
  }

  emitDateiZuord() {
    this.dateiZuord.next();
  }

  emitBaureiheZuord() {
    this.baureiheZuord.next();
  }
  emitDeckung() {
    this.deckung.next();
  }
  emitArticleUber() {
    this.Articleuber.next();
  }
  emitStaff() {
    this.AssignStaff.next();
  }

  emitPartNumAssignment(val){
    this.partNumAssignment.next(val);
  }

  changPrice(vlaue){
    this.ChangePriceLanguage.next(vlaue);
  }
}
