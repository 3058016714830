import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, from } from 'rxjs';
import { Constants } from '../constants';
import {User} from '../../dto/UserModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    private user = {} as User;
    public loggedUser = new Subject<any>();
  constructor(private http: HttpClient) {
    
   }

   get getUser(): User {
    return this.user;
}

set setUser(userDetails: User) {
    this.user=userDetails;
}

emitConfig(val) {
  this.loggedUser.next(val);
}
}
