import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { ArticleService } from '../services/article.service';
import {DashboardTableService} from '../component/dashboard-table/dashboard-table.service';

@Component({
  selector: 'app-articleuber',
  templateUrl: './articleuber.component.html',
  styleUrls: ['./articleuber.component.scss']
})

export class ArticleuberComponent implements OnInit {
  ArticleUber: boolean = false;
  noarticle: boolean = false;
  benuresfalse: boolean = false;
  beneuid: boolean = false;
  benurestrue: boolean = false;
  articleid = null;
  productmanager: any[];
  productmngervalues: any[];
  selectedproductmanagers: any;
  userdetl: any;
  constructor(private menuService: MenuService, private articleService: ArticleService, 
    private dashboardTableService:DashboardTableService) {
    this.menuService.Articleuber.subscribe(value => {
      this.showDialog();
    })
  }

  ngOnInit() {

  }

  showDialog() {

    this.articleid = localStorage.getItem("article_id");
    if (this.articleid) {
      this.ArticleUber = true;
      this.productmngervalues = [{ name: '', code: '' }]
      this.articleService.getAllProductManagers().subscribe(res => {       
        res.forEach((element) => {          
          this.productmngervalues.push({ name: element, code: element });       
          this.productmanager = this.productmngervalues;
        });
        let userdetaildept = localStorage.getItem("user");
        this.userdetl = JSON.parse(userdetaildept);
        this.productmanager.forEach(element => {
          if (element.name == this.userdetl.USER_ID) {
            this.selectedproductmanagers = {
            name: this.userdetl.USER_ID, code: this.userdetl.USER_ID
            }
          }
        });
      });
    }
    else {
      this.noarticle = true;
    }
  }

  selectedpm() {
    this.beneuid = false;
  }
  submit() {
    if (this.selectedproductmanagers == null) {
      this.beneuid = true;
    }
    else if (this.selectedproductmanagers.name == "") {
      this.beneuid = true;
    }
    else {
      this.beneuid = false;
      this.articleService.chngArticleOwner(this.articleid, this.selectedproductmanagers.name).subscribe(res => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        let finalobj = JSON.parse(resJSON._body);
        if (finalobj.result == false) {
          this.benuresfalse = true;
        }
        else {
          this.benurestrue = true;
          this.dashboardTableService.loadTableData();
          this.selectedproductmanagers = null;
          this.ArticleUber = false;
          localStorage.removeItem('article_id');
        }
      });
    }
  }
}
