export const en_literals = {
    FILE: 'File',
    PART_NUMBERS:'Part Numbers',
    PRINTING_HOUSE: 'Printing House',
    IMPORT: 'Import',
    EVALUATION: 'Evaluation',
    USER_MANAGEMENT: 'User Management',
    ADMINISTRATION: 'Administration',
    HELP: 'Help'
};
