import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { Kontakt, Lieferant } from '../../dto/Lieferant';
import { LieferantService } from '../services/lieferant.service';
import { DashboardTableService } from 'app/component/dashboard-table/dashboard-table.service';
import { Router } from '@angular/router';
import { ArticleService } from 'app/services/article.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


export class DashboardMetaData {
  action: string = 'home';
  projection: string;
  criteria: any;
}

@Component({
  selector: 'app-auf-brauch',
  templateUrl: './auf-brauch.component.html',
  styleUrls: ['./auf-brauch.component.scss']
})
export class AufBrauchComponent implements OnInit {

  criteria: Search = new Search();
  projection: string[] = [];
  checkedAll: boolean = false;
  checkboxs = [
    { field: 'produktidee', value: 'produktidee', checked: true },
    { field: 'lieferantenname', value: 'Name', checked: false },
    { field: 'teilenummer', value: 'teilenummer', checked: true },
    { field: 'Zuordnung', value: 'Benennung', checked: false },
    { field: 'benennung_ETAS', value: 'NAMEDE', checked: true },
    { field: 'Erstanlagedatum', value: 'DATUMFREIGABE', checked: false },
    { field: 'Ubersetzungen', value: 'NAMEFR, NAMEES, NAMEEN', checked: false },
    { field: 'teileinfo_1', value: 'teileinfo1', checked: true },
    { field: 'PART_INFO_2', value: 'teileinfo2', checked: false },
    { field: 'mc_code', value: 'macode', checked: false },
    { field: 'Erstdisposition', value: 'Erstdisposition', checked: false },
    { field: 'liefertermine', value: 'liefertermin', checked: false }
  ];

  filteredLifrent: any[];
  lieferants: Lieferant[] = [];
  liefrentSelects: any[] = [];
  filterdColumns = [];

  date3: Date;

  aufbrauch: boolean = false;
  liefrentSelect: string;
  validId: boolean = false;
  productmngervalues: any[] = [];
  productmanagerItems: any[] = [];
  userdetl: any;
  nolieferantid:boolean=false;

  constructor(private lieferantService: LieferantService, private menuService: MenuService,
    private articleService: ArticleService, private dashboardService: DashboardTableService, 
    private router: Router,private spinnerService: Ng4LoadingSpinnerService) {
    this.menuService.aufbrauch.subscribe(value => {  
    });
    this.getproductmanager();
  }



  ngOnInit() {
    this.criteria.valueDatesList = new Date();
    this.criteria.selectedStatuslist = "A";

    var d = new Date();
    var currYear = d.getFullYear()
    var currMonth = d.getMonth();
    var currDay = d.getDate();
    let date1 = new Date(currYear-1 , currMonth , currDay);
    this.criteria.exhautionDate = date1

  }


  filterBrands(event) {
      this.lieferantService.getLiefrentByName(event.query).subscribe(item => {
      this.lieferants = item.data;
      this.liefrentSelects = this.lieferants;
      this.filteredLifrent = this.lieferants;

    });

  }

  selctLifrent(value) {
   this.validId = false;
   this.liefrentSelect = value.id.lieferantId;
   
    
  }

  focusmethod(event) {
    if (this.filteredLifrent.length == 0) {
      this.nolieferantid = true;
    }
    else {
      this.nolieferantid = false;
    }

    if (this.criteria.supplier == 0) {
      this.nolieferantid = false;
    }
  }

  filterisempty() {
    const emvale = ((this.criteria.supplier == undefined || this.criteria.supplier.length == 0) ? false : (this.filteredLifrent && this.filteredLifrent.length === 0));
    return emvale;
  }

  lieferantkeyup() {
    this.nolieferantid = false;
  }



  public getproductmanager() {
    this.articleService.getAllProductManagers().subscribe(res => {
      this.productmngervalues = [{ label: '', value: '' }]
      res.forEach((element) => {
      this.productmngervalues.push({ label: element, value: element });
      this.productmanagerItems = this.productmngervalues;
      });
      this.productmanagerItems = this.productmanagerItems.slice(1);
      let userdetaildept = localStorage.getItem("user");
      this.userdetl = JSON.parse(userdetaildept);
      this.productmanagerItems.forEach(element => {
        
        if (element.label == this.userdetl.USER_ID) {
          this.criteria.productManager = [{
            label: this.userdetl.USER_ID, value: this.userdetl.USER_ID
          }];
        }
      });
    });
  }


  search() {
    const meta = new DashboardMetaData();
    const criteria = this.criteria.clone();
    meta.action = 'NEWSEARCH';
    meta.projection = "status,pm," + this.projection.concat(this.checkboxs.filter(e => e.checked).map(e => e.value)).join(',');
    meta.criteria = criteria;
    meta.projection = meta.projection.split(",").filter(e => e.trim() !== "")
      .filter((el, i, a) => i === a.indexOf(el)).join(",");
    if (criteria.selectedAssignmentsList) {
      criteria.selectedAssignmentsList = criteria.selectedAssignmentsList.map(e => e.id);
    }

    if (criteria.branch) {
      criteria.branch = (criteria.branch as any).sid;
    }


    if (criteria.patsFamily) {
      criteria.patsFamily = (criteria.patsFamily as any).tid;
    }

    if (criteria.supplier) {
      criteria.supplier = (criteria.supplier as any).id.lieferantId;
    }

    this.spinnerService.show();
    this.dashboardService.loadTableData(meta);
    this.spinnerService.hide();
    this.onHide();

  }

  onHide() {
    this.aufbrauch = false;
    this.menuService.emitDialog('');
  }

  checkAllEvent() {
    this.checkboxs.forEach(e => { e.checked = this.checkedAll });
  }

  changeonechk() {
    this.checkedAll = this.checkboxs.filter(e => e.checked).length === this.checkboxs.length;
  }

}



class Search {
  branch: 0;
  department: 0;
  productManager: any[];
  supplier: any;
  numberOfItem: 0;
  rangeAnalysisResultOrder: any;
  selectedAssignmentsList: any[];
  patsFamily: 0;
  assortmentGroup: 0;
  selectedStatuslist: any;
  prestoSystem: false;
  valueDatesList: any;
  exhautionDate: Date;

  clone(): Search {
    return Object.assign({}, this) as Search;
  }
}
