import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaureihePostDto } from 'dto/BaureihePosDto';
import { BauvariantentypPostDto } from 'dto/BauvariantentypPostDto';
import { BauvariantePostDto } from 'dto/BauvariantePostDto';
import { Constants } from '../constants';

@Injectable()
export class ZuordnungService {
  constructor (
    private http: HttpClient
  ) {}

  
  createBaureihe(data:BaureihePostDto) {
    return this.http.post<BaureihePostDto>( `${Constants.HOST}/createBaureihe/`, data )
    .map(( response ) => response )
     ;
   }

   createBauvariantetyp(data:BauvariantentypPostDto) {
    return this.http.post<BauvariantentypPostDto>( `${Constants.HOST}/createBauvariantentyp/`, data )
    .map(( response ) => response )
     ;
   }

   createBauvariante(data:BauvariantePostDto) {
    return this.http.post<BauvariantePostDto>( `${Constants.HOST}/createBauvariante/`, data )
    .map(( response ) => response )
     ;
   }
}