import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atricle-status',
  templateUrl: './atricle-status.component.html',
  styleUrls: ['./atricle-status.component.scss']
})
export class AtricleStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
