import { Directive, ElementRef, Input , SimpleChanges, OnChanges} from '@angular/core';
import { UserService } from './services/UserService';
import { RoleService } from './services/RoleService';


const ctrWithAssignedRole = {'tablesInput':['NUZUCO.PRODMGR','NUZUCO.SUPERUSER','NUZUCO.VIEWER'],
                               'saveOrDel':['NUZUCO.PRODMGR','NUZUCO.SUPERUSER'] };



export const isNotPermittedByController = (controllerName, role) => {
    var ctr = ctrWithAssignedRole[controllerName];
    if(ctr) {
        ctr = ctr.map(e=>e.toUpperCase());
        var r = role || '';
        r = r.split(',');
        for(var i=0;i<r.length;i++) {
            if(r[i].trim() === '') {
                continue;
            }
            if(!(ctr.indexOf(r[i]) >= 0)) {
                return true;
            }
           }
        return ;
    }
    return true;
}

export const isNotPermittedByRole = (currentRole, assignedRole=[]) => {
        var assigned = assignedRole.map(e=>e.toUpperCase());
        var r = currentRole || '';
        r = r.split(',');
        for(var i=0;i<r.length;i++) {
            if(r[i].trim() === '') {
                continue;
            }
         if(assigned.indexOf(r[i]) >= 0) {
             return false;
         }
        }
        return true;
}



@Directive({
  selector: '[controllerPermission]'
})
export class AppControllerPermission implements OnChanges{


    @Input('controllerPermission') controllerName: string;
    @Input() ngModel;

    @Input()
    changeVersionCount;

    @Input()
    changeVersionSaveDel;
    currentUserRole:String;

    constructor(public el: ElementRef, public userService:UserService, private roleService : RoleService) {
    }

    public updateEl() {
        this.el.nativeElement.classList.add("disabled-ctr");
        setTimeout(()=>{            
            if( this.roleService.getArticleOwnerPM && this.roleService.getStatusOfArticle){
            }
            this.currentUserRole = this.roleService.getUserRole;
                    if(!isNotPermittedByController(this.controllerName, this.currentUserRole)) {
                     var r : any;
                    if(this.currentUserRole){
                        r = this.currentUserRole.split(',');
                            if(this.roleService.getLoggedInUserID && this.roleService.getArticleOwnerPM){
                            if(r.indexOf('NUZUCO.PRODMGR')>=0 && this.roleService.getLoggedInUserID === this.roleService.getArticleOwnerPM) {
                                this.el.nativeElement.classList.remove("disabled-ctr");
                            } 
                             if (r.indexOf('NUZUCO.SUPERUSER')>=0){
                                this.el.nativeElement.classList.remove("disabled-ctr");
                            }
                        } 
                        if(this.controllerName === 'saveOrDel'){
                            if(r.indexOf('NUZUCO.PRODMGR')>=0){
                                this.el.nativeElement.classList.remove("disabled-ctr");
                            }
                        }
                    }
            } 
             else if (isNotPermittedByController(this.controllerName, this.currentUserRole)) {
                 if(this.controllerName === 'saveOrDel'){
                     var r : any;
                     if(this.currentUserRole){
                         r = this.currentUserRole.split(',');
                     if(r.indexOf('NUZUCO.PRODMGR')>=0){
                         this.el.nativeElement.classList.remove("disabled-ctr");
                     }
                    }
                 }
             }
        },1000);
    }

    ngOnChanges(changes: SimpleChanges){
      this.updateEl();
}

}