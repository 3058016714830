import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuService } from '../menu/menu.service';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TabViewService } from '../tab-view/tab-view.service';
import { LieferantService } from '../services/lieferant.service';
import { Kontakt, Lieferant } from '../../dto/Lieferant';
import { ModelSeries } from 'dto/ModelSeries';
import { ArticleService } from '../services/article.service';
import { Router } from '@angular/router';
import { DashboardTableService, DeshboardMetaData } from '../component/dashboard-table/dashboard-table.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from "moment";


interface Status {
  mcode: object,
  name: string,
  sgid: object,
  sid: string,
  tid: object,
  type: object,
  wid: object
}
interface warengrupe {
  name: string;
  code: string;
}

interface teilef {
  name: string;
  code: string;
}

interface sgrupe {
  name: string;
  code: string;
}

interface sortgruppe {
  name: string;
  code: string;
}
@Component({
  selector: 'app-search-artical',
  templateUrl: './search-artical.component.html',
  styleUrls: ['./search-artical.component.scss']
})
export class SearchArticalComponent implements OnInit {
  criteria: Search = new Search();
  projection: string[] = [];
  checkedAll: boolean = false;
  checkboxs = [
    { field: 'produktidee', value: 'produktidee', checked: true },
    { field: 'lieferantenname', value: 'Name', checked: false },
    { field: 'teilenummer', value: 'teilenummer', checked: true },
    { field: 'Zuordnung', value: 'Benennung', checked: false },
    { field: 'benennung_ETAS', value: 'NAMEDE', checked: true },
    { field: 'Erstanlagedatum', value: 'DATUMFREIGABE', checked: false },
    { field: 'Ubersetzungen', value: 'NAMEFR, NAMEES, NAMEEN', checked: false },
    { field: 'teileinfo_1', value: 'teileinfo1', checked: true },
    { field: 'PART_INFO_2', value: 'teileinfo2', checked: false },
    { field: 'mc_code', value: 'macode', checked: false },
    { field: 'Erstdisposition', value: 'Erstdisposition', checked: false },
    { field: 'liefertermine', value: 'liefertermin', checked: false }
  ];


  showPage: boolean = true;



  cars = [];
  selectedCars1: string[] = [];
  selectedCars2: string[] = [];
  selectedabteilung: string[] = [];
  abteilung: ModelSeries[] = [];
  selectedproduct: string[] = [];
  product = [];

  selectedzuordnung: string[] = [];
  zuordnung = [];
  selecteteilef;
  lieferants: Lieferant[] = [];
  liefrentSelects: any[] = [];
  filteredLifrent: any[];
  resetLiefrent: Lieferant;
  resetKontakts: Kontakt[] = [];
  lieferant: Lieferant;
  kontakt = {} as Kontakt;
  kontakts: Kontakt[] = [];
  validId: boolean = false;
  filterdColumns = [];
  dateiZurdng: boolean;
  baureiheRes: any[] = [];
  baureiheList: ModelSeries[] = [];
  selectedBaureihes: ModelSeries[] = [];
  baureiheSelRes: any[] = [];
  baureiheSelList: ModelSeries[] = [];
  statusvalues: any[];
  selectedStatus;
  warengrupevalues: warengrupe[];
  selectedwarengrupe;
  sgrupevalues: sgrupe[];
  dropdowndata = [];
  teilefvalues: teilef[];
  sortiments: ModelSeries[] = [];
  productmngervalues: any[] = [];
  productmanagerItems: any[] = [];
  productmanagerlist: any[] = [];
  productmanagerlistvalue: any[] = [];
  statusval: boolean = true;
  sortimentsbackendvalue: any;
  sortimentsdropdownval: any[];
  deprtLst: ModelSeries[] = []
  seldept: any;
  userdetl: any;
  nolieferantid: boolean = false;

  selectedColumns: string[] = ["produktidee", "Lieferantenname", "teilenummer", "flzuordnung", "benennung_ETAS", "erstanlagedatum",
    "ubersetzunger", "teileinfo1", "teileinfo2", "macode", "erstdisposition", "liefertermine"];

  constructor(private dashboardService: DashboardTableService,
    private translate: TranslateService, private tabViewService: TabViewService,
    private lieferantService: LieferantService,
    private router: Router,
    private menuService: MenuService,
    public articleService: ArticleService,
    private spinnerService: Ng4LoadingSpinnerService) {
    this.articleService.dropdown().subscribe(value => {
      if (value) {

        this.dropdowndata = value.data;
        this.statusvalues = this.filterDataByType(1, this.dropdowndata);

        let statusemp = {
          mcode: null,
          name: "",
          sgid: null,
          sid: "",
          tid: null,
          type: null,
          wid: null
        };
        this.statusvalues.unshift(statusemp);
        this.selectedStatus = this.statusvalues[0];
        this.criteria.branch = this.statusvalues[0] as any;
      }
    });

    // fetch teilefamilie
    this.articleService.getTeileFamilie().subscribe((res) => {
      this.teilefvalues = [{ name: 'Alle Teilefamilien', code: 'Alle Teilefamilien' }];
      res.result.forEach((item, i) => {
        this.teilefvalues.push({ name: item.name, code: item.TF_ID })

      })
    })
  }


  onHide() {
    this.menuService.emitDialog('');
  }

  ngOnInit() {

    this.filterdColumns = [];
    this.getSelectedBaureiheData();
    this.criteria.valueDatesList = new Date();
    this.criteria.selectedStatuslist = ["I", "S", "F", "A"];
    this.getDepartment();
    this.getproductmanager();
    this.sortiments = [
      { id: 'Alle Sortimentsgruppen', name: 'Alle Sortimentsgruppen' }
    ]

    let userdetaildept = localStorage.getItem("user");
    this.userdetl = JSON.parse(userdetaildept)
    var depid = this.userdetl.DEPT_ID;
    this.seldept = this.userdetl.DEPT_NAME;
    this.criteria.department = [{
      id: depid, name: this.seldept
    }]
    this.selectProductManger(this.criteria.department);

  }

  public getproductmanager() {
    this.articleService.getAllProductManagers().subscribe(res => {
      this.productmngervalues = [{ label: '', value: '' }]
      res.forEach((element) => {
        this.productmngervalues.push({ label: element, value: element });
        this.productmanagerItems = this.productmngervalues;
      });
    });
  }

  public getDepartment() {

    this.articleService.getDepartment().subscribe(item => {
      this.abteilung = [];
      var deprtLst: ModelSeries[] = [];
      item.forEach(dp => {
        var department: ModelSeries = new ModelSeries();

        department.id = dp.abteilung_id;
        department.name = dp.name;
        deprtLst.push(department);

      });

      this.abteilung = deprtLst;

    });
  }

  public selectProductManger(department) {
    this.product = [];
    let departmentId = "";

    department.forEach(dipId => {
      departmentId = dipId.id + "," + departmentId;

    });
    var productList: ModelSeries[] = [];

    if (!departmentId) {
      this.productmanagerlistvalue = [];
    }
    else {
      this.statusval = true;
      this.articleService.getProductManagerForDepartment(departmentId).subscribe((res) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        let finalobj = JSON.parse(resJSON._body);
        this.productmngervalues = [{ name: '', code: '' }]
        finalobj.result.forEach((pm) => {
          this.productmngervalues.push({ name: pm, code: pm });
        });
        this.productmanagerlist = this.productmngervalues;

        this.productmanagerlistvalue = this.productmanagerlist;
        this.productmanagerlistvalue = this.productmanagerlistvalue.slice(1);
        this.productmanagerlistvalue.forEach(element => {
          if (element.name == this.userdetl.USER_ID) {
            this.criteria.productManager = [{
              name: this.userdetl.USER_ID, code: this.userdetl.USER_ID
            }]
          }
        });
      });
    }
  }



  checkAllEvent($event) {
    this.checkboxs.forEach(e => { e.checked = this.checkedAll });
  }

  changeonechk() {
    this.checkedAll = this.checkboxs.filter(e => e.checked).length === this.checkboxs.length;
  }

  focusmethod(event) {
    if (this.filteredLifrent.length == 0) {
      this.nolieferantid = true;
    }
    else {
      this.nolieferantid = false;
    }
    if (this.criteria.supplier == 0) {
      this.nolieferantid = false;
    }
  }

  filterisempty() {
    const emvale = ((this.criteria.supplier == undefined || this.criteria.supplier.length == 0) ? false : (this.filteredLifrent && this.filteredLifrent.length === 0));
    return emvale;

  }

  lieferantkeyup() {
    this.nolieferantid = false;
  }
  search() {
    const meta = new DeshboardMetaData();
    const criteria = this.criteria.clone();
    meta.action = 'NEWSEARCH';
    meta.projection = "status,pm," + this.projection.concat(this.checkboxs.filter(e => e.checked).map(e => e.value)).join(',');
    meta.criteria = criteria;
    meta.projection = meta.projection.split(",").filter(e => e.trim() !== "")
      .filter((el, i, a) => i === a.indexOf(el)).join(",");
    if (criteria.selectedAssignmentsList) {
      criteria.selectedAssignmentsList = criteria.selectedAssignmentsList.map(e => e.id);
    }

    if (criteria.branch) {
      criteria.branch = (criteria.branch as any).sid;
    }
    if (criteria.department) {
      let departmentIds = [];
      criteria.department.forEach(item => {
        departmentIds.push(item.id);
      });
      criteria.department = departmentIds;
    }
    if (criteria.productManager) {
      let productManger = [];
      criteria.productManager.forEach(item => {
        productManger.push(item.code);
      });
      criteria.productManager = productManger;
    }

    if (criteria.patsFamily) {
      criteria.patsFamily = (criteria.patsFamily as any).tid;
      criteria.patsFamily = this.criteria.patsFamily.code;
    }

    if (criteria.assortmentGroup) {
      criteria.assortmentGroup = this.criteria.assortmentGroup.id;
    }

    if (criteria.supplier) {
      criteria.supplier = (criteria.supplier as any).id.lieferantId;
    }

    this.spinnerService.show();
    this.dashboardService.loadTableData(meta);
    this.spinnerService.hide();
    this.onHide();
  }

  getSelectedBaureiheData() {
    this.tabViewService.getDateiBaureiheSelData().subscribe(res => {
      this.baureiheSelRes = res.data;
      var baureiheSelRes: ModelSeries[] = [];
      this.baureiheSelRes.forEach(element => {
        var bauvar: ModelSeries = new ModelSeries();
        console.log("this is log"+bauvar);
        bauvar.id = element.id['baureihenId'];
        bauvar.name = element.benennung;
        baureiheSelRes.push(bauvar);
      });
      this.baureiheList = baureiheSelRes;
    });
  }

  showPages() {
    this.showPage = !this.showPage;
  }




  filterBrands(event) {
    this.lieferantService.getLiefrentByName(event.query).subscribe(item => {
      this.lieferants = item.data;
      this.liefrentSelects = this.lieferants;
      this.filteredLifrent = this.lieferants;
    });

  }





  filterDataByType(typeid, data) {
    return data.filter(e => e.type === typeid);
  }

  filterDataByTypeSparet() {
    this.criteria.department = [];
    this.productmanagerlistvalue = [];
    if (this.selectedStatus) {
      this.warengrupevalues = this.dropdowndata.filter(e => e.type === 2
        && e.sid === this.selectedStatus.sid);
      this.selectedwarengrupe = this.warengrupevalues[0];
      this.filterDataByTypeWg();
    }
  }

  filterDataByTypeWg() {
    if (this.selectedwarengrupe && this.selectedStatus) {
      this.teilefvalues = this.dropdowndata.filter(e => e.type === 3
        && e.sid === this.selectedStatus.sid
        && e.wid === this.selectedwarengrupe.wid);
      this.criteria.patsFamily = this.teilefvalues[0] as any;
      this.filterDataByTypeSg();
    }
  }

  filterDataByTypeSg() {
    if (this.selectedwarengrupe && this.selectedStatus && this.selecteteilef)
      this.sgrupevalues = this.dropdowndata.filter(e => e.type === 4
        && e.sid === this.selectedStatus.sid
        && e.wid === this.selectedwarengrupe.wid
        && e.tid === this.selecteteilef.tid);
    this.articleService.sortimentsvalue(this.criteria.patsFamily.name).subscribe((res) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      let finalobj = JSON.parse(resJSON._body);
      this.sortiments = [];
      this.sortiments = [
        { id: 'Alle Sortimentsgruppen', name: 'Alle Sortimentsgruppen' }
      ]
      finalobj.result.forEach(dp => {
        var sortvalue: ModelSeries = new ModelSeries();

        sortvalue.id = dp.SG_ID;
        sortvalue.name = dp.name;
        this.sortiments.push(sortvalue);

      });
      this.sortiments = this.sortiments;
    });


  }


  searcharticleobj() {
  }
}


class Search {
  branch: 0;
  department: any[];
  productManager: any[];
  supplier: any;
  selectedAssignmentsList: any[];
  patsFamily: any;
  assortmentGroup: any;
  selectedStatuslist: any[];
  prestoSystem: false;
  requiredColumnNames: any;
  valueDatesList: any;

  clone(): Search {
    return Object.assign({}, this) as Search;
  }
}
