import { Component, OnInit } from '@angular/core';
import { MenuService } from 'app/menu/menu.service';
import { MessageService } from 'primeng/api';
import { ArticleService } from 'app/services/article.service';
import { DashboardTableService, DeshboardMetaData } from 'app/component/dashboard-table/dashboard-table.service';

@Component({
  selector: 'app-part-num-assign',
  templateUrl: './part-num-assign.component.html',
  styleUrls: ['./part-num-assign.component.scss']
})



export class PartNumAssignComponent implements OnInit {

  telienumberZuweisen: boolean = false;
  productmngervalues: any[];
  productmanager: any[];
  criteria: Search = new Search();
  projection: string[] = [];
  noLoginUser: boolean = false;
  noLoginUser1: boolean = false;


  constructor(private messageService: MessageService, private menuService: MenuService,
    private articleService: ArticleService, private dashboardService: DashboardTableService, ) {
    this.showDialog();
  }

  showDialog() {
    this.getPartNumber();
  }

  getPartNumber() {
    this.productmngervalues = [{ name: '', code: '' }]
    this.articleService.getAllListForPartNumAssign().subscribe(res => {
      if (res.result.length == 0) {
        this.noLoginUser = true;
      }
      else {
        this.noLoginUser1 = true;
        res.result.forEach((element) => {
          this.productmngervalues.push({ name: element, code: element });
          this.productmanager = this.productmngervalues;
        });
      }
    });


  }

  search() {
    const meta = new DeshboardMetaData();
    const criteria = this.criteria.clone();
    meta.action = 'REQUESTTN';
    meta.criteria = criteria;
    if (criteria.productManager) {
      let productManger = [];
      criteria.productManager.forEach(item => {
        productManger.push(item.code);
      });
      criteria.productManager = productManger;
    }
    this.dashboardService.loadTableData(meta);
    this.onHide();
  }

  onHide() {
    this.menuService.emitDialog('');
  }

  ngOnInit() {
  }


}

class Search {
  branch: 0;
  department: any[];
  productManager: any[];
  supplier: 0;
  selectedAssignmentsList: any[];
  patsFamily: any;
  assortmentGroup: 0;
  selectedStatuslist: any[];
  prestoSystem: false;
  requiredColumnNames: any;
  valueDatesList: any;


  clone(): Search {
    return Object.assign({}, this) as Search;
  }
}