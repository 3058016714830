import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from '../../../node_modules/rxjs';
import { Constants } from '../constants';

import { HttpEvent, HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';


@Injectable()
export class ArticleService {

  httpOptions = new RequestOptions({ headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('msal.idtoken')}s`,'withCredentials': `true`}) });
  // ,'Access-Control-Allow-Origin': '*'
  constructor(
    private http: Http, private httpClient: HttpClient
  ) { }

  headers = new HttpHeaders()
  .set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`) 
  // .set('Access-Control-Allow-Origin', `*`);

  getArticles(): Observable<any> {
    return this.http.get(`${Constants.HOST}/getAllArtikels`,this.httpOptions).map(response => response.json());
  }

  createArticle(data) {
    return this.http.post(`${Constants.HOST}/createArtikels/`, data,this.httpOptions);
  }
  deleteArticle(id) {
    return this.http.delete(`${Constants.HOST}/deleteArtikels/${id}`,this.httpOptions).map(response => response.json());
  }
  updateArticle(data, flag) {
    return this.http.post(`${Constants.HOST}/updateArticle/${flag}`, data,this.httpOptions);
  }

  dropdown(): Observable<any> {
    return this.http.get(`${Constants.HOST}/dropdown/tele`,this.httpOptions).map((res: Response) => res.json());
  }

  blockOrResPartNum(partNum, status) {
    var data = { partNum: partNum, status: status };
    var strData = partNum + "/" + status
    return this.http.post(`${Constants.HOST}/reserveOrBlockPartNum/`, strData,this.httpOptions);
  }
  freePartNum(id) {
    return this.http.delete(`${Constants.HOST}/deleteArtikels/${id}`,this.httpOptions).map(response => response.json());
  }
  updateArticleStatus(articleid, statusid) {
    return this.http.post(`${Constants.HOST}/updateArtikelStatus/${articleid}/${statusid}`, JSON.stringify(articleid), JSON.stringify(statusid));
  }

  copyArticle(copyarticledata) {
    return this.http.post(`${Constants.HOST}/copyArticle`, copyarticledata,this.httpOptions);
  }


  evaluateflatprice(data) {
    return this.http.post(`${Constants.HOST}/evaluationFlatPriceAdjustment`, data);
  }

  updateArtikelStatusFreigabe(articleID, sendEk, BuyerEmeaId, MarginUnderRunDTO) {
    return this.http.post(`${Constants.HOST}/updateArtikelStatusFreigabe/${articleID}/${sendEk}/${BuyerEmeaId}`, MarginUnderRunDTO);
  }

  getItems(page, action, order, part, freeText, projection, criteria,exportFlag) {
    return this.http.post(`${Constants.HOST}/getAllItems/${page}/${action}?order=${order}&partNumber=${part}&freeText=${freeText}&projection=${projection}&exportFlag=${exportFlag}`,
      criteria, this.httpOptions).map(response => response.json());

  }

  getArticleById(id: number): Observable<any> {
    return this.http.get(`${Constants.HOST}/getArticleByArticleId/${id}`,this.httpOptions);
  }

  nextOrPrevPartNumber(partNr, flagPrevOrNext) {
    return this.http.get(`${Constants.HOST}/getPreviousOrNextItems/${partNr}?flag=${flagPrevOrNext}`,this.httpOptions).map(response => response.json());
  }

  getPartNumbers(partNumbers) {
    return this.http.get(`${Constants.HOST}/fetchArtikelForPartNumbers?parts=${partNumbers}`,this.httpOptions).map(response => response.json());
  }
  historyData(articleid): Observable<any> {
    return this.http.get(`${Constants.HOST}/getHistorie/${articleid}`,this.httpOptions);
  }


  preisData(articleid): Observable<any> {
    return this.http.get(`${Constants.HOST}/fetchPreiseByArticleID/${articleid}`,this.httpOptions);
  }



  preisDataSave(priceData): Observable<any> {
    return this.http.post(`${Constants.HOST}/saveOrUpdatePreiseAndEkDetails`, priceData,this.httpOptions);
  }

  archivedocument(archivedata): Observable<any> {
    return this.http.post(`${Constants.HOST}/getArchivedDocuments`, archivedata,this.httpOptions);
  }



  getDepartment(): Observable<any> {
    return this.http.get(`${Constants.HOST}/fetchAllDepartments`,this.httpOptions).map(response => response.json());
  }
  getUsernameForDepartment(id): Observable<any> {
    return this.http.get(`${Constants.HOST}/fetchUsernameForDepartment/${id}`);
  }

  getEmailStatus(): Observable<any> {
    return this.http.get(`${Constants.HOST}/getEmailToDispo`,this.httpOptions).map(response => response.json());
  }

  setEmailStatus(status): Observable<any> {
    return this.http.post(`${Constants.HOST}/setEmailToDispo/${status}`,this.httpOptions, status).map(response => response.json());
  }

  chngArticleOwner(artikelId, pm): Observable<any> {
    return this.http.get(`${Constants.HOST}/changeArtikelOwner/${artikelId}/${pm}`,this.httpOptions);
  }
  getAllProductManagers(): Observable<any> {
    return this.http.get(`${Constants.HOST}/fetchAllProductManager`,this.httpOptions).map(response => response.json());
  }

  getAllProductManagersForTNum(): Observable<any> {
    return this.http.get(`${Constants.HOST}/getAllUserIdsForPMandSUrole`,this.httpOptions).map(response => response.json());
  }

  assignTNum(id):Observable<any> {
    return this.http.get(`${Constants.HOST}/assignTeilinuberResponsiblity/${id}`,this.httpOptions).map(response => response.json());
  }

  getProductManagerForDepartment(deptid): Observable<any> {
    return this.http.get(`${Constants.HOST}/fetchPMForDepartments/${deptid}`,this.httpOptions);
  }

  //Buyer list http://localhost:8080/nuzuco/api/v1/fetchAllBuyers

  public getBuyer(): Observable<any> {
    return this.http.get(`${Constants.HOST}/fetchAllBuyers`).map(response => response.json(),this.httpOptions);
  }


  getBuyerList(): Observable<any> {
    return Observable.create(observer => {
      var rg = [{ name: null, code: null }];
      this.getBuyer().subscribe(item => {
        item.data.forEach(element => {
          rg.push({ name: element, code: element });
        });
        observer.next(rg);
      });
    });
  }

  senduserrights(userdetl): Observable<any> {
    return this.http.post(`${Constants.HOST}/manageUserInDB`, userdetl,this.httpOptions);
  }

  //Margine

  public getMargin(artikelId, auf) {

    return this.http.get(`${Constants.HOST}/isMarginUnderRun/${artikelId}/${auf}`,this.httpOptions).map(response => response.json());

  }
  public getTeileFamilie() {
    return this.http.get(`${Constants.HOST}/getAllTelieFamily`,this.httpOptions).map(response => response.json());
  }

  sortimentsvalue(sortimentsvalue) {
    return this.http.get(`${Constants.HOST}/getSortimentGroup/${sortimentsvalue}`,this.httpOptions);
  }

  getAllListForPartNumAssign(){
    return this.http.get(`${Constants.HOST}/fetchAllTeilinumberAssigner`,this.httpOptions).map(response => response.json());
  }
}