import { Injectable, Injector, LOCALE_ID } from '@angular/core';
import { de_literals } from './de-literals';
import { en_literals } from './en-literals';

@Injectable()
export class Literals {
    res: any = {};
    constructor(private injector: Injector) {
        this.res = en_literals;
    }
    switchLanguage(language: string) {
        if(language == 'EN'){
           alert('in if language = '+language);
            this.res = en_literals;
            alert('literal value= '+this.res.FILE);
        }else{
            alert('in else language = '+language);
            this.res = de_literals;

        }
      }
}
