import { Component, OnInit, ViewChild, Input, OnDestroy, ElementRef } from '@angular/core';
import { Constants } from '../../constants';
import { ArticleService } from '../../services/article.service';
import { TableBody } from 'primeng/primeng';
import { DashboardTableService, DeshboardMetaData } from './dashboard-table.service';
import { TabViewService } from 'app/tab-view/tab-view.service';
import { ExcelService } from '../../services/excel.service';
import { MenuService } from '../../menu/menu.service';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from "moment";
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { RoleService } from 'app/services/RoleService';
import { version } from 'punycode';
import { UserService } from 'app/services/UserService';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss']
})
export class DashboardTableComponent implements OnInit, OnDestroy {
  changeVersionSaveDel: number = 0;

  cols: any[];
  datasource: any;
  exportData: any;
  totalRecords: number = 0;
  loading: boolean = false;
  first: number = 0;
  sortString: string = "";
  searchType = 'tele';
  searchText = '';
  stringProjection = '';
  objectCriteria = {};
  action = 'home';
  colSelected: boolean = false;
  coldDelete: boolean = false;
  selectedRow: any;
  currentArticle: any;
  freeSearchPl: String = "search";
  articles: any;
  norec: boolean = false;
  intialPage: number = 0;
  table: any = null;
  paginationData: any = null;
  noteilenumber: boolean = false;
  isUserAlsoPM: boolean =false;
  rowSelctedUpdat: boolean = false;
  articleId: any;
  valuecreate: any;
  noteilenummer: any;
  nopartnumber: boolean = false;
  curentPageNumber: number = 1;
  version: number = 1;
  subsId: Subscription;

  constructor(private spinnerService: Ng4LoadingSpinnerService, public translate: TranslateService, private articleService: ArticleService, private dashboardService: DashboardTableService, private tabViewService: TabViewService
    , private excelService: ExcelService, private menuService: MenuService, private roleService: RoleService, private userService: UserService) {
    this.subsId = translate.onLangChange.subscribe(lang => {
      this.version++;
    });

    this.changeVersionSaveDel++;
    dashboardService.subscribe((value: DeshboardMetaData) => {
      this.valuecreate = value.action;
      if (value === null) {
        this.loadTableData();
        return;
      }
      this.spinnerService.show();
      this.stringProjection = value.projection;
      this.objectCriteria = value.criteria || {};
      this.action = value.action || 'home';
      this.first = 0;
      this.setColumn();
      if (value.action == "create") {
        this.action = "home";
        this.sortString = "order by created_dt desc";
      }
      if (value.action == "delete") {
        this.action = "home";
        this.sortString = "order by updated_dt desc";

        if (this.datasource.length === 1 || this.first > 0) {
          this.curentPageNumber = this.curentPageNumber - 1;
        }
        this.first = this.curentPageNumber * 10 - 10;

      }
      this.loadTableData();
      this.spinnerService.hide();
    });

    this.tabViewService.emiteArticleId.subscribe(item => {
      this.loadTableData();
      this.articleId = item;
      this.rowSelctedUpdat = true;

      if (this.table) {
      }
    });
  }

  ngOnInit() {
    this.userService.loggedUser.subscribe(user => {
      
      if(user.IS_USER_ALSO_PM === "TRUE"){
        this.isUserAlsoPM = true;    
        console.log("this.isUserAlsoPM = true")
      }else{
        this.isUserAlsoPM = false; 
        console.log("this.isUserAlsoPM = false")  
      }
    });
  }

  ngOnDestroy() {
    if (this.subsId) {
      this.subsId.unsubscribe();
    }
  }

  initHomeData(data) {
    this.table = data;
    this.table.sortField = "";
    this.sortString = "";
    this.freeSearchPl = "search";
    this.searchType = 'tele';
    this.searchText = '';
    this.setColumn();
    this.first = 0;
    this.dashboardService.loadTableData();
    this.selectedRow = null;
    this.noteilenumber = false;
    this.colSelected = false;
    localStorage.removeItem('article_id');
    localStorage.removeItem("articlestatus");
  }

  searchIdeeSpec(data) {
    this.table = data;
    this.table.sortField = "";
    this.freeSearchPl = "search";
    this.searchType = 'tele';
    this.searchText = '';
    this.setColumn();
    this.first = 0;
    const meta = new DeshboardMetaData();
    meta.action = 'HOMESTATUSFILTER';
    this.dashboardService.loadTableData(meta);
    this.selectedRow = null;
  }

  setColumn() {
    if (this.stringProjection && this.stringProjection.length > 3) {
      var d = this.stringProjection.toLocaleUpperCase().split(',');
      d = d.map(e => e.trim());
      this.cols = Constants.TABLE_COLUMNS.filter(e =>
        d.indexOf(e.field.trim().toLocaleUpperCase()) >= 0
      );
      return;
    } else if (this.searchType === 'free') {
      const freeTextCols = [1, 2, 3, 4, 19, 6, 7];
      this.cols = Constants.TABLE_COLUMNS.filter(e => freeTextCols.indexOf(e.id) >= 0);
    } else {
      const homeColums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      this.cols = Constants.TABLE_COLUMNS.filter(e => homeColums.indexOf(e.id) >= 0);
    }

  }

  onRowUnselect(event) {

    localStorage.removeItem('article_id');
    localStorage.removeItem('articlestatus');
    this.colSelected = false;
    this.tabViewService.emitConfig("search");
  }

  loadTableData() {
    this.spinnerService.show();
    this.stringProjection = this.stringProjection || '';
    this.colSelected = false;
    this.objectCriteria = this.objectCriteria ;
    this.articleService.getItems(
      this.first || 0,
      this.action,
      this.sortString,
      this.searchType === 'tele' ? this.searchText.toLocaleUpperCase() : '',
      this.searchType === 'free' ? this.searchText.toLocaleUpperCase() : '',
      this.stringProjection,
      this.objectCriteria,
      null,
    ).subscribe((data: any) => {
      this.datasource = data.result;
      if (data.status == 200) {
        console.log("load table   "+ this.datasource.toString);
        this.sortString = "";
        this.datasource = data.result;
        if (this.datasource.length == 0) {
          this.norec = true;
        }
        else {
          this.norec = false;
        }
        this.totalRecords = data.result && data.result.length > 0 ? data.result[0].count : 0;
        console.log("data for table  " +data.result.toString);
        data.result.forEach((element, i) => {
          if (element.status == "S") {
            element.status = "Spezifikation"

          }
          if (element.status == "F") {
            element.status = "Freigabe/Aktiv"
          }
          if (element.status == "A") {
            element.status = "Auf Aufbrauch"
          }
          if (element.status == "B") {
            element.status = "Blocked"
          }
          if (element.status == "R") {
            element.status = "Reserved"
          }
          if (element.status == "I") {
            element.status = "Idee"
          }
          if (element.status == "Z") {
            element.status = "Archiv Idee"
          }
          if (element.status == "X") {
            element.status = "Archiv"
          }
          if (element.bestanddatum != null) {
            element.bestanddatum = new Date(element.bestanddatum);
            element.bestanddatum = moment(element.bestanddatum).format('YYYY-MM-DD');
          }
          if (element.liefertermin != null) {
            element.liefertermin = new Date(element.liefertermin);
            element.liefertermin = moment(element.liefertermin).format('YYYY-MM-DD');
          }
          if (element.DATUMFREIGABE != null) {

            element.DATUMFREIGABE = new Date(element.DATUMFREIGABE);
            element.DATUMFREIGABE = moment(element.DATUMFREIGABE).format('YYYY-MM-DD');
          }

        });

      }
      this.spinnerService.hide();

      if (this.action == "home") {
        this.selectedRow = null;
      }

      if (this.valuecreate == "create") {
        this.valuecreate = null;
        this.selectedRow = this.datasource[0];
        this.coldDelete = true;
        this.tabViewService.emitConfig(this.selectedRow.artikel_id);
      }
      if (this.table != null && this.paginationData == null) {
        this.table.first = 0;
        this.paginationData = null;
      }

      this.tabViewService.emitConfig("search");

      if (this.rowSelctedUpdat) {
        this.selectedRow = this.datasource.find(x => x.artikel_id === this.articleId);
        this.tabViewService.emitConfig(this.articleId);
        this.rowSelctedUpdat = false;
      }
    });


  }

  loadCarsLazy(e) {
    this.paginationData = "page";
    this.rowSelctedUpdat = false;
    this.selectedRow = null;
    if (e) {
      this.first = e.first;
      if (e.sortField) {
        this.sortString = "ORDER BY " + e.sortField + (e.sortOrder === 1 ? " ASC " : " DESC ")
      } else {
        this.sortString = '';
      }
    }
    this.loadTableData();
  }

  serachInputEvent() {
    this.first = 0;
    this.loadTableData();
  }

  setTable(c) {

  }
  inputchange2() {
    if (this.searchType == 'tele') {
      this.action = "globalsearch";
    }
    if (this.searchType == 'free') {
      this.action = "globalsearch";
    }
  }

  searchAutoCompTele() {
    this.freeSearchPl = "search";
    this.searchType = 'tele';
    this.searchText = "";
    this.serachInputEvent();
    this.setColumn();
  }

  searchAutoCompFree() {
    this.freeSearchPl = "S_P_T";
    this.searchType = 'free';
    this.searchText = "";
    this.serachInputEvent();
    this.setColumn();
  }



  onRowSelect(event) {
    this.colSelected = true;
    this.coldDelete = true;
    this.currentArticle = event.data;
    this.selectedRow = event.data;
    this.tabViewService.emitConfig(this.selectedRow.artikel_id);
    this.roleService.setArticleOwnerPM = this.selectedRow.pm;
    this.roleService.setStatusOfArticle = this.selectedRow.status;
    if (event.data.status != "Idee" && event.data.status != "Spezifikation") {
      this.coldDelete = false;
    }
    if (event.data.teilenummer == null) {
      this.noteilenumber = false;
    }
    else {
      this.noteilenumber = true;
    }
  }

  nextSearch() {
    this.articleService.nextOrPrevPartNumber(this.selectedRow.teilenummer, 'next').subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.datasource = data.result;
          if (data.result && data.result.length > 0) {
            this.noteilenummer = data.result;
            this.totalRecords = data.result[0].count;
            data.result.forEach((element, i) => {
              if (element.status == "S") {
                element.status = "Spezifikation"
              }
              if (element.status == "F") {
                element.status = "Freigabe/Aktiv"
              }
              if (element.status == "A") {
                element.status = "Auf Aufbrauch"
              }
              if (element.status == "B") {
                element.status = "Blocked"
              }
              if (element.status == "R") {
                element.status = "Reserved"
              }
              if (element.status == "I") {
                element.status = "Idee"
              }
              if (element.status == "Z") {
                element.status = "Archiv Idee"
              }
              if (element.status == "X") {
                element.status = "Archiv"
              }

              if (element.bestanddatum != null) {
                element.bestanddatum = new Date(element.bestanddatum);
                element.bestanddatum = moment(element.bestanddatum).format('YYYY-MM-DD');
              }
              if (element.liefertermin != null) {
                element.liefertermin = new Date(element.liefertermin);
                element.liefertermin = moment(element.liefertermin).format('YYYY-MM-DD');
              }
              if (element.DATUMFREIGABE != null) {
                element.DATUMFREIGABE = new Date(element.DATUMFREIGABE);
                element.DATUMFREIGABE = moment(element.DATUMFREIGABE).format('YYYY-MM-DD');
              }

            });
            this.selectedRow = this.datasource[0];
          }
          else if (data.result.length == 0) {
            this.datasource = this.noteilenummer
            this.nopartnumber = true;
          }
          this.tabViewService.emitConfig(this.selectedRow.artikel_id);
        }
      });
    this.setColumn();
  }

  previousSearch() {
    this.articleService.nextOrPrevPartNumber(this.selectedRow.teilenummer, 'prev').subscribe((data: any) => {
      if (data.status == 200) {
        this.datasource = data.result;

        if (data.result && data.result.length > 0) {
          this.noteilenummer = data.result;
          this.totalRecords = data.result[0].count;
          data.result.forEach((element, i) => {
            if (element.status == "S") {
              element.status = "Spezifikation"
            }
            if (element.status == "F") {
              element.status = "Freigabe/Aktiv"
            }
            if (element.status == "A") {
              element.status = "Auf Aufbrauch"
            }
            if (element.status == "B") {
              element.status = "Blocked"
            }
            if (element.status == "R") {
              element.status = "Reserved"
            }
            if (element.status == "I") {
              element.status = "Idee"
            }
            if (element.status == "Z") {
              element.status = "Archiv Idee"
            }
            if (element.status == "X") {
              element.status = "Archiv"
            }

            if (element.bestanddatum != null) {
              element.bestanddatum = new Date(element.bestanddatum);
              element.bestanddatum = moment(element.bestanddatum).format('YYYY-MM-DD');
            }
            if (element.liefertermin != null) {
              element.liefertermin = new Date(element.liefertermin);
              element.liefertermin = moment(element.liefertermin).format('YYYY-MM-DD');
            }
            if (element.DATUMFREIGABE != null) {
              element.DATUMFREIGABE = new Date(element.DATUMFREIGABE);
              element.DATUMFREIGABE = moment(element.DATUMFREIGABE).format('YYYY-MM-DD');
            }

          });
          this.selectedRow = this.datasource[0];
        }

        else if (data.result.length == 0) {
          this.datasource = this.noteilenummer
          this.nopartnumber = true;
        }
        this.tabViewService.emitConfig(this.selectedRow.artikel_id);
      }

    });

    this.setColumn();

  }

  showDialog(tab: String) {
    this.menuService.emitConfig(tab);
  }


  public exportExcel(exportType) {
    var allData = [];
    var object = {}
    let head = []
    if (this.exportData) {
      this.exportData.forEach(item => {
        var excelObject = {};
        this.cols.forEach(data => {
         // excelObject[this.translate.instant(data.header)] = item[data.field.toUpperCase()] || "";
         excelObject[this.translate.instant(data.header)] = item[data.field] || "";
        });
        allData.push(excelObject);
      });
    }
    if (exportType == "CSV") {

      this.cols.forEach(data => {
        head.push(this.translate.instant(data.header));
      });
      new Angular5Csv(allData, "Article Export_TAD", { headers: (head) });
      this.spinnerService.hide();
    } else {
      this.excelService.exportAsExcelFile(allData, 'Article Export_TAD');
      this.spinnerService.hide();
    }
  }


  public getExportData(exportType) {
    this.spinnerService.show();
    this.stringProjection = this.stringProjection || '';
    this.objectCriteria = this.objectCriteria || {};
    this.articleService.getItems(
      this.first || 0,
      this.action,
      this.sortString,
      this.searchType === 'tele' ?
        this.searchText.toLocaleUpperCase() : '',
      this.searchType === 'free' ? this.searchText.toLocaleUpperCase() : '',
      this.stringProjection,
      this.objectCriteria,
      "exportFile"
    ).subscribe((data: any) => {
      this.exportData = data.result;
      if (data.status == 200) {
        this.exportData = data.result;
        this.exportData.forEach((element, i) => {

          if (element.status == "S") {
            element.status = "Spezifikation"

          }
          if (element.status == "F") {
            element.status = "Freigabe/Aktiv"
          }
          if (element.status == "A") {
            element.status = "Auf Aufbrauch"
          }
          if (element.status == "B") {
            element.status = "Blocked"
          }
          if (element.status == "R") {
            element.status = "Reserved"
          }
          if (element.status == "I") {
            element.status = "Idee"
          }
          if (element.status == "Z") {
            element.status = "Archiv Idee"
          }
          if (element.status == "X") {
            element.status = "Archiv"
          }

          if (element.bestanddatum != null) {
            element.bestanddatum = new Date(element.bestanddatum);
            element.bestanddatum = moment(element.bestanddatum).format('YYYY-MM-DD');
          }

          if (element.liefertermin != null) {
            element.liefertermin = new Date(element.liefertermin);
            element.liefertermin = moment(element.liefertermin).format('YYYY-MM-DD');
          }
          if (element.DATUMFREIGABE != null) {
            element.DATUMFREIGABE = new Date(element.DATUMFREIGABE);
            element.DATUMFREIGABE = moment(element.DATUMFREIGABE).format('YYYY-MM-DD');
          }

        });
        this.exportExcel(exportType);
      }

    });
  }

  paginate(event) {
    let pageIndex = event.first / event.rows + 1 // Index of the new page if event.page not defined.
    this.curentPageNumber = pageIndex;
    $("#contentData").animate({ scrollTop: 0 }, 900);
  }
}
