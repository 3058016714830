import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { SelectItem } from 'primeng/api';
import { ArchiveDto } from '../../dto/archive-dto';
import { ArticleService } from '../services/article.service';
import * as moment from "moment";

@Component({
  selector: 'app-menu-archive',
  templateUrl: './menu-archive.component.html',
  styleUrls: ['./menu-archive.component.scss']
})
export class MenuArchiveComponent implements OnInit {

  archivedetail: ArchiveDto;
  archive: boolean = false;
  productmanagerItems: any[] = [];
  items: SelectItem[];
  selectedProductmanagerItems: any[] = [];
  dateFormat = "dd.mm.yy";
  archiveconfirm: boolean = false;
  archiverestrue: boolean = false;
  archiveresfalse: boolean = false;
  archivedateerr: boolean = false;
  rescount: number;
  rescountvalid: boolean = false;
  rescountvalid1: boolean = false;
  productmngervalues: any[] = [];
  productmanager;
  userdetl: any;
  constructor(private menuService: MenuService, private articalservice: ArticleService) {

    this.menuService.archive.subscribe(value => {
    this.initialization();
    this.rescountvalid = false;
    this.rescountvalid1 = false;
    this.showDialog();
    });


  }

  ngOnInit() {
    this.initialization();
    this.rescountvalid = false;
    this.rescountvalid1 = false;
  }


  initialization() {
    this.archivedetail = {
      userList: [],
      tillDate: null
    }
  }


  showDialog() {
    this.archive = true;
    this.articalservice.getAllProductManagers().subscribe(res => {
    this.productmngervalues = [{ label: '', value: '' }]
    res.forEach((element) => {
    this.productmngervalues.push({ label: element, value: element });
    this.productmanagerItems = this.productmngervalues;
    this.productmanagerItems = this.productmanagerItems.slice(1);
    });
    let userdetaildept = localStorage.getItem("user");
    this.userdetl = JSON.parse(userdetaildept);
    this.productmanagerItems.forEach(element => {
    if (element.value == this.userdetl.USER_ID) {
        this.archivedetail.userList = [{
        label: this.userdetl.USER_ID, value: this.userdetl.USER_ID
        }]
      }
    });
    });

  }

  sendarchivedata() {
    this.archiveconfirm = true;
  }


  submit() {

    let archivvalue = [];
    this.archivedetail.userList.forEach((item) => {
      archivvalue.push(item.value);
    })
    
    this.archivedetail.userList = archivvalue;
    this.articalservice.archivedocument(this.archivedetail).subscribe(res => {
    let resSTR = JSON.stringify(res);
    let resJSON = JSON.parse(resSTR);
    let archiveobj = JSON.parse(resJSON._body);
    this.rescount = archiveobj.result;
    if (archiveobj.result == 0) {
      this.rescountvalid = true;
      }
    else {
      this.rescountvalid1 = true;
    }
      this.archiveconfirm = false;

    });
  }
}
