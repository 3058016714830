import { Component, OnInit } from '@angular/core';

import { TabViewService } from '../tab-view/tab-view.service'

import { Kontakt, Lieferant } from '../../dto/Lieferant';

import { LieferantService } from '../services/lieferant.service';

import { MenuService } from '../menu/menu.service'

import { MessageService } from 'primeng/api';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
  providers: [MessageService]
})
export class SupplierComponent implements OnInit {

  display: boolean = false;
  deleteKntct: boolean = false;
  deleteLfrent: boolean = false;

  lieferant: Lieferant;
  kontakt = {} as Kontakt;
  kontakts: Kontakt[] = [];
  lieferants: Lieferant[] = [];

  lifrentStatus: boolean = true;

  liefrentSelects: any[] = [];

  filteredLifrent: any[];

  liefrentSelect: string;

  contactId: string;

  resetLiefrent: Lieferant;
  resetKontakts: Kontakt[] = [];

  fromMenu: boolean = false;

  enableId: boolean = false;

  validId: boolean = false;

  kontactEmailValidation: any[] = [];
  emailValidation: boolean = true;
  editeStatus: any = [];
  crearteStatus: boolean = false;

  contaktIndex: number;

  selectedRow = {} as Kontakt;
  selctedContactStatus: boolean = false;

  dspList = [];
  emeaidSelected: any;
  nameValidation: boolean = false;
  kontactNameValidation: any[] = [];



  constructor(private messageService: MessageService, private tabViewService: TabViewService, private lieferantService: LieferantService, private menuService: MenuService,
    public translate: TranslateService) {
    this.intialization();

    this.tabViewService.supplier.subscribe(lifrnt => {
      this.liefrentSelect = "";
      this.emeaidSelected = null;
      this.getDisponent();

      this.getLiefrentByid(lifrnt);
      this.fromMenu = true;
      this.enableId = true;
      this.validId = false;
      this.emailValidation = true;
      this.crearteStatus = false;
      this.selctedContactStatus = false;
      this.selectedRow = null;
    });

    this.menuService.liefrent.subscribe(item => {
      this.liefrentSelect = "";
      this.emeaidSelected = null;
      this.getDisponent();
      this.fromMenu = false;
      this.enableId = true;
      this.validId = false;
      this.getLiefrentByid({});
      this.selctedContactStatus = false;
      this.emailValidation = true;
      this.selectedRow = null;

    });
  }

  ngOnInit() {

    this.intialization();

  }

  intialization() {
    this.kontakt = {
      id: { kontaktId: '0' },
      name: "",
      bemerkung: "",
      tel: " ",
      email: "",
    }
    this.kontakts = [];
    this.lieferant = {
      id: { lieferantId: '' },
      name: "",
      land: "",
      ort: "",
      plz: "",
      strasse1: "",
      strasse2: "",
      contact: this.kontakts,
      emeaid: ""

    }
    this.emeaidSelected = "";
  }


  // new lifrent
  newLifrent() {
    this.lifrentStatus = false;
    this.intialization();
    this.enableId = false;

  }
  // new contact
  newKontakte(dt) {
    var newArr = [];
    newArr = [{
      id: { kontaktId: '0' },
      name: "",
      bemerkung: "",
      tel: "",
      email: "",
    }];
    this.kontakts = newArr.concat(this.kontakts);
    dt.reset();


    this.editeStatus = [false].concat(this.editeStatus);
  }

  //Edite contact
  editKontakte() {
    this.editeStatus[this.contaktIndex] = false;
  }


  filterBrands(event) {
    this.lieferantService.getLiefrentByName(event.query).subscribe(item => {
      this.lieferants = item.data;
      this.liefrentSelects = this.lieferants;
      this.filteredLifrent = this.lieferants;

    });

  }
  selctLifrent(value) {
    this.validId = false;
    this.resetLiefrent = value;
    this.lifrentStatus = false;
    this.resetKontakts = this.resetLiefrent.contact;
    this.lieferant = Object.assign({}, this.resetLiefrent);
    this.kontakts = JSON.parse(JSON.stringify(this.resetKontakts));
    this.setEditeState(this.kontakts);
    this.emeaidSelected = this.dspList.find(x => x.name === this.resetLiefrent.emeaid);
  }
  //Set the edite false
  public setEditeState(kontakt) {
    let i = 0;
    kontakt.forEach(item => {
      this.editeStatus[i] = true;
      i++;
    });

  }




  //Get liefent by id

  getLiefrentByid(lifrnt) {
    this.lifrentStatus = true;
    this.intialization();
    this.display = true;
    if (lifrnt.id) {
      if (lifrnt.id.lieferantId != null) {
        this.lieferantService.getLiefrentById(lifrnt.id.lieferantId).subscribe(item => {
          this.resetLiefrent = item.data;
          this.lieferant = Object.assign({}, this.resetLiefrent);
          this.resetKontakts = this.resetLiefrent.contact;
          this.kontakts = JSON.parse(JSON.stringify(this.resetKontakts));
          this.lifrentStatus = false;
          this.emeaidSelected = this.dspList.find(x => x.name === this.resetLiefrent.emeaid);
        });
      }

    }
    this.setEditeState(this.kontakts);
  }

  //getall Liefrent 

  getAlllifrent(lifrnt) {
    this.liefrentSelect = "";
    this.lieferantService.getAllLieferant().subscribe(item => {
      this.lieferants = item.data;
      this.liefrentSelects = this.lieferants;
      this.lifrentStatus = true;
      this.intialization();
      this.display = true;
      if (lifrnt.id) {
        if (lifrnt.id.lieferantId != null) {
          this.resetLiefrent = this.lieferants.find(x => x.id.lieferantId == lifrnt.id.lieferantId);
          this.lieferant = Object.assign({}, this.resetLiefrent);
          this.resetKontakts = this.resetLiefrent.contact;
          this.kontakts = JSON.parse(JSON.stringify(this.resetKontakts));
          this.lifrentStatus = false;
        }
      }
    });
  }


  //Supplier save
  supplierSave() {
    this.lieferant.emeaid=this.emeaidSelected['name'];
    this.lieferant.contact = this.kontakts;
    //this.emailValidations(this.kontakts);
    if (!this.nameValidation) {
      this.lieferantService.createLieferant(this.lieferant).subscribe(
        item => {
          //this.getAlllifrent(this.lieferant);
          this.lieferant.contact = this.kontakts;

          this.messageService.add({ detail: this.translate.instant('lieferant_create'), key: 'c' });
          this.crearteStatus = true;
        }
      );
    }
  }

  //Email validation
  public emailValidations(data) {
    this.emailValidation = true;
    this.nameValidation = false;
    this.kontactEmailValidation = [];
    this.kontactNameValidation = [];
    let emailExpation = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let i = 0;
    data.forEach(item => {
      if (item.name == "") {
        this.nameValidation = true;
        this.kontactNameValidation[i] = true;
      }

      else {
        if (emailExpation.test(item.email) == false) {
          this.emailValidation = false;
          this.nameValidation = false;
          this.kontactEmailValidation[i] = true;
        } else {
          this.kontactEmailValidation[i] = false;
        }

        i++;
      }
    });
  }



  nameerr() {
    this.nameValidation = false;
  }

  emailerr() {
    this.emailValidation = true;
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 45 || event.charCode == 40 || event.charCode == 41 || event.charCode == 43) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  validNummer(data) {
    this.validId = false;
    if (data.target.value < 999 || data.target.value > 999999999 || (data.target.value % 1 != 0)) {
      this.validId = true;
    }
  }

  //Assigne the supplier
  assigneLifrent() {
    this.display = false;
    this.tabViewService.emiteAssigneSupplier(this.lieferant);
  }
  //Reset the supplier
  resetLifrents() {

    if (this.lieferant.id.lieferantId) {
      this.lieferant = Object.assign({}, this.resetLiefrent);
      this.kontakts = JSON.parse(JSON.stringify(this.resetKontakts));
    } else {
      this.intialization();
    }
  }

  //Delete the supplier
  deleteLifrent() {
    this.lieferantService.deleteLieferant(this.lieferant.id.lieferantId).subscribe(
      val => {

        this.lifrentStatus = true;
        this.kontakts = [];
        this.lieferant = {
          id: { lieferantId: '' },
          name: "",
          land: "",
          ort: "",
          plz: "",
          strasse1: "",
          strasse2: "",
          contact: this.kontakts,
          emeaid: null

        }
        this.messageService.add({ detail: this.translate.instant('lieferant_del'), key: 'c' });
      }
    );
    this.deleteLfrent = false;
  }
  //Delete the Contact
  deleteContact() {
    this.liefrentSelect = "";
    this.lieferantService.deleteContact(this.contactId).subscribe(
      val => {
        this.getLiefrentByid(this.lieferant);
        this.messageService.add({ detail: this.translate.instant('contact_del'), key: 'c' });
      }
    );
    this.deleteKntct = false;
  }

  //set contact id

  setContactId(contact, index) {
    this.contaktIndex = index;
    this.contactId = contact.id.kontaktId;
    this.selectedRow = contact;
    this.selctedContactStatus = true;
  }


  //Email validation


  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }

  //Get Disponent 
  getDisponent() {
    this.dspList = [{ name: "  ", code: 0 }]
    this.lieferantService.getAllDesponentsList().subscribe(item => {
      this.dspList = this.dspList.concat(item);


    });
  }

}
