import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Article } from 'dto/Article';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ArticleService } from '../services/article.service';

import { TabViewService } from '../tab-view/tab-view.service'
import { MenuService } from '../menu/menu.service'

import { ExcelService } from '../services/excel.service';
import { DashboardTableService } from '../component/dashboard-table/dashboard-table.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  articles = [];
  articleId;
  cols: any[];
  articalList = [];
  searchText: string;
  freeTextDailog: boolean = false;
  searchFreeText: string;
  allCols: any[];
  colsSecond: any[]
  colsThird: any[]
  searchFree: String = 'tile';
  selectedRow: any;
  currentArticle: any;
  articleInput = [];

  table: any;

  colSelected: boolean = false;
  coldDelete: boolean = false;

  artSelected: boolean = false;
  noPart: boolean = false;

  preivoseNextArticle: string;

  first: number = 0;

  freeSearchPl: String = "search";

  curentPageNumber: number = 1;

  currentFunctionality: String = "";
  activeDialog="";



  constructor(private translate: TranslateService, private menuService: MenuService, private articleService: ArticleService, private tabViewService: TabViewService, private excelService: ExcelService,
    private dashboardtableservice: DashboardTableService) {
     
    this.menuService.dialog.subscribe(value=>{
      if(value === undefined) {
       this.activeDialog = '';
       return; 
      }
      this.activeDialog = value as any;
    }); 

    this.menuService.configObservable.subscribe(value => {
      this.currentFunctionality = value;
    });

    this.tabViewService.tableColumns.subscribe(val => {

      this.cols = val;
    });

    this.tabViewService.statusvarb.subscribe(val => {

      this.cols.push({ field: 'status', header: 'Status', pointer: 'true', textAlign: 'left', width: '35%' });
      this.articles = val;

    });


    this.tabViewService.articleList.subscribe(data => {
      this.articles = data;
      this.removeStatus();
      var artPr = data.find(x => x.id.artikelId == localStorage.getItem('article_id'));
      this.articles.forEach((obj, i) => {
        if (obj.lieferantBean) {
          this.articles[i]["supplierName"] = obj.lieferantBean.name;
        } else {
          this.articles[i]["supplierName"] = null;
        }
        if (obj.status == "S") {
          obj.status = "Spezifikation"

        }
        if (obj.status == "F") {
          obj.status = "Freigabe/Aktiv"
        }
        if (obj.status == "A") {
          obj.status = "Auf Aufbrauch"
        }
        if (obj.status == "B") {
          obj.status = "Blocked"
        }
        if (obj.status == "R") {
          obj.status = "Reserved"
        }
        if (obj.status == "I") {
          obj.status = "Idee"
        }
        if (obj.status == "Z") {
          obj.status = "Archiv Idee"
        }
        if (obj.status == "X") {
          obj.status = "Archiv"
        }

      });

      this.articalList = this.articles;
      this.sortByPartNumber();
      this.selectedRow = artPr;
    }

    );
}

  ngOnInit() {

    this.translate.onLangChange.subscribe(
      (translate: LangChangeEvent) => (
        this.cols = [
          { field: 'teilenummer', header: translate.translations['PART_NUMBERS'], pointer: 'true', textAlign: 'left', width: '7%' },
          { field: 'produktidee', header: translate.translations['PRODUCT_IDEA'], pointer: 'none', textAlign: 'left', width: '13%' },
          { field: 'namede', header: translate.translations['NAME_ETAS'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'teileinfo1', header: translate.translations['PART_INFO_1'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'supplierName', header: translate.translations['SUPPLIER'], pointer: 'none', textAlign: 'left', width: '9%' },
          { field: 'status', header: 'Status', pointer: 'true', textAlign: 'left', width: '5%' },
          { field: 'pm', header: 'PM', pointer: 'none', textAlign: 'left', width: '5%' },
          { field: 'bestand', header: translate.translations['DURATION'], pointer: 'none', textAlign: 'right', width: '5%' },
          { field: 'datumarchiv', header: translate.translations['DATE'], pointer: 'true', textAlign: 'left', width: '5%' },
          { field: 'wa', header: 'WA', pointer: 'none', textAlign: 'right', width: '5%' }
        ],
        this.colsSecond = [
          { field: 'teilenummer', header: translate.translations['PART_NUMBERS'], pointer: 'true', textAlign: 'left', width: '7%' },

          { field: 'produktidee', header: translate.translations['PRODUCT_IDEA'], pointer: 'none', textAlign: 'left', width: '13%' },
          { field: 'namede', header: translate.translations['NAME_ETAS'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'teileinfo1', header: translate.translations['PART_INFO_1'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'teileinfo2', header: translate.translations['PART_INFO_2'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'pm', header: 'PM', pointer: 'none', textAlign: 'left', width: '5%' },
          { field: 'status', header: 'Status', pointer: 'true', textAlign: 'left', width: '5%' }
        ],
        this.colsThird = [
          { field: 'teilenummer', header: translate.translations['PART_NUMBERS'], pointer: 'true', textAlign: 'left', width: '7%' },

          { field: 'produktidee', header: translate.translations['PRODUCT_IDEA'], pointer: 'none', textAlign: 'left', width: '13%' },
          { field: 'namede', header: translate.translations['NAME_ETAS'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'teileinfo1', header: translate.translations['PART_INFO_1'], pointer: 'none', textAlign: 'left', width: '8%' },
          { field: 'pm', header: 'PM', pointer: 'none', textAlign: 'left', width: '5%' },
          { field: 'status', header: 'Status', pointer: 'true', textAlign: 'left', width: '5%' }
        ],
        this.allCols = this.cols,
        this.freeSearchPl = this.freeSearchPl
      )
    );


    this.getAllArticle();
    this.curentPageNumber = 1;

  }


  onRowSelect(event) {
    localStorage.setItem("article_id", event.data.id.artikelId);
    this.colSelected = true;
    this.coldDelete = true;
    this.currentArticle = event.data;
    this.selectedRow = event.data;
    this.preivoseNextArticle = event.data.teilenummer;
    this.tabViewService.emitConfig(event.data);
    this.articleId = event.data.id.artikelId;
    //Cheack the article status and Allow to delete the article, if status not in Idea and Specification
    if (event.data.status != "Idee" && event.data.status != "Spezifikation") {
      this.coldDelete = false;
    }
  }

  showDialog(tab: String) {
    this.menuService.emitConfig(tab);
  }

  freeTextTable() {
    this.cols = this.colsSecond;
    this.searchText = "";
    this.freeSearchPl = "S_P_T";
  }
  standardTextTable() {
    this.cols = this.allCols;
    this.searchText = "";
    this.freeSearchPl = "search";
  }
  onRowUnselect(event) {
    this.colSelected = false;
  }



  sort(data, tableData) {
    if (this.table == null) {
      this.table = tableData;
    }
    this.table.first = 0;
    this.cheackTableSellect(this.articles);
    if (this.searchText.length > 3) {
      if (this.searchFree == 'tile') {
        this.articles = this.articalList;
        this.sortByPartNumber();
        var filterdArtical = this.articles.filter(it => {
          if (it.teilenummer != null) {
            return it.teilenummer.toLowerCase().includes(data.toLowerCase());
          }

        });
      }
      if (this.searchFree == 'free') {

        this.articles = this.articalList;
        this.sortByPartNumber();

        var filterdArtical = this.articles.filter(it => {
          if (it.produktidee == null) {
            it.produktidee = ""
          }
          if (it.teileinfo1 == null) {
            it.teileinfo1 = ""
          }
          if (it.teileinfo2 == null) {
            it.teileinfo2 = ""
          }
          if (it.nameen == null) {
            it.nameen = ""
          }


          if (it.produktidee != null && it.teileinfo1 != null && it.teileinfo2 != null && it.nameen != null) {
            return it.produktidee.toString().toLowerCase().includes(data.toLowerCase()) || it.teileinfo1.toString().toLowerCase().includes(data.toLowerCase()) || it.teileinfo2.toLowerCase().includes(data.toLowerCase()) || it.nameen.toLowerCase().includes(data.toLowerCase());
          }

        });
      }

      if (this.searchText != "") {
        this.articles = filterdArtical;
        this.cheackTableSellect(this.articles);
      } else {
        this.articles = this.articalList;
        this.sortByPartNumber();
      }
      if (this.searchText.length < 3 && this.searchFree == 'tile') {
        this.articles = this.articalList;
        this.sortByPartNumber();
      }
    } else {
      this.articles = this.articalList;
      this.cheackTableSellect(this.articles);
      this.sortByPartNumber();
    }

  }
  checkMethod(data) {
    this.dashboardtableservice.resetTableData();
    this.articles = data;
    if (this.table != null) {
      this.dashboardtableservice.resetTableData();
    }

    this.removeStatus();
    this.articles.forEach((obj, i) => {
      if (obj.lieferantBean) {
        this.articles[i]["supplierName"] = obj.lieferantBean.name;
      } else {
        this.articles[i]["supplierName"] = null;
      }
      if (obj.status == "S") {
        obj.status = "Spezifikation"

      }
      if (obj.status == "F") {
        obj.status = "Freigabe/Aktiv"
      }
      if (obj.status == "A") {
        obj.status = "Auf Aufbrauch"
      }
      if (obj.status == "B") {
        obj.status = "Blocked"
      }
      if (obj.status == "R") {
        obj.status = "Reserved"
      }
      if (obj.status == "I") {
        obj.status = "Idee"
      }
      if (obj.status == "Z") {
        obj.status = "Archiv Idee"
      }
      if (obj.status == "X") {
        obj.status = "Archiv"
      }

    });


    //Set the current page
    this.coldDelete = false;
    let pageNumber = this.curentPageNumber * 10 - 10;
    if (this.articles.length > pageNumber) {
      this.first = pageNumber;
    } else {
      this.first = pageNumber - 10;
    }

    this.articlSortByDate();

    //clear the select data
    
    if (this.currentFunctionality == 'create') {
      this.first = 0;
    }
  }


  standardStatusSearch(tableData) {
    this.searchFree = "tile";
    this.freeSearchPl = "search";
    if (this.table == null) {
      this.table = tableData;
    }

    this.selectedRow = this.currentArticle;
    this.articles = this.articalList;
    this.sortByStatus();
    this.searchText = "";
    var filterdArtical = this.articles.filter(it => {
      if (it.status != null) {
        return it.status == 'Idee' || it.status == 'Spezifikation';
      }

    });
    this.cols = this.allCols;
    this.articles = filterdArtical;
    this.selectedRow = null;
    this.colSelected = false;
    this.cheackTableSellect(this.articles);
    if (this.table != null) {
      this.table.reset();
    }
  }
  standardIntialSearch(data, tableData) {
    this.searchFree = "tile";
    this.freeSearchPl = "search";
    this.selectedRow = data;
    if (this.table == null) {
      this.table = tableData;
    }
    if (this.table != null) {
      this.table.reset();
    }
    this.cols = this.allCols;
    this.searchText = "";
    this.articles = this.articalList;
    this.sortByPartNumber();
    this.selectedRow = this.currentArticle;

    //Disabling the previous and next buttons
    this.selectedRow = null;
    this.colSelected = false;
    this.cheackTableSellect(this.articles);
  }

  //Free text box dailog
  freeTextSearchDailog() {
    this.freeTextDailog = true;
    this.searchFreeText = ""
  }

  // Free search text
  searchFreeTexts() {
    this.searchText = "";

    if (this.searchFreeText != null) {

      var filterdArtical = this.articles.filter(it => {
        if (it.produktidee == null) {
          it.produktidee = ""
        }
        if (it.teileinfo1 == null) {
          it.teileinfo1 = ""
        }
        if (it.teileinfo2 == null) {
          it.teileinfo2 = ""
        }
        if (it.nameen == null) {
          it.nameen = ""
        }

        if (it.produktidee != null && it.teileinfo1 != null && it.teileinfo2 != null && it.nameen != null) {
             return it.produktidee.toString().toLowerCase().includes(this.searchFreeText.toLowerCase()) || it.teileinfo1.toString().toLowerCase().includes(this.searchFreeText.toLowerCase()) || it.teileinfo2.toLowerCase().includes(this.searchFreeText.toLowerCase()) || it.nameen.toLowerCase().includes(this.searchFreeText.toLowerCase());
        }

      });
    }
    this.articles = filterdArtical;
    this.cols = this.colsSecond;
    this.freeTextDailog = false;
  }


  //Sorting the article by date
  articlSortByDate() {
      let sortedArtcle = this.articles.sort((data1, data2) => {
      let value1 = data1.id.artikelId;
      let value2 = data2.id.artikelId;
      if (value1 > value2) {
        return -1;
      }

      if (value1 < value2) {
        return 1;
      }

      return 0;
    });
    this.articles = sortedArtcle;
  }

  setTable(data) {
    this.table = data;
  }

  //Privious search
  priviousSearch() {
    this.table.first = 0;
    if (this.preivoseNextArticle != null) {
      var artkls = [];
      let presentArticle = parseInt(this.preivoseNextArticle.substring(1));
      artkls = this.articalList;
      var filterdArtical = artkls.filter(it => { return it.teilenummer != null && (it.status != "B" && it.status != "R") });
      if (filterdArtical.length > 0) {
        var filterdArtical = filterdArtical.filter(it => {
          var tele = parseInt(it.teilenummer.substring(1));
          return tele < presentArticle
        });
      }
      if (filterdArtical.length > 0) {
        let sortedArtcle = filterdArtical.sort((data1, data2) => {
          let value1 = data1.teilenummer
          let value2 = data2.teilenummer
          if (value1 > value2) {
            return -1;
          }

          if (value1 < value2) {
            return 1;
          }

          return 0;
        });
        this.cols = this.colsThird;
        this.articles = [sortedArtcle[0]];
        this.selectedRow = sortedArtcle[0];
        this.currentArticle = this.selectedRow;
        this.tabViewService.emitConfig(sortedArtcle[0]);
        this.preivoseNextArticle = sortedArtcle[0].teilenummer

      }
      if (filterdArtical.length == 0) {
        this.noPart = true;
      }
    } else {
      this.artSelected = true;
    }
  }


  //Next Search
  nextSearch() {
    this.table.first = 0;
    if (this.preivoseNextArticle != null) {
      var artkls = [];
      let presentArticle = parseInt(this.preivoseNextArticle.substring(1));
      artkls = this.articalList;
      var filterdArtical = artkls.filter(it => { return it.teilenummer != null && (it.status != "B" && it.status != "R") });
      if (filterdArtical.length > 0) {
        var filterdArtical = filterdArtical.filter(it => {
          var tele = parseInt(it.teilenummer.substring(1));
          return tele > presentArticle
        });
      }
      if (filterdArtical.length > 0) {
        let sortedArtcle = filterdArtical.sort((data1, data2) => {
          let value1 = data1.teilenummer
          let value2 = data2.teilenummer
          if (value1 < value2) {
            return -1;
          }

          if (value1 > value2) {
            return 1;
          }

          return 0;
        });
        this.cols = this.colsThird;
        this.articles = [sortedArtcle[0]];
        this.selectedRow = sortedArtcle[0];
        this.tabViewService.emitConfig(sortedArtcle[0]);
        this.preivoseNextArticle = sortedArtcle[0].teilenummer

      }
      if (filterdArtical.length == 0) {
        this.noPart = true;
      }

    } else {
      this.artSelected = true;
    }
  }


  //Sort the Article by part number
  sortByPartNumber() {
    let articlePrt1 = this.articalList.filter(it => { return it.teilenummer != null });
    let articlePrt2 = this.articalList.filter(it => { return it.teilenummer == null });
    let sortedArtcle1 = articlePrt1.sort((data1, data2) => {
    let value1 = data1.teilenummer;
    let value2 = data2.teilenummer;
      if (value1 > value2) {
        return 1;
      }

      if (value1 < value2) {
        return -1;
      }

      return 0;
    });
    let sortedArtcle2 = articlePrt2.sort((data1, data2) => {
      let value1 = data1.produktidee;
      let value2 = data2.produktidee;
      if (value1 > value2) {
        return 1;
      }

      if (value1 < value2) {
        return -1;
      }

      return 0;
    });

    this.articles = sortedArtcle2.concat(sortedArtcle1);
    if (this.currentArticle) {
      this.selectedRow = this.currentArticle;
    }
  }

  //Sort by status
  sortByStatus() {
    let sortedArtcle = this.articles.sort((data1, data2) => {
      let value1 = data1.status;
      let value2 = data2.status;
      if (value1 > value2) {
        return 1;
      }

      if (value1 < value2) {
        return -1;
      }

      return 0;
    });
    this.articles = sortedArtcle;
  }

  //Check selcted article is in the table or
  cheackTableSellect(data) {
    if (this.selectedRow) {
      var artPr = data.find(x => x.produktidee == this.currentArticle.produktidee);
      const index = data.findIndex(role => role.produktidee === this.currentArticle.produktidee);
      if (index > -1) {
        this.tabViewService.emitConfig(this.currentArticle);
      } else {
        this.tabViewService.emitConfig({})
      }
    } else {
      this.tabViewService.emitConfig({})
    }
  }

  //Get the all articles
  getAllArticle() {
    this.articleService.getArticles().subscribe(res => {
      this.selectedRow = this.currentArticle;
      this.articles = res.json().data;
      this.articalList = res.json().data;
      this.articleInput = res.json().data;
      this.removeStatus();
      this.sortByPartNumber();
      this.articles.forEach((obj, i) => {
        if (obj.lieferantBean) {
          this.articles[i]["supplierName"] = obj.lieferantBean.name;
        } else {
          this.articles[i]["supplierName"] = null;
        }
        if (obj.status == "S") {
          obj.status = "Spezifikation"

        }
        if (obj.status == "F") {
          obj.status = "Freigabe/Aktiv"
        }
        if (obj.status == "A") {
          obj.status = "Auf Aufbrauch"
        }
        if (obj.status == "B") {
          obj.status = "Blocked"
        }
        if (obj.status == "R") {
          obj.status = "Reserved"
        }
        if (obj.status == "I") {
          obj.status = "Idee"
        }
        if (obj.status == "Z") {
          obj.status = "Archiv Idee"
        }
        if (obj.status == "X") {
          obj.status = "Archiv"
        }
      });
      this.freeSearchPl = 'search';
      this.selectedRow = this.currentArticle;
    });
    this.selectedRow = this.currentArticle;
  }

  //Excel exporting 
  public exportExcel(tableData) {
    var allData = [];
    var object = {}
    tableData._value.forEach(item => {
    var excelObject = {};
    tableData._columns.forEach(data => {
    object[data.header] = data['header'];
    excelObject[data.header] = item[data.field];
      });
    allData.push(excelObject);
    });

    this.excelService.exportAsExcelFile(allData, 'Article Export_TAD');
  }

  // Remove the Reserved and Blocked status

  public removeStatus() {
    let filterdSts = this.articles.filter(it => {
      return it.status != "B" && it.status != "R"
    });
    this.articalList = filterdSts;
    this.articles = filterdSts;
    }


  //Pageinatore
  paginate(event) {
    let pageIndex = event.first / event.rows + 1 // Index of the new page if event.page not defined.
    this.curentPageNumber = pageIndex;
    $("#contentData").animate({ scrollTop: 0 }, 900);

  }
}
