import { Component, OnInit } from '@angular/core';
import { MenuService } from 'app/menu/menu.service';
import { CsvService } from "../services/csv.service";
import { Constants } from "../constants";


@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})

export class ImportComponent implements OnInit {
  displayResultDlg: boolean = false;
  display: boolean = false;
  displayResultTable: boolean = false;
  showImports: string;
  importImage: any;
  validCsv: boolean = false;
  resultList: any =[];

  importAttribute: boolean = false;
  importSprache: boolean = false;
  importBenennung: boolean = false;
  importBemerkung: boolean = false;

  datemFile: any;
  datemFileNmae: string;

  ekJahrDate: any;
  ekJahrDateOptions: any = [];
  ekDatemFile: any;
  ekDatemFileNmae: string;


  abJahrDateOptions: any = [];
  abJahrDate: any;
  abDatemFile: any;
  abDatemFileNmae: string;
  abDatum: any;



  bsDatemFile: any;
  bsDatemFileNmae: string;
  bsDatum: any;
  bsSpFile: any;
  bsSpFileNmae: string;


  validatedObject: any = [];

  defaultDate: any;
  importValidation: any;
  file: boolean = false;

  constructor(private menuService: MenuService, private csvService: CsvService) {

    this.menuService.importMenu.subscribe(item => {
      this.showImports = item;
      this.validCsv = false;
      this.datemFile = null;
      this.datemFileNmae = null;
      this.showDailog();
      this.resetData();
    });
  }

  ngOnInit() {
    this.validatedObject = null;
    this.ekJahrDateOptions = [];
    this.abJahrDateOptions = [];
    let nowDate = new Date();


    for (let i = 0; i < 12; i++) {
      this.ekJahrDateOptions.push({ name: nowDate.getFullYear() + 1 - i, code: nowDate.getFullYear() + 1 - i });

    }
    this.abJahrDateOptions.push({ name: nowDate.getFullYear(), code: nowDate.getFullYear() }, { name: nowDate.getFullYear() - 1, code: nowDate.getFullYear() - 1 });
  }

  showDailog() {
    this.display = true;

  }

  importFile(event, fileType) {
    this.validCsv = false;
    var parts = event.target.value.split('.');
    if (this.showImports == 'article') {
      this.datemFileNmae = event.target.files[0].name;
    }
    if (this.showImports == 'ek') {
      this.ekDatemFileNmae = event.target.files[0].name;
    }
    if (this.showImports == 'abgange') {
      this.abDatemFileNmae = event.target.files[0].name;
    }

    if (this.showImports == 'bestande') {
      if (fileType == "bsDatemFileNmae") {
        this.bsDatemFileNmae = event.target.files[0].name;

      } else {
        this.bsSpFileNmae = event.target.files[0].name;
      }

    }
  if (parts[parts.length - 1].toLowerCase() == "csv") {
      var fileStatus = true;
      this.importValidation = null;
      if (this.showImports == 'article') {
        this.datemFileNmae = event.target.files[0].name;
        this.datemFile = event.target.files[0];
      }
      if (this.showImports == 'ek') {
        this.ekDatemFileNmae = event.target.files[0].name;
        this.ekImportFile = event.target.files[0];
        }
      if (this.showImports == 'abgange') {
        this.abDatemFileNmae = event.target.files[0].name;
        this.abDatemFile = event.target.files[0];
      }

      if (this.showImports == 'bestande') {

        if (fileType == "bsDatemFileNmae") {
          this.bsDatemFileNmae = event.target.files[0].name;
          this.bsDatemFile = event.target.files[0];

        } else {
          this.bsSpFileNmae = event.target.files[0].name;
          this.bsSpFile = event.target.files[0];
        }

      }

      event.target.value = null;
      this.file = false;
    } else {
      this.validCsv = true;
    }
    event.target.value = null;
  }


  //save article import 
  saveImportArticle() {
    this.csvService.uploadImportFile(this.datemFile).subscribe(item => {
    this.validatedObject = item;
    });
    this.display = true;
  }

  // Save Ek import

  saveImportEK() {
    if (this.ekDatemFileNmae == null) {
      this.file = true;
    }
    else {
      if (this.ekJahrDate == null) {
        this.ekJahrDate = this.ekJahrDateOptions[0]
      }
      this.csvService.uploadImportFileEk(this.ekImportFile, this.ekJahrDate.code).subscribe(item => {
        this.display = false;
        this.displayResultDlg = true;
        this.validatedObject = item;
        if (item.successCount > 0) {
          this.displayResultTable = true;
          this.resultList = this.validatedObject.reponseData;  
        }
        else if (item.failureCount > 0) {
          this.displayResultTable = false;
        }
      });
    }
  }

  //save Abgänge 
  saveAbgange() {
    if (this.abDatemFileNmae == null) {
      this.file = true;
    }
    else {
      if (this.abJahrDate == null) {
        this.abJahrDate = this.abJahrDateOptions[0]
      }
      this.csvService.abangeImportFile(this.abDatemFile, this.abDatum, this.abJahrDate.code).subscribe(item => {
      this.display = false;
      this.displayResultDlg = true;
      this.validatedObject = item;
        if (item.successCount > 0) {
          this.displayResultTable = true;
          this.resultList = this.validatedObject.reponseData;  
        }
        else if (item.failureCount > 0) {
          this.displayResultTable = false;
        }
      });
    }
  }

  // Save Bestand
  saveBestand() {
    if (this.bsDatemFileNmae == null) {
      this.file = true;
    }
    else {
      this.csvService.BestandImportFile(this.bsDatemFile, this.bsDatum).subscribe(item => {
        this.display = false;
        this.displayResultDlg = true;
        this.validatedObject = item;
        if (item.successCount > 0) {
          this.displayResultTable = true;
          this.resultList = this.validatedObject.reponseData;
        }
        else if (item.failureCount > 0) {
          this.displayResultTable = false;
        }
      });
    }
  }

  // Ek import file
  ekImportFile(event) {
    this.validCsv = false;
    var parts = event.target.value.split('.');
    this.ekDatemFileNmae = event.target.files[0].name;

    if (parts[parts.length - 1].toLowerCase() == "csv") {
      this.ekImportFile = event.target.files[0];

    } else {
      this.validCsv = true;
    }
  }

  //Abgänge  import
  abangImportFile(event) {
    this.validCsv = false;
    var parts = event.target.value.split('.');
    this.abDatemFileNmae = event.target.files[0].name;
    this.file = false;
    if (parts[parts.length - 1].toLowerCase() == "csv") {
      this.abDatemFile = event.target.files[0];

    } else {
      this.validCsv = true;
    }
  }

  // reset the data
  public resetData() {
    this.importAttribute = false;
    this.importSprache = false;
    this.importBenennung = false;
    this.importBemerkung = false;
    this.datemFile = false;
    this.datemFileNmae = null;
    this.validCsv = false;
    this.ekImportFile = null;
    this.ekDatemFileNmae = null;
    this.ekJahrDate = null;
    this.importValidation = null;
    this.validatedObject = null;
    this.abDatemFile = null;
    this.abDatemFileNmae = null;
    this.abJahrDate = null;
    this.abDatum = new Date();;
    this.bsDatemFile = null;
    this.bsSpFile = null;
    this.bsDatum = new Date();
    this.bsDatemFileNmae = null;
    this.bsSpFileNmae = null;
    this.file = false;

  }

  public importValidate(importData) {
    this.importAttribute = false;
    this.importSprache = false;
    this.importBenennung = false;
    this.importBemerkung = false;
    importData.forEach(element => {
      if (element.length < 3 || element.length > 4) {
        this.importAttribute = true;
      }

      if (!this.importAttribute && (element[1] != Constants.SPRACHE_ID_ENGLISH && element[1] != Constants.SPRACHE_ID_FRENCH && element[1] != Constants.SPRACHE_ID_GERMAN && element[1] != Constants.SPRACHE_ID_ITALIAN && element[1] != Constants.SPRACHE_ID_SPANISH)) {
        this.importSprache = true;
        }
      if (!this.importSprache && (element[2].length < 1 || element[2].length > 255)) {
        this.importBenennung = true;
      }

      if (!this.importBenennung && element.length == 4) {
        if (element[3].length < 1 || element[3].length > 255) {
          this.importBemerkung = true;
        }
      }
    });

    return (this.importAttribute || this.importBemerkung || this.importBenennung || this.importAttribute)
  }

}
