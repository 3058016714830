
const PREV_YEAR = new Date().getFullYear()-1;
const PREV_YEAR_1 = new Date().getFullYear()-2;
const PREV_YEAR_2 = new Date().getFullYear()-3;
const PREV_YEAR_3 = new Date().getFullYear()-4;

export class Constants {

  public  static  SPRACHE_ID_GERMAN  = "D";
  public  static  SPRACHE_ID_ENGLISH:String = "E";
  public  static  SPRACHE_ID_ITALIAN:String = "I";
  public  static  SPRACHE_ID_FRENCH:String = "F";
  public  static  SPRACHE_ID_SPANISH:String = "S";

  // public static HOST = "https://localhost:9089/nuzuco";
  
  // public static HOST_INT = "https://localhost:9089/nuzuco";
  
  // public static HOST = "https://10.0.1.111/nuzuco/api/v1";
   public static HOST = "https://tad-int.aftersales.daimlertruck.com/nuzuco";
  //public static HOST = "https://tad.aftersales.daimlertruck.com/nuzuco/";
  public static logoutUrl = "https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1/oauth2/v2.0/logout";
  public static TABLE_COLUMNS = [
    { id:1, field: 'teilenummer', header: 'PART_NUMBERS', pointer: 'true', numberFormat:false },
    { id:2, field: 'produktidee', header: 'PRODUCT_IDEA', pointer: 'none', numberFormat:false },
    { id:3, field: 'namede', header: 'benennung_ETAS', pointer: 'none', numberFormat:false },
    { id:4, field: 'teileinfo1', header: 'PART_INFO_1', pointer: 'none', numberFormat:false },
    { id:5, field: 'name', header: 'SUPPLIER', pointer: 'none', numberFormat:false },
    { id:6, field: 'status', header: 'Status', pointer: 'true', numberFormat:false },
    { id:7, field: 'pm', header: 'PM', pointer: 'none', numberFormat:false },
    { id:8, field: 'bestand', header: 'DURATION', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:9, field: 'bestanddatum', header: 'DATE', pointer: 'true', numberFormat:false },
    { id:10,field: 'wa', header: 'WA', pointer: 'none', textAlign: 'right', numberFormat:true},
    { id:11,field: 'NAMEEN', header: 'Ben. Englisch', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:12,field: 'NAMEES', header: 'Ben. Spanisch', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:13,field: 'NAMEFR', header: 'Ben. Fransosich', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:14,field: 'sortimentsklasse', header: 'Sortimentsklasse', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:15,field: 'DATUMFREIGABE', header: "Erstanlagedatum", pointer: 'none', numberFormat:false },
    { id:16, field: 'erstdisposition', header: 'erstdispositionmenge', pointer: 'none', numberFormat:false },
    { id:17,field: 'liefertermin', header: 'liefertermin', pointer: 'none', numberFormat:false },
    { id:18,field: 'macode', header: 'mc_code', pointer: 'none', numberFormat:false },
    { id:19,field: 'teileinfo2', header: 'PART_INFO_2', pointer: 'none', numberFormat:false },
    { id:20,field: 'ek', header: 'ek', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:21,field: 'rg', header: 'rg', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:22,field: 'blp', header: 'blp_vk', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:23,field: 'vk', header: 'VK', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:24,field: 'ek1', header: 'ek1', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:25,field: 'rg1', header: 'rg1', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:26,field: 'wa1', header: 'wa1', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:27,field: 'vk1', header: 'VK1', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:28,field: 'ek2', header: 'ek2', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:29,field: 'rg2', header: 'rg2', pointer: 'none', textAlign: 'right', numberFormat:false },
    { id:30,field: 'wa2', header: 'wa2', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:31,field: 'vk2', header: 'VK2', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:32,field: 'aufshlagPer', header: 'Aufschlag %', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:33,field: 'aufshlagAbsolute', header: 'Aufschlag Absolute', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:34,field: 'CURRENT_YEAR_ABGANG', header: 'Abgang Lfd Jahr', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:35,field: 'LAST_YEAR_ABGANG', header: 'Abgang '+PREV_YEAR, pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:36,field: 'last_year_1_ABGANG', header: 'Abgang '+PREV_YEAR_1, pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:37,field: 'last_year_2_ABGANG', header: 'Abgang '+PREV_YEAR_2, pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:38,field: 'last_year_3_ABGANG', header: 'Abgang '+PREV_YEAR_3, pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:39,field: 'benennung', header: 'Zuordnung', pointer: 'none', numberFormat:false },
    { id:40,field: 'deckungsbeitrag', header: 'Deckungsbeitrag', pointer: 'none', textAlign: 'right', numberFormat:true },
    { id:41,field: 'reichweite', header: 'Reichweite', pointer: 'none', textAlign: 'right', numberFormat:true },
    
    //{ id:15, field: 'ubersetzungen', header: 'ubersetzunger', pointer: 'none', textAlign: 'right', numberFormat:false },
    //{ id:18, field: 'liefertermine', header: 'liefertermine', pointer: 'none', numberFormat:false },
    // { id:26,field: 'bestand', header: 'Bestand', pointer: 'none', textAlign: 'right', numberFormat:false },
    ];

  constructor() {
      
  }

} 
