import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import {ArticleService} from '../services/article.service';

@Component({
  selector: 'app-menu-email',
  templateUrl: './menu-email.component.html',
  styleUrls: ['./menu-email.component.scss']
})
export class MenuEmailComponent implements OnInit {

  email:boolean=false;
  selectedEmail:boolean;
  constructor(private menuService: MenuService,private articleService:ArticleService) {
  this.menuService.email.subscribe(value => {
  this.getEmailStatus();
  this.showDialog();
    })
   }

   showDialog()
   {
     this.email=true;
   }
  ngOnInit() {
  }

  public getEmailStatus(){
  this.articleService.getEmailStatus().subscribe(item=>{
  this.selectedEmail=item;
  });
  }
  public setEmailStatus(){ 
    this.articleService.setEmailStatus(this.selectedEmail).subscribe(item=>{
    this.email=false;
    });
    }
}
