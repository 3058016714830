import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TreeviewItem } from 'ngx-treeview';
import { Http, RequestOptions,Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PostAssociations } from 'dto/PostAssociations';
import { ModelSeries } from 'dto/ModelSeries';
import { DeleteBautypDto } from 'dto/DeleteBautypDto';
import { DeleteBauvariantDto } from 'dto/DeleteBauvariantDto';
import { DeleteBaureiheDto } from 'dto/DeleteBaureiheDto';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class TabViewService {
  public articalObservable = new Subject<any>();
  public tableColumns = new Subject<any[]>();
  public articleList = new Subject<any>();
  public supplier = new Subject<any>();
  public assignSupplier = new Subject<any>();
  public teilenumberexport = new Subject<any>();
  public statusvarb = new Subject<any>();
  public ArticleStatus = new Subject<any>();
  public historydetail = new Subject<any>();
  public emiteRabateGrop = new Subject<String>();
  public emiteArticleId = new Subject<any>();
  constructor(private https: Http,private http: HttpClient) { }
  httpOptions = new RequestOptions({ headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('msal.idtoken')}`}) });

  headers = new HttpHeaders()
  .set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`)
  emitConfig(val) {
    this.articalObservable.next(val);
  }

  emitConfigTablr(val) {
    this.tableColumns.next(val);
  }

  emitArticle(val) {
    this.articleList.next(val);
  }

  emitstatus(val1) {
    this.statusvarb.next(val1);
  }

  emitArticleStatus(status) {
    this.ArticleStatus.next(status);
  }


  emiteSupplier(val) {

    this.supplier.next(val);
  }
  emiteAssigneSupplier(val) {

    this.assignSupplier.next(val);
  }

  benningEtas: boolean = false;
  TeileF: boolean = false;

  emitMenuTeile() {
    this.teilenumberexport.next();
  }


  getZuordnungData(id): Observable<any> {
    return this.https.get(`${Constants.HOST}/getBauvarianteByArtikel/${id}`,this.httpOptions);
  }

  createZuordnungForArtikels(data: PostAssociations[]) {
    return this.http.post<PostAssociations[]>(`${Constants.HOST}/createAssociations`, data,{headers:this.headers})
      .map((response) => response)
      ;
  }

  deleteZuordnungForArtikel(data: PostAssociations[]) {
    return this.http.post<PostAssociations[]>(`${Constants.HOST}/deleteAssociations`, data,{headers:this.headers})
      .map((response) => response)
      ;
  }

  getDateiBaureiheData(): Observable<any> {
    return this.https.get(`${Constants.HOST}/getBaureiheDta`,this.httpOptions).map(response => response.json());
  }

  getDateiBaureiheSelData(): Observable<any> {
    return this.https.get(`${Constants.HOST}/getBaureiheSelDta`,this.httpOptions).map(response => response.json());
  }

  saveSelectedBaureihe(selBau: ModelSeries[]): Observable<any> {
    return this.http.post<ModelSeries[]>(`${Constants.HOST}/saveSelectedBaureihe/`,selBau,{headers:this.headers})
      .map((response) => response)
      ;
  }

  getAdminBaureiheData(): Observable<any> {
    return this.https.get(`${Constants.HOST}/getAllModelSeriesMasterDta`,this.httpOptions).map(response => response.json());
  }

  deleteSelectedBaureihe(delBaureihedto: DeleteBaureiheDto) {

    return this.http.post<DeleteBaureiheDto>(`${Constants.HOST}/deleteBaureihe/`, delBaureihedto,{headers:this.headers})
      .map((response) => response);
  }

  deleteSelectedBauvarTyp(delBautyp: DeleteBautypDto) {
    return this.http.post<DeleteBautypDto>(`${Constants.HOST}/deleteBauvartyp/`, delBautyp,{headers:this.headers})
      .map((response) => response);
  }

  deleteSelectedBauvariante(delBauvariant: DeleteBauvariantDto) {
    return this.http.post<DeleteBauvariantDto>(`${Constants.HOST}/deleteBauvariant/`, delBauvariant,{headers:this.headers})
      .map((response) => response);
  }

  teilenumbers(startteile, endteile): Observable<any> {
    startteile = startteile.replace(/ /g, "");
    endteile = endteile.replace(/ /g, "");
    return this.https.get(`${Constants.HOST}/exportTeiliNumber/${startteile}/${endteile}`,this.httpOptions).map(response => response.json());
  }

  sendToPresto(articleId) {
    return this.http.get(`${Constants.HOST}/prestoTest/${articleId}`);
  }


  emitRabert(val) {
    this.emiteRabateGrop.next(val);
  }

  emitUpdatServic(val) {
    this.emiteArticleId.next(val);
  }
}
