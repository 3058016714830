import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { from } from 'rxjs';
import { ArticleService } from '../services/article.service';
import { MessageService } from 'primeng/api';
import { CopyArticleDto } from '../../dto/copy-article-dto';
import { TranslateService } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { DashboardTableService, DeshboardMetaData } from '../component/dashboard-table/dashboard-table.service';

export class DashboardMetaData {
  action: string = 'home';
  projection: string;
  criteria: any;
}



@Component({
  selector: 'app-atricle-create',
  templateUrl: './atricle-create.component.html',
  styleUrls: ['./atricle-create.component.scss'],
  providers: [MessageService]
})
export class AtricleCreateComponent implements OnInit {

  @Output('dataModal')
  dataModal: EventEmitter<string> = new EventEmitter<string>();
  criteria: Search = new Search();

  display: boolean = false;
  crateRadio: String = 'create';
  copyRadio: String;
  productId: String;
  articles: any;
  rowselect: boolean = false;
  enterproductid: boolean = false;
  copyarticledata: CopyArticleDto;
  
  constructor(private menuService: MenuService, private articleService: ArticleService, private messageService: MessageService, private dashboardService: DashboardTableService,
    public translate: TranslateService, private spinnerService: Ng4LoadingSpinnerService) {
    this.menuService.configObservable.subscribe(value => {
      if (value == 'create') {
        this.productId = "";
        this.showDialog();
      }
    })
  }

  initialization() {
    this.copyarticledata = {
      articleID: '',
      lieferant: false,
      preis: false,
      benennungEtas: false,
      teileinfo: false,
      teilefamile: false,
      zuordnung: false,
      dokumente: false
    }
  }

  ngOnInit() {
    this.initialization();
  }
  showDialog() {
    this.crateRadio = 'create';
    this.display = true;
  }
  creat() {
    if (this.crateRadio == 'create') {
      this.rowselect = false;
      this.enterproductid = false;
      if (this.productId == '') {
        this.enterproductid = true;
        this.rowselect = false;
      }
      else {
        this.enterproductid = false;
        this.rowselect = false;
        this.spinnerService.show();
        //var articlename=this.productId;
        // var s = encodeURIComponent(articlename.toString());
        // console.log("Encoded dtring" + s);
       //this.articleService.createArticle(encodeURIComponent(this.productId.toString())).subscribe(
          this.articleService.createArticle(this.productId.toString()).subscribe(
          (res: any) => {
            if (res) {
              this.display = false;
              this.loadTable();
              this.spinnerService.hide();
              this.messageService.add({ detail: this.translate.instant('article_create'), key: 'c' });
            }
          },
          error => { console.log("Error occured while creating an artikel")}
        );
      }
    }
    if (this.crateRadio == 'copy') {
      this.enterproductid = false;
      this.rowselect = false;
      this.copyarticledata.articleID = localStorage.getItem('article_id');
      JSON.stringify(this.copyarticledata.articleID);
      this.rowselect = false;
      if (this.copyarticledata.articleID == null) {
        this.rowselect = true;
        this.enterproductid = false;
      }
      else {
        this.rowselect = false;
        this.enterproductid = false;
        this.spinnerService.show();
        this.articleService.copyArticle(this.copyarticledata).subscribe(
          (res: any) => {
            if (res) {
              this.display = false;
              this.loadTable();
              this.spinnerService.hide();
              this.messageService.add({ detail: this.translate.instant('article_copy'), key: 'c' });
            }
          },
          error => { console.log("Error occured while copying an artikel")}
        );
        this.initialization();
      }
    }
  }
  //Prise sort by date
  public sortPriceById(item) {
    let sortedRG = item.sort((data1, data2) => {
      let value1 = data1.id.artikelId;
      let value2 = data2.id.artikelId;
      if (value1 > value2) {
        return -1;
      }

      if (value1 > value2) {
        return 1;
      }

      return 0;
    });

    return sortedRG;
  }

  //REfresh the table
  public loadTable() {
    const meta = new DashboardMetaData();
    const criteria = this.criteria.clone();
    meta.action = 'create';
    this.dashboardService.loadTableData(meta);
  }
}
class Search {
  branch: 0;
  department: 0;
  productManager: any;
  supplier: 0;
  selectedAssignmentsList: any[];
  patsFamily: 0;
  assortmentGroup: 0;
  selectedStatuslist: any[];
  prestoSystem: false;
  requiredColumnNames: any;
  valueDatesList: any;
  rangeAnalysisResultOrder: any;

  clone(): Search {
    return Object.assign({}, this) as Search;
  }
}