import { Injectable } from '@angular/core';
import { Http, RequestOptions, Response ,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from '../../../node_modules/rxjs';

import { Kontakt, Lieferant, RaberttGruppen } from '../../dto/Lieferant';
import { HttpEvent, HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';

@Injectable()
export class RabattgrupenService {
  httpOptions = new RequestOptions({ headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('msal.idtoken')}`}) });
  constructor(
    private http: Http, private httpClient: HttpClient
  ) { }

 
  getAllRabattgrupen(): Observable<any> {
    return this.http.get(`${Constants.HOST}/getAllRabattgruppe`,this.httpOptions).map(response => response.json());
  }


  createRabattgrupen(data: RaberttGruppen): Observable<any> {
    return this.http.post(`${Constants.HOST}/createRabattgrupper`, data)
      .map((response) => response);
  }


  deleteRabertGrupen(id) {
    return this.http.delete(`${Constants.HOST}/deleteRabattgrupper/${id}`);
  }


  getAllRabattgrupenList(): Observable<any> {
    return Observable.create(observer => {
      var rg = [];
      this.getAllRabattgrupen().subscribe(item => {
        item.data.forEach(element => {
          rg.push({ name: element.id.rg +  "(" + element.prozent + "%)", code: element.prozent / 100, id: element.id.rg });
        });
        observer.next(rg);
      });
    });
  }

}