import { Injectable } from '@angular/core';
import { Http, RequestOptions,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from '../../../node_modules/rxjs';

import { Kontakt, Lieferant } from '../../dto/Lieferant';
import { HttpEvent, HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import { Header } from 'primeng/primeng';

@Injectable()
export class LieferantService {
  constructor (
    private http: Http,private httpclient: HttpClient
  ) {}
  httpOptions = new RequestOptions({ headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('msal.idtoken')}`}) });
  headers = new HttpHeaders()
  .set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`) 


  getAllLieferant():Observable<any> {
    return this.http.get(`${Constants.HOST}/getAllLieferants`,this.httpOptions);  }

  createLieferant(data:Lieferant): Observable<any>  {
    return this.httpclient.post<Lieferant>( `${Constants.HOST}/createLieferant/`,data,{headers:this.headers})
   .map(( response ) => response);
  }

  deleteLieferant(id){
    return this.http.delete(`${Constants.HOST}/deleteLieferant/${id}`,this.httpOptions);
  }

  deleteContact(id){
    return this.http.delete(`${Constants.HOST}/deleteKontakt/${id}`,this.httpOptions);
  }
  
  getLiefrentById(id):Observable<any>{
    return this.http.get(`${Constants.HOST}/getAllLieferantsById/${id}`,this.httpOptions);
  }

  getLiefrentByName(name):Observable<any>{
    return this.http.get(`${Constants.HOST}/getAllLieferantsByName/${name}`,this.httpOptions).map(response => response.json());
  }


  getAllDesponents():Observable<any>{
    return this.http.get(`${Constants.HOST}/fetchAllDispositions`,this.httpOptions).map(response => response.json());
    
  }

  getAllDesponentsList(): Observable<any> {
    return Observable.create(observer => {
      var rg =  [];
      this.getAllDesponents().subscribe(item => {
        item.data.forEach(element => {
          rg.push({ name: element , code: element });
        });
        observer.next(rg);
      });
    });
  }
}