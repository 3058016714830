import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({name: 'numberFormatter'})
export class NumberFormatterPipe implements PipeTransform, OnDestroy {
    presentLanguage: string;
    subsId:Subscription;
    constructor(public translate: TranslateService) {
        this.subsId = translate.onLangChange.subscribe(lang => {
        this.presentLanguage = lang.lang;
          })
    }

    ngOnDestroy(){
        if(this.subsId) {
          this.subsId.unsubscribe();
        }
    }
  transform(value: any, args:any, sec:any): string {
    if(args.numberFormat === true){
        var dvalue = +value;
        if(dvalue>100) {
          dvalue = dvalue/100;
        }
        return new Intl.NumberFormat(this.presentLanguage === 'de'?'de-DE':'en-US', {minimumFractionDigits:2, maximumFractionDigits: 2}).format(dvalue);
        } else {
              return value;
          }
}
}