import { Component, OnInit, SimpleChanges, OnChanges, Input, Output, EventEmitter, DoCheck, KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TabViewService } from '../tab-view/tab-view.service';
import { MenuItem, LazyLoadEvent } from 'primeng/api';
import { PartNumber } from '../pipe/part-number-pipe'
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { PartNumbers } from '../../dto/PartNumbers';
import { ArticleService } from '../services/article.service';
import { MessageService } from 'primeng/api';
import { PostAssociations } from '../../dto/PostAssociations';
import _ from 'lodash';
import { ExcelService } from '../services/excel.service';
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { MenuService } from '../../app/menu/menu.service';
import { FormatnumberDirective } from '../directive/formatnumber.directive';
import { RabattgrupenService } from '../services/rabbattgrupen.service';
import { Directive, HostListener, ElementRef } from "@angular/core";
import { DocumentService } from "../services/documentFile.service"
import { saveAs } from 'file-saver'
import { HistoryDto } from '../../dto/history-dto';
import * as moment from "moment";
import { DashboardTableService } from '../component/dashboard-table/dashboard-table.service';
import { DatePipe } from '@angular/common'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from 'app/services/UserService';
import { User } from 'dto/UserModel';
import { RoleService } from 'app/services/RoleService';
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements';

interface Status {
  name: string;
  code: string;
}

interface warengrupe {
  name: string;
  code: string;
}

interface teilef {
  name: string;
  code: string;
}

interface sgrupe {
  name: string;
  code: string;
}
interface MarginEmDto {
  marge: String;
  abteilungName: String;
  abteilungUserEmeaIdEmail: String;
}

export const parseValue = (value, lang) => {
  var d = value.toString().split(lang === 'de' ? "," : '.');
  if (d.length > 1) {
    var c = d.slice(0, d.length - 1);
    c = c.join("");
    c = c.replace(/\./g, '');
    c = c.replace(/,/g, '');
    var dc = (d[d.length - 1]);
    return parseFloat(c + '.' + (dc.length > 0 ? dc[0] : '0') + (dc.length > 1 ? dc[1] : '0'));
  }
  return d.join('').replace(/\./g, '').replace(/,/g, '');
}

export const formateCurrency = (value, lang) => {
  var dvalue = +value;
  return new Intl.NumberFormat(lang === 'de' ? 'de-DE' : 'en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(dvalue);
}

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
  providers: [PartNumber, MessageService, FormatnumberDirective, DatePipe]
})
export class TabViewComponent implements OnInit, OnChanges, DoCheck {
  changeVersionCount: number = 0;

  @Input()
  articles: any[];
  @Input() selectedRowTnum: any;
  articleDtaList: any[];
  ProductManagement: string;
  partNum: PartNumbers = new PartNumbers();
  partNumbersList: PartNumbers[] = [];
  partNumListWithStatus: PartNumbers[] = [];
  Documents: string;
  cols: any[];
  startPartNum: number = 0;
  rangePartNum: number = 9999999;
  totalRecords: number = 9999999 - 6000000;
  loading: boolean;
  InitialRecognition: string
  Clearance: string;
  rgList = [];
  rabartGruppensReset = []
  i: number;
  items: MenuItem[];
  itemsTree: TreeviewItem[];
  values: number[];
  valuesReplica: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 240
  });
  artical: any;
  currentArticle: any;
  display: boolean = false;
  display2: boolean = false;

  articleTeilenummer: string;

  partNumId;
  searchPartNum: PartNumbers[] = [];
  currentSelPartNum: any;
  currentSelBenennung: any;
  partNumAssignmentFlag: boolean = true;


  table: any = "dt";
  selectedRow: any;
  searchFree: String = '';
  searchPnPlaceholder: String = "Geben Sie eine Teilenummer ein"
  noRecFound: boolean = false;

  statusvalues: Status[];
  selectedStatus;

  warengrupevalues: warengrupe[];
  selectedwarengrupe;

  teilefvalues: teilef[];
  selecteteilef;

  sgrupevalues: sgrupe[];
  selectesgrupe: any;

  status: boolean = false;
  status1: boolean = false;
  statuspopup: boolean = false;

  articlesList: any = [];

  articleCheck: boolean = true;
  specification: boolean = false;
  archiv: boolean = false;
  freigabe: boolean = false;
  aufbrauch: boolean = false;
  benningEtas: boolean = false;
  dropdowndata = [];
  dataForBenennungEtas: any = [];
  filterDataForBenennungEtas: any;

  blp: any = "0,00";
  auf: any = "0,00";
  selectedRg: any;
  articlRg: any = "0,00";
  filearr: any[];
  deltaPrice: any = null;

  partNumBlockedFlag: boolean = true;
  partNumUnblockedFlag: boolean = true;
  partNumReservedFlag: boolean = true;
  partNumUnreservedFlag: boolean = true;
  partNumFromMenu: boolean = false;

  zuordAssociationChanged: boolean = false;
  zuordNoAssociationChkBx: boolean = false;
  disableZuordDelCheckbox: boolean = true;
  itemTreeValueStored: TreeviewItem[] = [];
  restoreZuord: TreeviewItem[] = [];
  Zurdng: boolean;
  zuordOkFlag: boolean;
  zuordBaureiheFrArtikel: string[] = [];
  zuordngArtikelBaureihe: string = "";

  checkAuf: boolean = true;
  car: any[];

  bengTableSelct: boolean = false;
  documentdeleteui: boolean = false;
  enterRpFl: boolean = false;

  lifrentNam: string;
  teilenumberexport: boolean = false;
  startteilenumber: string = '6000000';
  endteilenumber: string = '6000999';
  teilenumberArray: any[] = [];
  startteile: any = 'B6 600 0000';
  endteile: any = 'B6 600 0999';
  Vergebe: any = 'vergebestatus';
  frei: any = 'freiestatus';
  block: any = 'blockstatus';
  reserve: any = 'reservestatus';
  articlestatus;
  teilenumberarticlelist: any[];
  teileexportformat: any = 'teilecsv';
  filename: string;

  lieferantBean: any = null;
  selectHandelsware = [];
  selectedStaus = [];

  currentTfName: String;
  filenamedialog: boolean = false;
  statusteile: any[] = ['Vergebe', 'frei', 'block', 'reserve'];
  errmsg: boolean = false;
  archive1: boolean = false;

  historiedetail: HistoryDto;
  genstatusvar;
  presentLanguage: String;
  rangeerr: boolean = false;
  validlast4: boolean = false;

  teleNumRequested: boolean = false;
  prestoBackendConform: boolean = false;

  brands: string[] = ['Audi', 'BMW', 'Mercedes', 'Renault', 'Volvo', 'VW'];
  brandobj = {
    'heading': ['Document'],
    'Idee': { key: 1, value: [] },
    'Lastenheft': { key: 2, value: [] },
    'Angebot': { key: 3, value: [] },
    'ForgibeDesign': { key: 4, value: [] },
    'ForgibeKonstruktion': { key: 5, value: [] },
    'ForgibeBaureihe': { key: 6, value: [] },
    'ForgibeVersuch': { key: 7, value: [] },
    'ForgibeQM': { key: 8, value: [] },
    'MontageEinbau': { key: 9, value: [] },
    'Bedienungsanleitung': { key: 10, value: [] },
    'Sonstiges': { key: 11, value: [] },
  }

  displayFile: boolean = false;

  displayAllFile: boolean = false;
  filenameerr: boolean = false;
  allFileTr: String = "";
  allFilePi: string = "";

  filteredBrands: any[];

  brand: string[] = ['Document'];

  formInputChanged: boolean = false;


  articlaDiffer: any;
  letChecked: number = 0;
  articlaChange: boolean = true;
  handelswareDiffer: any;
  selectedStausDiffer: any;

  changeStatusTF: any;
  changeStatusZr: any;
  teleNChanged: any;

  fileProductId: String;
  fileTelnummer: String;

  fileType: String = "file";
  fileName: String;
  attachedFile: any;
  attachedStatusId: Number;

  editeFiletype: String = "download";
  selectedFile: any;

  replaceFile: String;

  documentCopyAllVld: string = "";
  documentCopyVld: string = "";
  curentPageNumber: number = 1;
  private base64textString: String = "";
  preisrg: boolean = false;


  validErrrAdF: boolean = false;
  validErrrcpF: boolean = false;

  checkFilesize: boolean = false;

  divHeight: String = "auto";

  sortimentsShow: boolean = false;
  sortimentsklasseSelect = [];


  freigabeValidErr: String = "";
  freigabePopup: boolean = false;
  freigabeZordPopup: boolean = false;
  showConform: boolean = false;
  exauf: any = "0,00";
  changecolor: any;

  emittedArticleId: any;

  priceList: any[];
  selectedPrices: any;
  selectedPricesAllData: any[];

  gultigVonCaluclation: boolean = false;
  priseDateGv: Date = new Date();

  email: boolean = false;

  assignmentBuyer: boolean = false;
  assignmentBuyerSelect: any = { name: null, code: null };
  assignmentBuyerList: any[];
  buyerEkConform: boolean = false;
  buyerErrorMessage: boolean = false;

  emailDto: MarginEmDto;
  reason: string = null;
  emailErrMsg: boolean = false;

  emailEkChange: boolean = false;
  tabdisable: boolean = false;
  ekYear: any = null;
  initialyear: string;

  prestroCaluclation: boolean = false;

  prestroWarning: boolean = false;
  prestroConfirmation: boolean = false;

  pristoArticle: any;
  pristoPrice: any;

  tn;
  teilebenennungvar;
  teileinfo1var;
  teilelieferantvar;
  statusvar;
  startteileerr: boolean = false;
  endteileerr: boolean = false;

  gultinvonChange: boolean = false;

  disableTab: boolean = false;

  setAufschlagColor: number = 0;
  user: User;
  currentRole: String;
  tNumRespPerson: String;
  first: number = 0;

  addFile($event) {
    this.checkFilesize = false;
    if ($event.target.files[0].size > 1048576*20) {
      this.checkFilesize = true;
    }
    this.fileName = $event.target.files[0].name;
    this.attachedFile = $event.target.files[0];
    $event.target.value = null;
  }

  showFilePopup(key) {
    this.checkFilesize = false;
    this.fileTelnummer = "";
    this.fileProductId = ""
    this.fileName = "";
    this.documentCopyVld = "";
    this.fileType = 'file';
    this.attachedFile = null;
    this.displayFile = true;
    this.validErrrAdF = false;
    this.attachedStatusId = key;

  }

  copyAllPop() {
    this.displayAllFile = true;
    this.validErrrcpF = false;
    this.allFilePi = "";
    this.allFileTr = "";
    this.documentCopyAllVld = "";
  }

  changefileProductId() {

    this.fileTelnummer = ""

  }
  changefileTelnummer() {

    this.fileProductId = ""

  }

  delete(brand, index) {
    brand.splice(index, 1);
    this.documentdeleteui = false;
  }

  documentdialog() {
    this.documentdeleteui = !this.documentdeleteui;
  }


  private el: HTMLInputElement;
  HTTPActivity: boolean;
  constructor(private spinnerService: Ng4LoadingSpinnerService, private datepipe: DatePipe, private documentService: DocumentService, private differs: KeyValueDiffers, private rabattgrupenService: RabattgrupenService, private messageService: MessageService, public translate: TranslateService, public articleService: ArticleService, private dashboardService: DashboardTableService,
    private tabViewService: TabViewService, private partNumber: PartNumber, private excelService: ExcelService,
    private menuService: MenuService, private elementRef: ElementRef,
    private currencyPipe: PartNumber,
    private dashboardtableservice: DashboardTableService, private userService: UserService, private roleService: RoleService) {
    this.menuService.ChangePriceLanguage.subscribe(item => {
      this.priceLanguageChange(item);
    });

    this.menuService.partNum.subscribe(item => { this.showPartNum(); });
    this.articalIntilization();
    this.el = this.elementRef.nativeElement;
    this.getRgValves();
    this.statusvalues = [];
    this.warengrupevalues = [];
    this.teilefvalues = [];
    this.sgrupevalues = [];

    this.tabViewService.teilenumberexport.subscribe(value => {
      this.el.value = this.currencyPipe.transform(this.el.value);
      this.el.value.replace(/\s/g, "");
      this.showDialog();
    });

    this.articleService.dropdown().subscribe(value => {
      if (value) {
        this.dropdowndata = value.data;
        this.statusvalues = this.filterDataByType(1, this.dropdowndata);
        this.selectedStatus = this.statusvalues[0];
        this.filterDataByTypeSparet();
        this.filterDataByTypeWg();
        this.filterDataByTypeSg();
      }
    });

    this.tabViewService.emiteRabateGrop.subscribe(item => {
      this.getRgValves();
    });


    this.tabViewService.articalObservable.subscribe(value => {
      document.getElementById("changecolor").style.backgroundColor = "#ffffff";
      document.getElementById("changecolor").style.border = "1px solid #cccccc"
      this.changeVersionCount++;
      this.disableTab = false;
      this.deltaPrice = null;
      this.reason = null;
      this.ekYear = null;
      if (value == "search") {
        this.articalIntilization();
      } else {
        this.gultigVonCaluclation = false;
        this.prestroCaluclation = false;
        this.prestroWarning = false;
        this.prestroConfirmation = false;
        this.emittedArticleId = value;
        localStorage.setItem('article_id', this.emittedArticleId);
        this.spinnerService.show();
        console.log(value);
        this.articleService.getArticleById(value).subscribe(article => {
          this.spinnerService.hide();
          var value = article.json();
          console.log(value);
          this.letChecked = 0;
          this.tabdisbloow = 0;
          this.selectHandelsware = [];
          this.selectedStaus = []
          this.articlaChange = true;
          this.changeStatusTF = false;
          this.changeStatusZr = false;
          this.teleNChanged = false;
          this.teleNumRequested = false;
          this.artical = Object.assign({}, value);
          this.currentArticle = Object.assign({}, this.artical);
          if (this.artical.teilefamilie != null) {
            this.currentTfName = this.artical.teilefamilie.name;
          }

          if (this.artical.teilenummer === "ATn") {
            this.articleTeilenummer = "-angefordert-";
          }
          else {
            this.articleTeilenummer = this.partNumber.transform(this.artical.teilenummer)
          }

          this.artical.teilefamilie = this.artical.teilefamilie || { id: { tfId: null, wgId: null, sparteId: null }, name: null };
          this.artical.lieferantBean = this.artical.lieferantBean || { name: this.lifrentNam };

          this.artical.lieferantBean.emeaid = this.artical.lieferantBean.emeaid || "NA";


          this.articleCheck = false;
          if (this.artical.sortklasse) {
            this.sortimentsklasseSelect = this.convertStringToArray(this.artical.sortklasse);
          }
          if (this.artical.liefertermin != null) {
            this.artical.liefertermin = new Date(this.artical.liefertermin);
          }
          if (this.artical.vertragsbindung != null) {
            this.artical.vertragsbindung = new Date(this.artical.vertragsbindung);
          }
          if (value.status == undefined) {
            this.articleCheck = true;
          }
          if (this.artical.handelsware == "X") {
            this.selectHandelsware.push("handelsware")
          }
          if (this.artical.ateilidentisch == "X") {
            this.selectHandelsware.push("ateilidentisch")
          }
          //Statu check box 
          if (this.artical.fltempusmis == "X") {
            this.selectedStaus.push("fltempusmis")
          }
          if (this.artical.inpreisliste == "X") {
            this.selectedStaus.push("inpreisliste")
          }
          if (this.artical.flmbks == "X") {
            this.selectedStaus.push("flmbks")
          }
          if (this.artical.prestoStatus == "X") {
            this.selectedStaus.push("prestoStatus")
          }
          // Status
          if (this.artical.status == "S") {
            this.artical.status = "Spezifikation"

          }
          if (this.artical.status == "F") {
            this.artical.status = "Freigabe/Aktiv"
          }
          if (this.artical.status == "A") {
            this.artical.status = "Auf Aufbrauch"
          }
          if (this.artical.status == "B") {
            this.artical.status = "Blocked"
          }
          if (this.artical.status == "R") {
            this.artical.status = "Reserved"
          }
          if (this.artical.status == "I") {
            this.artical.status = "Idee"
          }
          if (this.artical.status == "Z") {
            this.artical.status = "Archiv Idee"
          }
          if (this.artical.status == "X") {
            this.artical.status = "Archiv"
          }

          this.auf = "35,00";
          var rgValu = this.artical.rg
          this.selectedRg = this.rgList.find(x => x.id === rgValu);

          //EK
          this.intialCaluclation(this.artical.vk);
          //Get article price by some conditions
          if (this.artical.status != "Idee" && this.artical.status != "Spezifikation") {
            this.getPrise();
          }
          //set sortimentsgruppe vlaue
          if (this.artical.sortimentsgruppe != null) {
            this.selectesgrupe = this.sgrupevalues.find(x => x['sgid'] === this.artical.sortimentsgruppe);
          } if (this.selectesgrupe == undefined || this.selectesgrupe == null || this.artical.sortimentsgruppe == null) {
            this.selectesgrupe = this.sgrupevalues[0];
          }


          //Binding the value of the article in selected Spart
          if (this.artical.teilefamilie.id) {

            if (this.artical.teilefamilie.id.sparteId) {
              this.selectedStatus = this.statusvalues.find(item => item['sid'] === this.artical.teilefamilie.id.sparteId);
            }
            if (this.artical.teilefamilie.id.wgId) {
              this.selectedwarengrupe = this.warengrupevalues.find(item => item['wid'] === this.artical.teilefamilie.id.wgId);
            }
            if (this.artical.teilefamilie.id.tfId) {
              this.selecteteilef = this.teilefvalues.find(item => item['tid'] === this.artical.teilefamilie.id.tfId);

            }
          }

          this.getZuordList();
          this.gethistorydata(this.emittedArticleId);
          //Geting the document by id
          localStorage.setItem('articlestatus', this.artical.status);
          if (this.emittedArticleId) {
            this.getDocumentById(this.emittedArticleId);
          }
        });
      }
    });
    this.spinnerService.show();
    this.tabViewService.assignSupplier.subscribe(value => {
      this.spinnerService.hide();
      this.artical.lieferantBean = value;
      this.lieferantBean = value;
      this.articlaChange = false;
    });

    translate.onLangChange.subscribe(lang => {
      this.presentLanguage = lang.lang;
    })

  }
  
  gethistorydata(id) {
    this.spinnerService.show();
    this.articleService.historyData(id).subscribe((res) => {
      this.spinnerService.hide();
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      let finalobj = JSON.parse(resJSON._body);
      this.historiedetail.abgangKey1 = finalobj.result.abgangKey1;
      this.historiedetail.abgangKey2 = finalobj.result.abgangKey2;
      this.historiedetail.abgangKey3 = finalobj.result.abgangKey3;
      if (finalobj.result.db1 == 0) {
        finalobj.result.db1 = "0,00";
      } 
     //for showing Db value in number formate according to language  
      else {
        finalobj.result.db1 =  (finalobj.result.db1 / 100).toFixed(2);
        if(this.presentLanguage == "de"){
          finalobj.result.db1=finalobj.result.db1.replace('.', ',');
        }else{
          finalobj.result.db1=finalobj.result.db1.replace(',', '.');
        }
          finalobj.result.db1 = formateCurrency(parseValue(finalobj.result.db1, this.presentLanguage), this.presentLanguage);   
       
      }
      //for Db 2
      if (finalobj.result.db2 == 0) {
        finalobj.result.db2 = "0,00";
      }
      // for showing Db value in number formate according to language  
      else {
        finalobj.result.db2 =  (finalobj.result.db2 / 100).toFixed(2);
        if(this.presentLanguage == "de"){
          finalobj.result.db2=finalobj.result.db2.replace('.', ',');
        }else{
          finalobj.result.db2=finalobj.result.db2.replace(',', '.');
        }
         finalobj.result.db2 = formateCurrency(parseValue(finalobj.result.db2, this.presentLanguage), this.presentLanguage);
        
      }

      //for Db 3
      if (finalobj.result.db3 == 0) {
        finalobj.result.db3 = "0,00";
      }
       //for showing Db value in number formate according to language  
      else {
      finalobj.result.db3 =  (finalobj.result.db3 / 100).toFixed(2);
      if(this.presentLanguage == "de"){
        finalobj.result.db3=finalobj.result.db3.replace('.', ',');
      }else{
        finalobj.result.db3=finalobj.result.db3.replace(',', '.');
      }
        finalobj.result.db3 = formateCurrency(parseValue(finalobj.result.db3, this.presentLanguage), this.presentLanguage);
      }

      if (finalobj.result.abgangValue1 == 0) {
        finalobj.result.abgangValue1 = "";
      }
      if (finalobj.result.abgangValue2 == 0) {
        finalobj.result.abgangValue2 = "";
      }
      if (finalobj.result.abgangValue3 == 0) {
        finalobj.result.abgangValue3 = "";
      }
      if (finalobj.result.reichweite == 0.0) {
        finalobj.result.reichweite = "";
      }
      if (finalobj.result.bestand == 0) {
        finalobj.result.bestand = 0;
        finalobj.result.bestandDatum = "";
      }
      else {
        finalobj.result.bestandDatum = new Date(finalobj.result.bestandDatum);
        finalobj.result.bestandDatum = moment(finalobj.result.bestandDatum).format('DD.MM.YYYY');
      }
      if (finalobj.result.finishYearAbgang == 0) {
        finalobj.result.finishYearAbgang = "";
        finalobj.result.abgangDate = "";
      }
      else {
        finalobj.result.abgangDate = new Date(finalobj.result.abgangDate);
        finalobj.result.abgangDate = moment(finalobj.result.abgangDate).format('DD.MM.YYYY');
      }
      if (finalobj.result.reichweite <= 0.0) {
        document.getElementById("changecolor").style.backgroundColor = "#ececec";
        document.getElementById("changecolor").style.borderColor = "#ececec";
      }

      else if (finalobj.result.reichweite < 1.0) {
        finalobj.result.reichweite = finalobj.result.reichweite.toFixed(2);
        document.getElementById("changecolor").style.backgroundColor = "rgb(203, 255, 204)";
      }

      else if (finalobj.result.reichweite < 3.0) {
        finalobj.result.reichweite = finalobj.result.reichweite.toFixed(2);
        document.getElementById("changecolor").style.backgroundColor = "rgb(255, 255, 103)";
      }

      else {
        finalobj.result.reichweite = finalobj.result.reichweite.toFixed(2);
        document.getElementById("changecolor").style.backgroundColor = "rgb(255, 51, 52)";
        document.getElementById("changecolor").style.color = "white";
      }
      this.historiedetail = finalobj.result;
    });

  }

  arrowEnter() {
    $("#arrowGray").hide();
    $("#arrowWhite").show();
  }

  arrowLeave() {
    $("#arrowGray").show();
    $("#arrowWhite").hide();
  }

  bennungEnter() {
    $("#bennungGray").hide();
    $("#bennungWhite").show();
  }

  bennungLeave() {
    $("#bennungGray").show();
    $("#bennungWhite").hide();
  }

  teile1Enter() {
    $("#teile1Gray").hide();
    $("#teile1White").show();
  }

  teile1Leave() {
    $("#teile1Gray").show();
    $("#teile1White").hide();
  }

  searchzuroEnter() {
    $("#searchzuroGray").hide();
    $("#searchzuroWhite").show();
  }

  searchzuroLeave() {
    $("#searchzuroGray").show();
    $("#searchzuroWhite").hide();
  }

  searchtfEnter() {
    $("#searchtfGray").hide();
    $("#searchtfWhite").show();
  }

  searchtfLeave() {
    $("#searchtfGray").show();
    $("#searchtfWhite").hide();
  }

  gult1Enter() {
    $("#gult1Gray").hide();
    $("#gult1White").show();
  }

  gult1Leave() {
    $("#gult1Gray").show();
    $("#gult1White").hide();
  }

  searchdispEnter() {
    $("#searchdispGray").hide();
    $("#searchdispWhite").show();
  }

  searchdispLeave() {
    $("#searchdispGray").show();
    $("#searchdispWhite").hide();
  }

  // teilenumberpopup
  showDialog() {
    this.teilenumberexportpopclose();
    this.teilenumberexport = true;
    this.getRgValves();
  }
  teiledata(data) {
    var startteile1 = this.startteile.replace(/ /g, "");
    var endteile1 = this.endteile.replace(/ /g, "");
    var st4 = startteile1.substring(5, 9);
    var et4 = endteile1.substring(5, 9);
    var st3 = startteile1.substring(2, 5);
    var et3 = endteile1.substring(2, 5);
    if (!startteile1.toUpperCase().startsWith("B6") || startteile1.length > 9 || startteile1.length < 9 || !this.ChkNumFnc(startteile1)) {
      this.startteileerr = true;
    }
    else if (!endteile1.toUpperCase().startsWith("B6") || endteile1.length > 9 || endteile1.length < 9 || !this.ChkNumFnc(endteile1)) {
      this.endteileerr = true;
    }
    else if (!this.filename) {
      this.filenameerr = true;
    }
    else if (st4 > et4 || et4 < st4) {
      this.rangeerr = true;
    }
    else if (!st3.startsWith("600") || !et3.startsWith("600")) {
      this.validlast4 = true;
    }

    else {
      let st = +(this.startteile.replace(/ /g, "").substring(2, 9));
      let et = +(this.endteile.replace(/ /g, "").substring(2, 9));
      var teilenumberarticlelist = [];
      this.teilenumberArray = [];
      this.genstatusvar = "frei";
      this.teileinfo1var = "";
      this.teilebenennungvar = "";
      this.teilelieferantvar = "";
      this.tabViewService.teilenumbers(startteile1, endteile1).subscribe(res => {
        const savedData = {};
        console.log(res.result)
        res.result.forEach((elem, i) => {
          savedData[elem.teilenummer] = elem;
        });

        for (var i = st; i <= et; i++) {
          var tlN = 'B6' + i;
          var o = savedData[tlN];
          if (o) {
            if (o.BENENNUNG_ETAS == null) {
              o.BENENNUNG_ETAS = "";
            }
            else {
              o.BENENNUNG_ETAS = o.BENENNUNG_ETAS;
            }
            if (o.teileinfo1 == null) {
              o.teileinfo1 = "";
            }
            else {
              o.teileinfo1 = o.teileinfo1;
            }
            if (o.LIFERANT == null) {
              o.LIFERANT = "";
            }
            else {
              o.LIFERANT = o.LIFERANT;
            }
            if (o.status == "R") {
              o.status = "reserved";
            }
            else if (o.status == "B") {
              o.status = "blocked"
            }
            else if (o.status == "F" || o.status == "A" || o.status == "I" || o.status == "S" || o.status == "Z" || o.status == "X") {
              o.status = "vergeben";
            }
            if ((!this.Vergebe && !this.frei && !this.block && !this.reserve) ||
              (this.Vergebe && this.frei && this.block && this.reserve) ||
              (this.Vergebe && o.status == "vergeben") ||
              (this.frei && o.status == "frei") ||
              (this.block && o.status == "blocked") ||
              (this.reserve && o.status == "reserved"))
              this.teilenumberArray.push({
                "teilenumber": tlN, "benennung_etas": o.BENENNUNG_ETAS, "teileinfo1": o.teileinfo1,
                "lieferant": o.LIFERANT, "status": o.status,
              });
          }
          else {
            if ((!this.Vergebe && !this.frei && !this.block && !this.reserve) ||
              (this.Vergebe && this.frei && this.block && this.reserve) ||
              (this.Vergebe && this.genstatusvar == "vergeben") ||
              (this.frei && this.genstatusvar == "frei") ||
              (this.block && this.genstatusvar == "blocked") ||
              (this.reserve && this.genstatusvar == "reserved"))
              this.teilenumberArray.push({
                "teilenumber": tlN, "benennung_etas": this.teilebenennungvar, "teileinfo1": this.teileinfo1var,
                "lieferant": this.teilelieferantvar, "status": this.genstatusvar,
              });
          }
        }


        if (data === 'teileexcel') {
          this.excelService.exportAsExcelFile(this.teilenumberArray, this.filename);
          this.messageService.add({ detail: this.translate.instant('teilenum_excel'), key: 'c' });
          this.teilenumberexport = false;
        }

        else {
          var head = ['teilenumber', 'benennung_etas', 'teileinfo1', 'lieferant', 'status'];
          new Angular5Csv(this.teilenumberArray, this.filename, { headers: (head) });
          this.messageService.add({ detail: this.translate.instant('teilenum_csv'), key: 'c' });
          this.teilenumberexport = false;
        }
      });
    }
  }

  ChkNumFnc(NumPsgVar) {
    let NumVar = NumPsgVar.substring(2, 9);
    return !isNaN(parseFloat(NumVar)) && isFinite(NumVar);
  }

  inputchange() {
    this.filenameerr = false;

  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  validationForGeWicht() {

    if (this.artical.gewicht.startsWith('0')) {
      if (this.artical.gewicht == 0) {
        this.artical.gewicht = this.artical.gewicht;
      }
      else {
        this.artical.gewicht = Number(this.artical.gewicht).toString();
      }
    }

    if (this.artical.gewicht.indexOf(".") != -1) {
      this.artical.gewicht = this.artical.gewicht.replace(".", '');
    }
    if (this.artical.gewicht >= 0 && this.artical.gewicht <= 999) {

      this.artical.gewicht = this.artical.gewicht;

    } else if (this.artical.gewicht >= 1000 && this.artical.gewicht <= 9999) {
      // after first digit apply decimal point --> 1.234
      this.artical.gewicht = (this.artical.gewicht / 1000).toFixed(3);
    } else if (this.artical.gewicht >= 10000 && this.artical.gewicht <= 99999) {

      // after second digit apply decimal point --> 12.345	
      this.artical.gewicht = (this.artical.gewicht / 1000).toFixed(3);;
    } else if (this.artical.gewicht >= 100000 && this.artical.gewicht <= 999999) {

      // after third digit apply decimal point --> 123.456
      this.artical.gewicht = (this.artical.gewicht / 1000).toFixed(3);;
    }
  }

  inputchange1() {
    this.startteileerr = false;
    this.validlast4 = false;
    this.rangeerr = false;
  }

  inputchange2() {
    this.endteileerr = false;
    this.validlast4 = false;
    this.rangeerr = false;
  }
  teilenumberexportpopclose() {
    this.teilenumberexport = false;
    this.filename = '';
    this.Vergebe = 'vergebestatus';
    this.frei = 'freiestatus';
    this.block = 'blockstatus';
    this.reserve = 'reservestatus';
    this.teileexportformat = 'teilecsv';
    this.startteile = "B6 600 0000";
    this.endteile = "B6 600 0999";
    this.startteileerr = false;
    this.endteileerr = false;
    this.rangeerr = false;
    this.validlast4 = false;
  }
  chngtospecification() {

    if (this.artical.status == 'Idee') {
      this.specification = true;
    }
  }

  chngtoaufbrauch() {
    if (this.artical.status == 'Freigabe/Aktiv') {
      this.artical.datumaufbrauch = new Date();
      this.aufbrauch = true;
    }
  }
 
  chngstatustospecification(aid, sid) {
    this.spinnerService.show();
    this.articleService.updateArticleStatus(aid, sid).subscribe(res => {
    this.spinnerService.hide();
    if (res) {
      this.artical.status = 'Spezifikation';
    }
    this.spinnerService.show();
    this.tabViewService.emitUpdatServic(this.emittedArticleId);
    this.spinnerService.hide();
    });
    this.specification = false;
  }

  chngstatustoarchive(aid, sid) {
    this.spinnerService.show();
    this.articleService.updateArticleStatus(aid, sid).subscribe(res => {
      this.spinnerService.hide();
      if (res) {
        this.artical.status = 'Archiv';
      }
      this.spinnerService.show();
      this.dashboardService.loadTableData();
      this.spinnerService.hide();
    });
    this.archiv = false;
  }

  chngstatustoaufbrauch(aid, sid) {
    this.spinnerService.show();
    this.articleService.updateArticleStatus(aid, sid).subscribe(res => {
      this.spinnerService.hide();
      if (res) {
        this.artical.status = 'Auf Aufbrauch';
      }
      this.spinnerService.show();
      this.tabViewService.emitUpdatServic(this.emittedArticleId);
      this.spinnerService.hide();
    });
    this.aufbrauch = false;
  }

  chngstatustofreigabe(aid, sid) {
    this.spinnerService.show();
    this.articleService.updateArticleStatus(aid, sid).subscribe(res => {
      this.spinnerService.hide();
      if (res) {
        this.artical.status = 'Freigabe/Aktiv';
      }
      this.spinnerService.show();
      this.tabViewService.emitUpdatServic(this.emittedArticleId);
      this.spinnerService.hide();
    });
    this.freigabe = false;
  }

  chngstatustoarchive1(aid, sid) {
    this.spinnerService.show();
    this.articleService.updateArticleStatus(aid, sid).subscribe(res => {
      this.spinnerService.hide();
      if (res) {
        this.artical.status = 'Archiv';
      }
      this.spinnerService.show();
      this.dashboardService.loadTableData();
      this.spinnerService.hide();
    });
    this.archive1 = false;
  }

  filterDataByType(typeid, data) {
    return data.filter(e => e.type === typeid);
  }

  filterDataByTypeSparet() {
    if (this.selectedStatus) {
      this.warengrupevalues = this.dropdowndata.filter(e => e.type === 2
        && e.sid === this.selectedStatus.sid);
      this.selectedwarengrupe = this.warengrupevalues[0];
      this.filterDataByTypeWg();
    }
  }

  filterDataByTypeWg() {
    if (this.selectedwarengrupe && this.selectedStatus) {
      this.teilefvalues = this.dropdowndata.filter(e => e.type === 3
        && e.sid === this.selectedStatus.sid
        && e.wid === this.selectedwarengrupe.wid);
      this.selecteteilef = this.teilefvalues[0];
      this.filterDataByTypeSg();
    }
  }

  filterDataByTypeSg() {
    if (this.selectedwarengrupe && this.selectedStatus && this.selecteteilef)
      this.sgrupevalues = this.dropdowndata.filter(e => e.type === 4
        && e.sid === this.selectedStatus.sid
        && e.wid === this.selectedwarengrupe.wid
        && e.tid === this.selecteteilef.tid);
  }

  TeileF: boolean = false;

  fetchTeilefValue() {
    this.changeStatusTF = true;
    this.artical.teilefamilie.name = "";
    if (this.selecteteilef.name != undefined) {
      this.artical.teilefamilie.name = this.selecteteilef.name.substr(4);
    }
    this.TeileF = false;
    if (this.sgrupevalues.length > 0) {
      this.selectesgrupe = this.sgrupevalues[0];
    }
    if (this.selectesgrupe != undefined) {
      this.artical.sortimentsgruppe = this.selectesgrupe.sgid;
      this.artical.macode = this.selectesgrupe.mcode;
    }
    this.artical.teilefamilie.id = { 'sparteId': null, 'wgId': null, 'tfId': null }
    if (this.sgrupevalues[0]['sid'] !== undefined) {
      this.artical.teilefamilie.id.sparteId = this.sgrupevalues[0]['sid'];
    }
    if (this.selecteteilef.wid) {
      this.artical.teilefamilie.id.wgId = this.selecteteilef.wid;
    }
    if (this.selecteteilef.tid) {
      this.artical.teilefamilie.id.tfId = this.selecteteilef.tid;
    }

  }


  setTeilefValue() {
    this.TeileF = true;

    if (this.artical.teilefamilie.name != null) {
    }
  }

  showBenennungETASPopupp() {

    let filteredData = [];
    if (this.artical.namede != null && this.artical.namede != "") {
      this.benningEtas = true;
      this.bengTableSelct = false;
      if (this.dataForBenennungEtas) {
        var groupByName = {};
        var e1 = [];
        this.dataForBenennungEtas.forEach(e => {
          if (e.status === 'F' || e.status === 'A' || e.status === 'X') {
            e.namede = (e.namede === undefined || e.namede === '' || e.namede === null) ? '' : e.namede;
            var co = e.namede.toUpperCase().startsWith(this.artical.namede.toUpperCase())
            var co1 = groupByName[(e.namede || '')];
            groupByName[e.namede || ''] = true;
            if (co1 === undefined && co) {
              e1.push(e);
            }
          }
        });
        this.filterDataForBenennungEtas = e1;
      }
    }
  }

  showBenennungETASValue() {

    this.artical.namede = this.currentSelBenennung.namede;
    this.artical.nameen = this.currentSelBenennung.nameen;
    this.artical.namees = this.currentSelBenennung.namees;
    this.artical.namefr = this.currentSelBenennung.namefr;
    this.benningEtas = false;
  }

  articlemap = {};

  ngOnChanges(changes: SimpleChanges) {
    this.getRgValves();

    // if (this.articles)
    //   this.articles.forEach(e => {
    //     this.articlemap[e.teilenummer] = e;
    //   });
    // if (this.selectedRowTnum && this.selectedRowTnum.teilenummer !== null) {
    //   let partNumberSel = new PartNumbers();
    //   this.searchFree = this.selectedRowTnum.teilenummer;
    //   this.changePartNum();
    //   partNumberSel = this.partNumListWithStatus.find(x => x.teilienummer === this.selectedRowTnum.teilenummer);
    //   this.selectedRow = partNumberSel;
    // } else {
    //   this.loading = true;
    //   this.changePartNum();
    //   this.loading = false;
    // }
    this.dataForBenennungEtas = this.articles;
    this.getZuordList();
  }

  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.partNumListWithStatus = this.generatePartNumber(false, event.first);
    this.loading = false;
  }

  ngOnInit() {
    this.priceList = [];
    this.curentPageNumber = 1;
    this.articlaDiffer = this.differs.find(this.artical).create();
    this.handelswareDiffer = this.differs.find(this.selectHandelsware).create();
    this.selectedStausDiffer = this.differs.find(this.selectedStaus).create();
    this.getRgValves()
    this.items = [
      { label: 'Step 1' },
      { label: 'Step 2' },
      { label: 'Step 3' }
    ];
    this.translate.onLangChange.subscribe(
      (translate: LangChangeEvent) => (
        this.cols = [
          { field: 'teilienummer', header: translate.translations['PART_NUMBERS'] },
          { field: 'benennung', header: translate.translations['NAME_ETAS'] },
          { field: 'teileinfo1', header: translate.translations['PART_INFO_1'] },
          { field: 'lieferant', header: translate.translations['SUPPLIER'] },
          { field: 'status', header: 'Status' }
        ]));

    this.translate.onLangChange.subscribe(
      (translate: LangChangeEvent) => (
        this.ProductManagement = translate.translations['PRODUCT_MANAGEMENT'],
        this.Documents = translate.translations['DOCUMENTS'],
        this.InitialRecognition = translate.translations['INITIAL_RECOGNITION'],
        this.Clearance = translate.translations['CLEARANCE'],
        this.lifrentNam = translate.translations['kn_lf']
      ));

    this.translate.onLangChange.subscribe(
      (translate: LangChangeEvent) => (
        this.presentLanguage = translate.lang
      ));
    this.getZuordList();
    this.historieInitialization();
    this.historiedetail.abgangKey1 = new Date().getFullYear() - 1;
    this.historiedetail.abgangKey2 = this.historiedetail.abgangKey1 - 1;
    this.historiedetail.abgangKey3 = this.historiedetail.abgangKey2 - 1;
    this.userService.loggedUser.subscribe(value => {
      this.user = value;
      this.currentRole = this.user.USER_ROLE;
      this.tNumRespPerson = this.user.RESP_PERSON;
      this.roleService.setLoggedInUserID = this.user.USER_ID;
      this.roleService.setUserRole = this.user.USER_ROLE;
    });
  }
  articalIntilization() {
    this.artical = {
      artikeltyp: null,
      ateilidentisch: null,
      aw: null,
      bestand: null,
      bestanddatum: null,
      bypassDatum: null,
      bypassMenge: null,
      datumarchiv: null,
      datumaufbrauch: null,
      datumfreigabe: null,
      datumtempusmis: null,
      datumtempuspreis: null,
      druckchangeordner: null,
      druckdatum: null,
      druckfrko: null,
      druckfrpm: null,
      druckimordner: null,
      drucknachtrag: null,
      ek: null,
      erstdisposition: null,
      flabverkauf: null,
      flkopie: null,
      flmbks: null,
      flprestoanpassung: null,
      fltempusanpassung: null,
      fltempusmis: null,
      fltempuspreis: null,
      flzuordnung: null,
      frbaureihe: null,
      frdesign: null,
      frkonstruktion: null,
      frqm: null,
      frversuch: null,
      gewicht: null,
      handelsware: null,
      id: null,
      inpreisliste: null,
      katalogbildId: null,
      liefertermin: null,
      macode: null,
      nachricht: null,
      namede: null,
      nameen: null,
      namees: null,
      namefr: null,
      notiz: null,
      prestoStatus: null,
      produktidee: null,
      rg: null,
      sortimentsgruppe: null,
      sortklasse: null,
      sparte: null,
      status: null,
      technikinfo: null,
      teilefamilie: {
        id: {
          tfId: null,
          wgId: null,
          sparteId: null
        },
        name: null
      },
      teileinfo1: null,
      teileinfo2: null,
      teilenummer: null,
      vertragsbindung: null,
      vk: null,
      vorgaenger: null,
      wa: null,
      warengruppe: null,
      webbildId: null,
      wisnr: null,
      lieferantBean: {
        id: {
          lieferantId: null
        },
        land: null,
        name: null,
        ort: null,
        plz: null,
        strasse1: null,
        strasse2: null
      },
      filename: ""
    }
    this.auf = null;
    this.setAufschlagColor = 0;
    this.articleTeilenummer = null;
    this.zuordngArtikelBaureihe = null;
    this.selectedStaus = [];

    this.blp = "0,0";
    this.exauf = null;
    this.selectedRg = null;
    this.articlRg = null;
    this.articleCheck = true;
    this.historieInitialization();
  }

  historieInitialization() {
    this.historiedetail = {
      articleId: null,
      db1: null,
      db2: null,
      db3: null,
      abgangKey1: null,
      abgangKey2: null,
      abgangKey3: null,
      abgangValue1: null,
      abgangValue2: null,
      abgangValue3: null,
      finishYearAbgang: null,
      abgangDate: null,
      reichweite: null,
      bestand: null,
      bestandDatum: null,
      jahr: null,
      artikelStatus: null,
      wa: null,
      ek: null,
      priecDatum: null
    }
  }

  generatePartNumber(changeTotal = false, sum = 0): PartNumbers[] {
    const first = this.startPartNum + sum, last = this.rangePartNum;
    const partNumbersList: PartNumbers[] = [];
    const partNumRefinedList = [];
    if (changeTotal) {
      this.totalRecords = last - first + 1;
    }

    if (this.totalRecords > 0) {
      const loopLength = this.totalRecords > 10 ? 10 : this.totalRecords;
      for (var j = 0; j < loopLength; j++) {
        const partNum = new PartNumbers();
        partNum.teilienummer = "B";
        partNum.teilienummer += (first + j);
        partNum.status = "frei";
        partNumbersList.push(partNum);
      }

      var partNumsForQuery = partNumbersList.map((value) => value.teilienummer).join(",");
      this.articleService.getPartNumbers(partNumsForQuery).subscribe(data => {
        this.articlemap = {};
        data.result.forEach(e => {
          this.articlemap[e.teilenummer] = e;
        });
        partNumbersList.forEach(partNum => {

          var article = this.articlemap[partNum.teilienummer] || {};
          partNum.benennung = article.namede;

          partNum.lieferant = article.name;


          partNum.teileinfo1 = article.teileinfo1;

          partNum.assignedToArticleId = article.artikel_id;
          if (article.status) {
            if (article.status === "B") {
              partNum.status = "blockiert"
            } else if (article.status === "R") {
              partNum.status = "reserviert"
            } else {
              partNum.status = "vergeben";
            }
          } else {
            partNum.status = "frei";
          }
          partNumRefinedList.push(partNum);
        })

      });
    }
    return partNumRefinedList;

  }


  onRowSelect(event) {
    this.currentSelPartNum = event.data;
    if (this.currentSelPartNum.status === "frei") {
      this.partNumAssignmentFlag = false;
      if (this.partNumFromMenu) {
        this.partNumAssignmentFlag = true;
      } else {
        this.partNumAssignmentFlag = false;
      }
      this.partNumBlockedFlag = false;
      this.partNumReservedFlag = false;

      this.partNumUnblockedFlag = true;
      this.partNumUnreservedFlag = true;
    }
    if (this.currentSelPartNum.status === "blockiert") {
      this.partNumUnblockedFlag = false;

      this.partNumAssignmentFlag = true;
      this.partNumBlockedFlag = true;
      this.partNumReservedFlag = true;
      this.partNumUnreservedFlag = true;
    }
    if (this.currentSelPartNum.status === "reserviert") {
      this.partNumUnreservedFlag = false;

      this.partNumAssignmentFlag = true;
      this.partNumBlockedFlag = true;
      this.partNumReservedFlag = true;
      this.partNumUnblockedFlag = true;
    }
    if (this.currentSelPartNum.status === "vergeben") {
      this.partNumUnreservedFlag = true;

      this.partNumAssignmentFlag = true;
      this.partNumBlockedFlag = true;
      this.partNumReservedFlag = true;
      this.partNumUnblockedFlag = true;
    }
    this.searchFree = event.data.teilienummer;
  }

  onRowUnselect(event) {

  }


  onRowSelect2(event) {
    this.bengTableSelct = true;
    this.currentSelBenennung = event.data;
  }

  onRowUnselect2(event) {
  }


  changePartNum() {
    this.searchFree = this.searchFree.replace(/\s/g, "");
    if (this.searchFree.length > 9) {
      this.noRecFound = true;
      return;
    } else {
      this.noRecFound = false;
    }
    if (this.searchFree.length <= 3) {
      this.startPartNum = 66000000;
      this.rangePartNum = 69999999;
      this.partNumListWithStatus = this.generatePartNumber(true);
      return;
    }

    if (!this.searchFree.toUpperCase().startsWith("B6") || this.searchFree.length > 9) {
      this.startPartNum = 0;
      this.rangePartNum = 0;
      this.partNumListWithStatus = this.generatePartNumber(true);
      this.noRecFound = true;
      return;
    }

    let v = this.searchFree.substr(1) as string;
    const px = Math.pow(10, (8 - v.length));

    const start = +v * px;
    let end = +(v + (px - 1));
    if (this.searchFree.length == 9) {
      end = start;
    }

    if (start === 0) {
      this.startPartNum = 0;
      this.rangePartNum = 0;
    } else {
      this.startPartNum = start;
      this.rangePartNum = end;
    }
    this.partNumListWithStatus = this.generatePartNumber(true);
  }

  TeileNo: boolean = false;

  assignPartNum() {
    if (this.currentSelPartNum.status === "frei") {
      this.articleTeilenummer = this.partNumber.transform(this.currentSelPartNum.teilienummer);
      this.TeileNo = false;
      this.teleNChanged = true;
    }
  }




  public updateArticle() {
    if ((this.selectedRg == null || this.selectedRg.code == 0) && (parseFloat(this.artical.vk) > 0 || parseFloat(this.artical.wa) > 0 || parseFloat(this.artical.ek) > 0)) {
      this.preisrg = true;
    }
    else {
      this.prepareFodb();
      this.storeEVWValu();
      var updtArticle = Object.assign({}, this.artical);
      //TF fileds updating to article object
      if (updtArticle.teilefamilie.name != null) {
        if (this.selectesgrupe != undefined) {
          updtArticle.sortimentsgruppe = this.selectesgrupe.sgid;
          updtArticle.macode = this.selectesgrupe.mcode;
        }
        updtArticle.teilefamilie.id = { 'sparteId': null, 'wgId': null, 'tfId': null }
        if (this.sgrupevalues[0]['sid'] !== undefined) {
          updtArticle.teilefamilie.id.sparteId = this.sgrupevalues[0]['sid'];
        }
        if (this.selecteteilef.wid) {
          updtArticle.teilefamilie.id.wgId = this.selecteteilef.wid;
        }
        if (this.selecteteilef.tid) {
          updtArticle.teilefamilie.id.tfId = this.selecteteilef.tid;
        }
      }
      if (this.articleTeilenummer) {
        if (this.articleTeilenummer === '-angefordert-') {
          updtArticle.teilenummer = '-angefordert-';
        } else {
          updtArticle.teilenummer = this.articleTeilenummer.replace(/\s/g, "");
        }
      }
      if (this.selectedRg) {
        updtArticle.rg = this.selectedRg.id;
      }
      if (updtArticle.ek != null) {
        updtArticle.ek = parseFloat(updtArticle.ek)
      }
      if (updtArticle.vk != null) {
        updtArticle.vk = parseFloat(updtArticle.vk)
      }
      if (updtArticle.wa != null) {
        updtArticle.wa = parseFloat(updtArticle.wa)
      }
      if (updtArticle.bypassDatum != null) {
        updtArticle.bypassDatum = new Date(updtArticle.bypassDatum);
      }
      if (updtArticle.datumaufbrauch != null) {
        updtArticle.datumaufbrauch = new Date(updtArticle.datumaufbrauch);
      }
      if (updtArticle.datumfreigabe != null) {
        updtArticle.datumfreigabe = new Date(updtArticle.datumfreigabe);
      }
      if (updtArticle.datumtempuspreis != null) {
        updtArticle.datumtempuspreis = new Date(updtArticle.datumtempuspreis);
      }
      if (updtArticle.datumtempusmis != null) {
        updtArticle.datumtempusmis = new Date(updtArticle.datumtempusmis);
      }

      if (updtArticle.druckdatum != null) {
        updtArticle.druckdatum = new Date(updtArticle.druckdatum);
      }

      if (updtArticle.vertragsbindung != null) {
        updtArticle.vertragsbindung = new Date(updtArticle.vertragsbindung);
      }

      if (updtArticle.liefertermin != null) {
        updtArticle.liefertermin = new Date(updtArticle.liefertermin);
      }
      if (updtArticle.liefertermin != null) {
        updtArticle.liefertermin = new Date(updtArticle.liefertermin);
      }
      if (updtArticle.bestanddatum != null) {
        updtArticle.bestanddatum = new Date(updtArticle.bestanddatum);
      }
      if (updtArticle.datumarchiv != null) {
        updtArticle.datumarchiv = new Date(updtArticle.datumarchiv);
      }

      if (updtArticle.lieferantBean.id == undefined) {
        updtArticle.lieferantBean = null;
      }
      // removing contact form lieferantBean
      if (this.lieferantBean != null) {
        delete this.lieferantBean.delete;
        updtArticle.lieferantBean = this.lieferantBean;
      }

      if (updtArticle.status == "Spezifikation") {
        updtArticle.status = "S"
      }
      if (updtArticle.status == "Freigabe/Aktiv") {
        updtArticle.status = "F"
      }
      if (updtArticle.status == "Auf Aufbrauch") {
        updtArticle.status = "A"
      }
      if (updtArticle.status == "Blocked") {
        updtArticle.status = "B"
      }
      if (updtArticle.status == "Reserved") {
        updtArticle.status = "R"
      }
      if (updtArticle.status == "Idee") {
        updtArticle.status = "I"
      }
      if (updtArticle.status == "Archiv") {
        updtArticle.status = "X"
      }
      if (updtArticle.status == "Archiv Idee") {
        updtArticle.status = "Z"
      }
      updtArticle.createdDt = new Date(updtArticle.createdDt);
      updtArticle.updtdDt = null;
      delete updtArticle.supplierName
      updtArticle.handelsware = "O";
      updtArticle.ateilidentisch = "O";
      this.selectHandelsware.forEach(itemSel => {
        if (itemSel == "handelsware") {
          updtArticle.handelsware = "X";
        }
        if (itemSel == "ateilidentisch") {
          updtArticle.ateilidentisch = "X";
        }
      });

      // status the cheack boxes  
      updtArticle.prestoStatus = "O";
      updtArticle.flmbks = "O";
      updtArticle.inpreisliste = "O";
      updtArticle.fltempusmis = "O";
      this.selectedStaus.forEach(item => {
        if (item == "prestoStatus") {
          updtArticle.prestoStatus = "X";
        }
        if (item == "flmbks") {
          updtArticle.flmbks = "X";
        }
        if (item == "inpreisliste") {
          updtArticle.inpreisliste = "X";
        }
        if (item == "fltempusmis") {
          updtArticle.fltempusmis = "X";
        }

      });

      // stroring the ek table

      if (this.artical.status != "Idee" && this.artical.status != "Spezifikation") {
        if (this.prestroCaluclation) {
          let priceData = new PriceData()
          priceData.ek = updtArticle.ek;
          priceData.wa = updtArticle.wa;
          priceData.vk = updtArticle.vk;
          priceData.rg = updtArticle.rg;
          let date = new Date(this.priseDateGv);
          let curentDate = new Date();
          priceData.datum = date.getTime();
          priceData.articleID = this.emittedArticleId;
          priceData.year = this.priseDateGv.getFullYear();

          updtArticle.ek = this.currentArticle.ek;
          updtArticle.wa = this.currentArticle.wa;
          updtArticle.vk = this.currentArticle.vk;
          updtArticle.rg = this.currentArticle.rg;

          // Checking presto is selcted or note
          if (updtArticle.prestoStatus == "X") {
            let diff = Math.abs(date.getTime() - curentDate.getTime());
            let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
            //Compring the date
            if (diffDays >= 4) {
              updtArticle.flprestoanpassung = "M";
              this.articleService.preisDataSave(priceData).subscribe(item => {
                this.gultigVonCaluclation = false;
                this.updateService(updtArticle, true);
              });
            } else {
              this.prestroWarning = true;
            }
          } else {
            //confermation
            this.pristoArticle = updtArticle;
            this.pristoPrice = priceData;
            this.prestroConfirmation = true;
          }

        } else {
          //presto presto flag always false
          this.updateService(updtArticle, false);
        }
      } else {
        //presto presto flag always false
        this.updateService(updtArticle, false);
      }
      this.disableTab = false;
    }
  }
  //Conformation dailog for presto

  public prestoConfirmation() {
    this.prestroConfirmation = false;
    this.articleService.preisDataSave(this.pristoPrice).subscribe(item => {
      this.gultigVonCaluclation = false;
      this.updateService(this.pristoArticle, false);
    });
  }


  //Calling the upadate service

  public updateService(updtArticle, flag) {
    this.spinnerService.show();
    this.articleService.updateArticle(updtArticle, flag).subscribe(data => {
    this.disableTab = false;
    this.prepareEVWValu();

    //saving 
    this.saveAssociationsZuord();
    this.tabViewService.emitUpdatServic(this.emittedArticleId);
    this.spinnerService.hide();
    this.messageService.add({ detail: this.translate.instant('article_update'), key: 'c' });
    });
  }

  //restoring the article 
  public restoreArticle() {
    this.tabViewService.emitConfig(this.emittedArticleId);
    this.disableTab = false;
  }

  //Caluclation of ek, wa, rg  and Blp
  public calculation() {
    if (this.selectedRg == null || this.selectedRg.code == 0) {
      this.preisrg = true;
    }
    else {

      if (this.auf == null) {
        this.auf = "0,00";
      }
      this.prestroCaluclation = true;
      this.restoreStringtoNumber();
      let MWST_MULTIPLIKATOR = 1.19;
      let CRITICAL_ROUND_VALUE = 2500;
      let rg = this.selectedRg.code;
      let vk = this.artical.vk * 100;
      let blp = 0;
      let wa = this.artical.wa * 100;
      let aufschlag = this.doublePercentValueAufschlag(this.auf);
      let ek = this.artical.ek * 100;
      if (vk < 0) {
        vk = -1 * vk;
      }
      if (wa < 0) {
        wa = -1 * wa;
      }
      if (ek < 0) {
        ek = -1 * ek;
      }


      /* Calculate */

      // check for VK and WA
      if ((vk != 0) || (wa != 0)) {
        // go backward from WA or VK to the Aufschlag and EK
        if (vk != 0) {
          // determine WA from VK
          blp = vk / MWST_MULTIPLIKATOR;
          wa = blp * (1 - rg);
        }

        else {
          //determine VK from WA
          blp = wa / (1 - rg);
          vk = blp * MWST_MULTIPLIKATOR;
        }
      }
      else {
        // go foreward from EK and Aufschlag to WA and VK
        if ((ek == 0) || (aufschlag == 0)) {
          // nothing to do here ...
        }

        else {
          // determine WA from EK and Aufschlag
          wa = ek * (1 + aufschlag);

          // determine VK from WA
          blp = wa / (1 - rg);
          vk = blp * MWST_MULTIPLIKATOR;
        }
      }

      /* Adjust */
      /* Calculation in Cent !! */

      // Adjust vk
      if (vk > CRITICAL_ROUND_VALUE) {
        // Aufrunden afu volle Euro
        vk = Math.floor((vk + 99.0001) / 100) * 100;
      }

      else {
        // Aufrunden auf volle 10 Cent
        vk = Math.round((vk + 0.0001) / 10) * 10;
      }

      // determine blp from vk and round with two digits
      blp = vk / MWST_MULTIPLIKATOR;
      blp = Math.round(blp + 0.0001);

      // determine WA from blp and round with two digits
      wa = blp * (1 - rg);
      wa = Math.round(wa + 0.0001);

      // check if EK is to calculate
      if (ek == 0) {
        if (aufschlag == -1) {
          // Bad joke
        }
        if (aufschlag == 0) {
          aufschlag = 0.35;
        }
        // determine EK from WA and Aufschlag and truncate with two digits
        ek = wa / (1 + aufschlag);
        ek = Math.round(ek + 0.0001);
      }

      // adjust Aufschlag
      if (ek != 0) {
        aufschlag = (wa - ek) / ek;
      }
      this.artical.vk = vk;
      this.artical.ek = ek;
      this.artical.wa = wa;
      this.auf = this.toFixedCustom((aufschlag * 100), 1);
      this.blp = blp / 100;
      this.articlRg = (this.blp - this.artical.wa / 100).toFixed(2);
      this.exauf = ((wa - ek)).toFixed(2);
      this.setAufschlagColor = this.auf;
      this.prepareEVWValu();
      this.priceLanguageChange(this.presentLanguage);
    }

  }
  //Supplier 
  showSupplier() {
    this.tabViewService.emiteSupplier(this.artical.lieferantBean);
  }



  blockPartNumber() {
    if (this.currentSelPartNum.status === "frei") {
      this.articleService.blockOrResPartNum(this.currentSelPartNum.teilienummer, "B").subscribe(
        (res: any) => {
          if (res) {
            var artikel;
            artikel = res.json();
            this.currentSelPartNum.status = "blockiert"
            this.currentSelPartNum.assignedToArticleId = artikel.id.artikelId;
            this.partNumAssignmentFlag = true;
            this.partNumBlockedFlag = true;
            this.partNumReservedFlag = true;
            this.currentSelPartNum = {};
            this.selectedRow = "";
          }
        }
      );

    }
  }
  unblockPartNumber() {
    if (this.currentSelPartNum.status === "blockiert") {
      this.articleService.freePartNum(this.currentSelPartNum.assignedToArticleId).subscribe(
        (res: any) => {
          if (res) {
            this.currentSelPartNum.status = "frei";
            this.partNumUnblockedFlag = true;
            this.currentSelPartNum = {};
            this.selectedRow = "";
          }
        }
      );

    }
  }
  reserverPartNumber() {
    if (this.currentSelPartNum.status === "frei") {
      this.articleService.blockOrResPartNum(this.currentSelPartNum.teilienummer, "R").subscribe(
        (res: any) => {
          if (res) {
            var artikel;
            artikel = res.json();
            this.currentSelPartNum.status = "reserviert";
            this.currentSelPartNum.assignedToArticleId = artikel.id.artikelId;
            this.partNumAssignmentFlag = true;
            this.partNumBlockedFlag = true;
            this.partNumReservedFlag = true;
            this.currentSelPartNum = {};
            this.selectedRow = "";
          }
        }
      );

    }
  }
  unreservePartNumber() {
    if (this.currentSelPartNum.status === "reserviert") {
      this.articleService.freePartNum(this.currentSelPartNum.assignedToArticleId).subscribe(
        (res: any) => {
          if (res) {
            this.currentSelPartNum.status = "frei";
            this.partNumUnreservedFlag = true;
            this.currentSelPartNum = {};
            this.selectedRow = "";
          }
        }
      );

    }
  }


  getZuordList() {
    if (!this.emittedArticleId) {
      return;
    }
    this.tabViewService.getZuordnungData(this.emittedArticleId).subscribe(res => {
      let zuordnungVal = res;
      this.restoreZuord = res;
      this.itemsTree = []; this.zuordngArtikelBaureihe = "";
      this.zuordBaureiheFrArtikel = [];
      var nameBaureiheList: string[] = [];
      console.log("this is result ",zuordnungVal);
      let finalobj = JSON.parse(zuordnungVal._body);
        finalobj.forEach(element => {
        console.log("this is element ",element);
        this.itemsTree.push(new TreeviewItem(element));
        this.itemTreeValueStored.push(new TreeviewItem(element));
      });

      this.itemsTree.forEach(element => {
        if (element) {
          if (element.checked === true) {
            nameBaureiheList.push(element.text + ";");
          }
          if (element.children) {
            element.children.forEach(ele => {
              if (ele.checked === true) {
                nameBaureiheList.push(element.text + ";");

              }
              if (ele.children) {
                ele.children.forEach(e => {
                  if (e.checked === true) {

                    nameBaureiheList.push(element.text + ";");
                  }
                });
              }
            });
          }
        }
      });
      if (nameBaureiheList !== undefined) {
        this.zuordBaureiheFrArtikel = _.uniq(nameBaureiheList);
        this.zuordBaureiheFrArtikel.forEach(text => {
          this.zuordngArtikelBaureihe = this.zuordngArtikelBaureihe + text;
        });
      }
      if (this.values) {
        this.valuesReplica = this.values;
      } else {
        this.valuesReplica = [];
      }
    });
    this.zuordOkFlag = true;
  }

  // clear the fields
  public clearFeilds() {

    this.setAufschlagColor = 1;
    if (this.presentLanguage == 'de') {
      this.artical.wa = "0,00";
      this.artical.vk = "0,00";
    } else {
      this.artical.wa = "0.00";
      this.artical.vk = "0,00";
    }

    if (this.auf == 0 || this.auf == null) {
      this.artical.vk = "0,0";
      this.blp = "0,0";
      this.artical.wa = "0,0";
      this.artical.ek = "0,0";
      this.priceLanguageChange(this.presentLanguage);
    }

  }

  getCheckBoxValues() {
    var nameBaureiheList: string[] = [];
    this.zuordBaureiheFrArtikel = [];
    this.zuordngArtikelBaureihe = "";
    if (!this.zuordNoAssociationChkBx) {
      var disableOk: boolean;
      this.zuordAssociationChanged = true;
      this.changeStatusZr = true;
    } else {
      this.zuordNoAssociationChkBx = true;
      this.zuordAssociationChanged = false;
    }

    this.itemsTree.forEach(element => {
      if (element) {
        if (element.checked === true) {
          nameBaureiheList.push(element.text + ";");
        }
        if (element.children) {
          element.children.forEach(ele => {
            if (ele.checked === true) {
              nameBaureiheList.push(element.text + ";");

            }
            if (ele.children) {
              ele.children.forEach(e => {
                if (e.checked === true) {

                  nameBaureiheList.push(element.text + ";");
                }
              });
            }
          });
        }
      }
    });
    if (nameBaureiheList !== undefined) {
      this.zuordBaureiheFrArtikel = _.uniq(nameBaureiheList);
      this.zuordBaureiheFrArtikel.forEach(text => {
        this.zuordngArtikelBaureihe = this.zuordngArtikelBaureihe + text;
      });
    }
    this.Zurdng = false;


  }

  onFilterChange(value: string) {
  }

  saveAssociationsZuord() {
    if (this.zuordAssociationChanged) {

      var postAssociationList: PostAssociations[] = [];
      if (this.values && this.values.length > 0) {
        this.values.forEach(e => {
          var postAssociation: PostAssociations = new PostAssociations();
          postAssociation.articleId = this.emittedArticleId;
          let num = e.toString();
          let splitArray = num.split(",");
          postAssociation.baureihenId = (+splitArray[0]);
          if (splitArray.length >= 2) {
            postAssociation.bautypId = (+splitArray[1]);
            if (splitArray.length === 3) {
              postAssociation.bauvariantId = (+splitArray[2]);
            }
          } else {
            postAssociation.bautypId = null;
            postAssociation.bauvariantId = null;
          }
          postAssociationList.push(postAssociation);
        });
      } else {
        var postAssociation: PostAssociations = new PostAssociations();
        postAssociation.articleId = this.emittedArticleId;
        postAssociation.baureihenId = null;
        postAssociation.bautypId = null;
        postAssociation.bauvariantId = null;
        postAssociationList.push(postAssociation);
      }
      this.tabViewService.createZuordnungForArtikels(postAssociationList).subscribe(
        (res: any) => {
          if (res) {
            this.tabViewService.getZuordnungData(this.emittedArticleId).subscribe(res => {
              var zuordnungVal = res;
              this.restoreZuord = res;
              this.itemsTree = []; this.zuordngArtikelBaureihe = "";
              this.zuordBaureiheFrArtikel = [];
              var nameBaureiheList: string[] = [];
              zuordnungVal.forEach(element => {
                this.itemsTree.push(new TreeviewItem(element));
                this.itemTreeValueStored.push(new TreeviewItem(element));
              });

              this.itemsTree.forEach(element => {
                if (element) {
                  if (element.checked === true) {
                    nameBaureiheList.push(element.text + ";");
                  }
                  if (element.children) {
                    element.children.forEach(ele => {
                      if (ele.checked === true) {
                        nameBaureiheList.push(element.text + ";");

                      }
                      if (ele.children) {
                        ele.children.forEach(e => {
                          if (e.checked === true) {

                            nameBaureiheList.push(element.text + ";");
                          }

                        });
                      }
                    });
                  }
                }
              });
              if (nameBaureiheList !== undefined) {
                this.zuordBaureiheFrArtikel = _.uniq(nameBaureiheList);
                this.zuordBaureiheFrArtikel.forEach(text => {
                  this.zuordngArtikelBaureihe = this.zuordngArtikelBaureihe + text;
                });
              }

            });
            this.zuordAssociationChanged = false;
          }
        });
    }
    if (this.zuordNoAssociationChkBx) {
      var postAssociationList: PostAssociations[] = [];
      if (this.values && this.values.length > 0) {
        this.values.forEach(e => {
          var postAssociation: PostAssociations = new PostAssociations();
          postAssociation.articleId = this.emittedArticleId;
          let num = e.toString();
          let splitArray = num.split(",");
          postAssociation.baureihenId = (+splitArray[0]);
          if (splitArray.length >= 2) {
            postAssociation.bautypId = (+splitArray[1]);
            if (splitArray.length === 3) {
              postAssociation.bauvariantId = (+splitArray[2]);
            }
          } else {
            //postAssociation.bautypId = null;
            //postAssociation.bauvariantId = null;
          }
          postAssociationList.push(postAssociation);
        });
      }
      if (null != postAssociationList) {
        this.tabViewService.deleteZuordnungForArtikel(postAssociationList).subscribe(
          (res: any) => {
            if (res) {

              this.zuordNoAssociationChkBx = false;
            }
          }
        );
      }
    }

  }

  checkBoxOnChange() {
    if (this.zuordNoAssociationChkBx) {
      this.itemsTree.forEach(element => {
        element.checked = false;
        if (element.children) {
          element.children.forEach(ele => {
            ele.checked = false;
            if (ele.children) {
              ele.children.forEach(e => {
                e.checked = false
              });
            }
          });
        }
      });
      this.zuordOkFlag = false;
      this.articlaChange = false;
    }
    else {
      this.zuordOkFlag = true;
    }
  }

  closeZuordPopup() {

    var nameBaureiheList: string[] = [];
    this.zuordBaureiheFrArtikel = [];
    this.zuordngArtikelBaureihe = "";
    this.values = this.valuesReplica;
    this.itemsTree = [];
    this.restoreZuord.forEach(element => {
      this.itemsTree.push(new TreeviewItem(element));
      this.itemTreeValueStored.push(new TreeviewItem(element));
    });

    this.itemsTree.forEach(element => {
      if (element) {
        if (element.checked === true) {
          nameBaureiheList.push(element.text + ";");
        }
        if (element.children) {
          element.children.forEach(ele => {
            if (ele.checked === true) {
              nameBaureiheList.push(element.text + ";");

            }
            if (ele.children) {
              ele.children.forEach(e => {
                if (e.checked === true) {

                  nameBaureiheList.push(element.text + ";");
                }
              });
            }
          });
        }
      }
    });
    if (nameBaureiheList !== undefined) {
      this.zuordBaureiheFrArtikel = _.uniq(nameBaureiheList);
      this.zuordBaureiheFrArtikel.forEach(text => {
        this.zuordngArtikelBaureihe = this.zuordngArtikelBaureihe + text;
      });
    }
    this.zuordNoAssociationChkBx = false;
    this.zuordOkFlag = false;
    this.Zurdng = false;


  }

  showPartNum() {
    this.TeileNo = true;
    this.partNumFromMenu = true;
    this.partNumAssignmentFlag = true;
    this.partNumBlockedFlag = true;
    this.partNumUnblockedFlag = true;
    this.partNumReservedFlag = true;
    this.partNumUnreservedFlag = true;
    this.selectedRow = "";
    this.loading = true;
    this.changePartNum();
    this.loading = false;
  }

  showTeileNumPopupPM() {
    if (this.tNumRespPerson === null) {
      if (this.articleTeilenummer === null || this.articleTeilenummer === "") {
        this.first = 0;
        this.articleTeilenummer = this.articleTeilenummer.replace(/\s/g, "B6 600 0000");
        let partNumberSel = new PartNumbers();
        this.searchFree = this.articleTeilenummer;
        this.changePartNum();
        partNumberSel = this.partNumListWithStatus.find(x => x.teilienummer === this.articleTeilenummer);
        this.selectedRow = partNumberSel;
      }

      if (this.articleTeilenummer && this.articleTeilenummer !== null || this.articleTeilenummer !== "") {
        this.articleTeilenummer = this.articleTeilenummer.replace(/\s/g, "");
        let partNumberSel = new PartNumbers();
        if (this.articleTeilenummer === "-angefordert-") {
          this.searchFree = '';
        } else {
          this.searchFree = this.articleTeilenummer;
        }
        this.changePartNum();
        partNumberSel = this.partNumListWithStatus.find(x => x.teilienummer === this.articleTeilenummer);
        this.selectedRow = partNumberSel;
      } else {
        this.loading = true;
        this.changePartNum();
        this.loading = false;
      }
      this.partNumBlockedFlag = true;
      this.partNumUnblockedFlag = true;
      this.partNumReservedFlag = true;
      this.partNumUnreservedFlag = true;
      this.partNumAssignmentFlag = true;
      this.TeileNo = true;
    } else {
      this.articleTeilenummer = "-angefordert-";
      this.teleNumRequested = true;
    }
  }




  closePartNum(table1) {
    this.searchFree = "";
    this.selectedRow = "";
    if (this.partNumFromMenu === true) {
      this.partNumFromMenu = false;
    }
    table1.reset();
    this.TeileNo = false;
  }


  //Rg value 
  public getRgValves() {
    this.rgList = [{ name: "", code: 0 }]
    this.rabattgrupenService.getAllRabattgrupenList().subscribe(item => {
      let sortedRG = item.sort((data1, data2) => {
        let value1 = data1.code;
        let value2 = data2.code;
        if (value1 > value2) {
          return 1;
        }

        if (value1 < value2) {
          return -1;
        }

        return 0;
      });
      this.rgList = this.rgList.concat(sortedRG);
    });

  }

  //On change value
  onChange(newValue) {
    this.formInputChanged = true;;  // don't forget to update the model here
  }

  //Mesage service

  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }

  zuordCheckboxclicked() {

    this.zuordOkFlag = false;
    if (this.values && this.values.length > 0) {
      this.disableZuordDelCheckbox = false;
    } else if (this.values.length === 0) {
      this.disableZuordDelCheckbox = true;
    }
  }

  tabdisbloow = 0;

  ngDoCheck() {
    const changes = this.articlaDiffer.diff(this.artical);
    const changeH = this.handelswareDiffer.diff(this.selectHandelsware);
    const changeST = this.selectedStausDiffer.diff(this.selectedStaus);
    if (changes || changeH || changeST || this.changeStatusTF || this.changeStatusZr || this.teleNChanged || this.teleNumRequested) {

      if (this.letChecked > 0) {

        this.articlaChange = false;
        this.disableTab = true;
      }

      if (this.tabdisbloow > 2) {
        this.tabdisable = true;
      }
      else {
        this.tabdisable = false;
      }
      this.tabdisbloow++;
      this.letChecked++;
    }
    if (this.gultinvonChange) {
      this.gultinvonChange = false;
      this.articlaChange = true;
      this.disableTab = false;
    }

    if (this.articlaChange) {
      this.disableTab = false;
    }
  }

  public getDocumentById(id) {
    this.brandobj['heading'] = [];
    let X = 0;
    let docKey = Object.keys(this.brandobj);
    docKey.shift();
    this.documentService.getTabDocumentById(id).subscribe(item => {
      docKey.forEach(dkey => {
        this.brandobj[dkey].value = item.json().data.filter(x => x.processID === this.brandobj[dkey].key);
        if (X < this.brandobj[dkey].value.length) {
          X = this.brandobj[dkey].value.length;
        }
      });

      for (let i = 1; i <= X; i++) {
        this.brandobj['heading'].push('Document')
      }
      if (X == 0)
        this.brandobj['heading'] = ['Document'];

    });
  }

  public creatDocument() {
    if (this.fileType == "file" && this.attachedFile != null && !this.checkFilesize) {
      this.getBase64(this.attachedFile).then(data => {
        let fileData = {
          "name": this.fileName,
          "processID": this.attachedStatusId,
          "data": data
        }
        this.documentService.uploadFileArticleID(this.emittedArticleId, fileData).subscribe(item => {
          this.getDocumentById(this.emittedArticleId);
          this.displayFile = false;
          this.fileType = "file";
          this.messageService.add({ detail: this.translate.instant('doc_create'), key: 'c' });
        });
      });
    }
    if (this.fileType == "article") {

      if ((this.fileTelnummer == "" || this.fileTelnummer == undefined) && (this.fileProductId == "" || this.fileProductId == undefined)) {
        this.validErrrAdF = true;
      }

      if (this.fileTelnummer != undefined) {
        this.fileTelnummer = this.fileTelnummer.replace(/\s/g, "")
      }
      if (this.fileTelnummer == "" || this.fileTelnummer == undefined) {
        this.fileTelnummer = "0";
      }

      if (this.fileProductId == "" || this.fileProductId == undefined) {
        this.fileProductId = "0"
      }
      this.documentService.copythProsesDocument(this.fileProductId, this.fileTelnummer, this.emittedArticleId, this.attachedStatusId).subscribe(item => {
        this.documentCopyVld = item['_body'];
        if (item['_body'] == "FCS") {
          this.getDocumentById(this.emittedArticleId);
          this.displayFile = false;
          this.fileType = "file";
          this.messageService.add({ detail: this.translate.instant('doc_copy'), key: 'c' });
        }

        if (this.fileTelnummer == "0") {
          this.fileTelnummer = "";
        }

        if (this.fileProductId == "0") {
          this.fileProductId = ""
        }
      });
    }
  }

  public editeDocument() {
    //Download the file
    if (this.editeFiletype == "download") {
      this.documentService.downloadFile(this.selectedFile.dateiID).subscribe(item => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        let byte = this.base64ToArrayBuffer(item.json().data);
        var blob = new Blob([byte], {type:'application/octet-stream'});
        var fileName = '' + this.selectedFile.name;
        if(window.navigator.msSaveOrOpenBlob) {
          //msSaveBlob only available for IE & Edge
          window.navigator.msSaveBlob(blob, fileName);
        }else {
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = fileName;
          document.body.appendChild(anchor); 
          anchor.click();
          //It's better to remove the elem
          document.body.removeChild(anchor);
        }
      });
      this.documentdeleteui = false;
    }

    //Delete the file
    if (this.editeFiletype == "delete_file") {
      this.documentService.deletFile(this.selectedFile.dateiID).subscribe(item => {
        this.documentdeleteui = false;
        this.getDocumentById(this.emittedArticleId);
        this.messageService.add({ detail: this.translate.instant('doc_del'), key: 'c' });
      });
    }

    //Replase the file
    if (this.editeFiletype == "replace_file" && !this.checkFilesize) {
      this.enterRpFl = true;
      if (this.attachedFile != undefined && this.attachedFile != null) {
        this.enterRpFl = false;
        let bbb = this.getBase64(this.attachedFile).then(data => {
          let fileData = {
            "name": this.attachedFile.name,
            "dateiID": this.selectedFile.dateiID,
            "processID": this.attachedStatusId,
            "data": data
          }

          this.documentService.updateFile(fileData).subscribe(item => {
            this.documentdeleteui = false;
            this.getDocumentById(this.emittedArticleId);
            this.messageService.add({ detail: this.translate.instant('doc_update'), key: 'c' });
          });
        });
      }
    }
    //Delete the file link
    if (this.editeFiletype == "delete_link") {
      this.documentService.deletFileLink(this.selectedFile.dateiID, this.emittedArticleId).subscribe(item => {
        this.documentdeleteui = false;
        this.getDocumentById(this.emittedArticleId);
        this.messageService.add({ detail: this.translate.instant('doc_del'), key: 'c' });
      });
    }
  }

  public showEditeDocument(data, processID) {
    this.checkFilesize = false;
    this.attachedStatusId = processID;
    this.editeFiletype = "download";
    this.replaceFile = null;
    this.attachedFile = null;
    this.selectedFile = data;
    this.enterRpFl = false;
    this.documentdeleteui = true;
  }
  public replaceFiles($event) {
    this.checkFilesize = false;
    if ($event.target.files[0].size > 1048576*20) {
      this.checkFilesize = true;
    }
    this.replaceFile = $event.target.files[0].name;
    this.attachedFile = $event.target.files[0];
    $event.target.value = null;
  }
  //Copy the all article
  public copyAllDocument() {
    if ((this.allFilePi == "" || this.allFilePi == undefined) && (this.allFileTr == "" || this.allFileTr == undefined)) {
      this.validErrrcpF = true;
    }
    if (this.fileTelnummer != undefined) {
      this.allFileTr = this.allFileTr.replace(/\s/g, "")
    }
    if (this.allFileTr == "" || this.allFileTr == undefined) {
      this.allFileTr = "0";
    }

    if (this.allFilePi == "" || this.allFilePi == undefined) {
      this.allFilePi = "0"
    }
    this.documentService.copythALLDocument(this.allFilePi, this.allFileTr, this.emittedArticleId).subscribe(item => {
      this.documentCopyAllVld = item['_body'];
      if (item['_body'] == "FCS") {
        this.getDocumentById(this.emittedArticleId);
        this.displayAllFile = false;
        this.messageService.add({ detail: this.translate.instant('doc_copy'), key: 'c' });
      }
      if (this.allFileTr == "0") {
        this.allFileTr = "";
      }
      if (this.allFilePi == "0") {
        this.allFilePi = ""
      }
    });
  }
  public sortimentsShowPopup() {
    this.sortimentsShow = true;
  }

  //Converting file to base64
  public getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  }

  // created by kritika
  onZourdCheckboxChange(event) {
    this.values = event;
    if (this.values && this.values.length > 0) {
      this.disableZuordDelCheckbox = false;
    } else {
      this.disableZuordDelCheckbox = true;
    }
  }
  // Converting base64 to ArrayBuffer
  public base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  public convertStringToArray(data: string) {
    let array = [];
    for (const ch of data) {
      array.push(ch);
    }
    return array;
  }
  // Assigne Sortiment
  public assigneSortiments() {
    this.artical.sortklasse = "";
    this.sortimentsklasseSelect.forEach(item => {
      this.artical.sortklasse += item;
    });
    this.sortimentsShow = false;
  }
  //freigabe Article
  public freigabeArticle() {
    let aufschlag = "0,0";
    //check = true;
    this.reason == null;
    if (this.auf == null) {
      aufschlag = "0,0"
    } else {
      aufschlag = (this.auf).toString().replace('.', ',');
    //  console.log(aufschlag);
    }
    this.articleService.getMargin(this.emittedArticleId, aufschlag).subscribe(item => {
      this.emailDto = item.data;
      this.freigabePopup = false;
      let check = true;
      this.freigabeValidErr = "";
      // 2 tenienumber condidtion working
      if (!this.articleTeilenummer || this.articleTeilenummer === '-angefordert-' && check) {
        this.freigabeValidErr = "freigabe_tele_valid";
        this.freigabePopup = true;
        check = false;
        this.emailEkChange = false;
      }
      if (this.artical.lieferantBean.id == undefined && check) {
        this.freigabeValidErr = "freigabe_liefrent_valid";
        this.freigabePopup = true;
        check = false;
      }
      //4 wa and rabatt condition missing
      if ((!this.artical.wa || this.artical.wa == 0 || this.artical.wa == "0,00" || !this.articlRg) && check) {
        this.freigabeValidErr = "freigabe_wa_rg_valid";
        this.freigabePopup = true;
        check = false;
        this.emailEkChange = true;
      }

      if ((this.reason == null) && check) {
        check = false;
        if (this.emailDto.marge == "" || this.emailDto.abteilungName == "" || this.emailDto.abteilungUserEmeaIdEmail == "") {
          check = true;
        } else {
          this.email = true;
          this.emailErrMsg = false;
          check = false;
        }


      }

      //5 teliefamily condition workign
      if (!this.artical.teilefamilie.name && check) {
        this.freigabeValidErr = "freigabe_teile_valid";
        this.freigabePopup = true;
        check = false;
      }

      //6 bengETS condition workign
      if (!this.artical.namede && check) {
        this.freigabeValidErr = "freigabe_bengETS_valid";
        this.freigabePopup = true;
        check = false;
      }

      //7 english condition workign
      if (!this.artical.nameen && check) {
        this.freigabeValidErr = "freigabe_english_valid";
        this.freigabePopup = true;
        check = false;
      }
      //8 english spanish workign
      if (!this.artical.namees && check) {
        this.freigabeValidErr = "freigabe_spanish_valid";
        this.freigabePopup = true;
        check = false;
      }
      //9 french english spanish workign
      if (!this.artical.namefr && check) {
        this.freigabeValidErr = "freigabe_namefr_valid";
        this.freigabePopup = true;
        check = false;
      }
      //10 sortklasse workign
      if (!this.artical.sortklasse && check) {
        this.freigabeValidErr = "freigabe_sortklasse_valid";
        this.freigabePopup = true;
        check = false;
      }

      //11 erstdisposition
      if (!this.artical.erstdisposition && check) {

        this.freigabeValidErr = "freigabe_erstdisposition_valid";
        this.freigabePopup = true;
        check = false;
      } else if (this.artical.erstdisposition == 0 && check) {
        this.freigabeValidErr = "freigabe_erstdisposition_Zero";
        this.freigabePopup = true;
        check = false;
      }
      //12 liefertermin
      if (!this.artical.liefertermin && check) {
        this.freigabeValidErr = "freigabe_liefertermin_valid";
        this.freigabePopup = true;
        check = false;
      }

      //13 vertragsbindung
      if (!this.artical.vertragsbindung && check) {
        this.freigabeValidErr = "freigabe_vertragsbindung_valid";
        this.freigabePopup = true;
        check = false;
      }
      //14 Zuordng 
      if (!this.zuordngArtikelBaureihe && check) {
        this.freigabeValidErr = "freigabe_zuordng_valid";
        check = false;
        this.freigabeZordPopup = true;
      } if (!this.freigabeZordPopup && check) {
        this.showConform = true;
      }
    });
  }

  public emailValidtion() {
    this.emailErrMsg = false;
    if (this.reason == null || this.reason == "") {
      this.emailErrMsg = true;
    } else {
      this.email = false;
      this.freigabeArticle()
    }
  }
  public emailValidtionFls() {
    this.email = false;
    this.reason = null;
  }
  public updateFreigabe(sendEk, BuyerEmeaId, marginUnderRunDTO) {
    this.showConform = false;
    this.spinnerService.show();
    this.articleService.updateArtikelStatusFreigabe(this.emittedArticleId, sendEk, BuyerEmeaId, marginUnderRunDTO).subscribe(item => {
      if (item) {
        this.artical.status = "Freigabe/Aktiv";
        this.tabViewService.emitUpdatServic(this.emittedArticleId);
        this.messageService.add({ detail: this.translate.instant('article_publish'), key: 'c' });
        // this.articleService.getArticles().subscribe(res => {
        //   var articlesList = res.json().data;
        //   this.spinnerService.show();
        //   this.tabViewService.emitArticle(articlesList);
        //   this.spinnerService.show();
        //   this.tabViewService.emitConfig(this.emittedArticleId);
        //   this.articles = res.json().data;
        //   //this.dashboardtableservice.resetTableData();
        //   this.spinnerService.show();
        //   this.tabViewService.emitUpdatServic(this.emittedArticleId);
        //   this.spinnerService.hide();
        //   if (this.articles)
            
        // });
        this.spinnerService.hide();  
      } else {
        this.spinnerService.hide();
        this.messageService.add({ detail: this.translate.instant('article_Freigabe_err'), key: 'c' });
      }

    });
  }

  public freigabeZordPopupShow() {
    this.Zurdng = true;
    this.freigabeZordPopup = false
  }

  public doublePercentValueAufschlag(input) {
    if (input == null) return .35;
    if (input == 0) return .35;
    if (Number.isNaN(input)) {
      return .35;
    }
    let value = input / 100;
    return value;
  }

  public cleanupStringValue(input) {
    let re = /\,/gi;
    let result = input.replace(re, ".");
    return result
  }
  //prepare value for showing in Ui
  public prepareEVWValu() {
    this.artical.vk = (this.artical.vk / 100).toFixed(2);
    this.artical.ek = (this.artical.ek / 100).toFixed(2);
    this.artical.wa = (this.artical.wa / 100).toFixed(2);
    if (this.artical.wa == null || this.artical.wa == 0) {
      this.artical.wa = "0,00";
    }
    if (this.artical.ek == null || this.artical.ek == 0) {
      this.artical.ek = "0,00";
    }
    if (this.artical.vk == null || this.artical.vk == 0) {
      this.artical.vk = (0).toFixed(2);
    }
    if (this.blp == null) {
      this.blp = "0,00"
    }
    if (this.auf == null || this.auf == "0,00" || this.auf == "0.00") {
      this.auf = "35,00"
    }
    this.exauf = ((parseFloat(this.artical.wa) - parseFloat(this.artical.ek))).toFixed(2);
    this.perpareFoUI();
    this.priceLanguageChange(this.presentLanguage);
  }

  //prepare value for storing in db
  public storeEVWValu() {
    this.artical.vk = this.artical.vk.toString().replace(/[^0-9]/g, "");
    this.artical.ek = this.artical.ek.toString().replace(/[^0-9]/g, "");
    this.artical.wa = this.artical.wa.toString().replace(/[^0-9]/g, "");
  }

  public restoreStringtoNumber() {
    this.numberFormating();
    this.prepareFodb();
    this.artical.vk = (this.artical.vk).replace('/[^\d.-]/g', '');
    this.artical.wa = (this.artical.wa).replace(/[^\d.-]/g, '');
    this.auf = (this.auf).replace(/[^\d.-]/g, '')
    this.artical.ek = (this.artical.ek).replace(/[^\d.-]/g, '');
    this.blp = (this.blp.toString()).replace(/[^\d.-]/g, '');
    this.articlRg = (this.articlRg.toString()).replace(/[^\d.-]/g, '');
  }

  //number formating 
  public numberFormating() {
    if (this.presentLanguage == 'en') {
      this.artical.vk = (this.artical.vk).replace(/[^0-9.]/g, "");
      this.artical.wa = (this.artical.wa).replace(/[^0-9.]/g, "");
      this.auf = (this.auf).replace(/[^0-9,]/g, "");
      this.artical.ek = (this.artical.ek).replace(/[^0-9.]/g, "");
      this.blp = (this.blp.toString()).replace(/[^0-9.]/g, "");
      this.articlRg = (this.articlRg.toString()).replace(/[^0-9.]/g, "");
    } else {
      this.artical.vk = (this.artical.vk).replace(/[^0-9,]/g, "");
      this.artical.wa = (this.artical.wa).replace(/[^0-9,]/g, "");
      this.auf = (this.auf).replace(/[^0-9,]/g, "");
      this.artical.ek = (this.artical.ek).replace(/[^0-9,]/g, "");
      this.blp = (this.blp.toString()).replace(/[^0-9,]/g, "");
      this.articlRg = (this.articlRg.toString()).replace(/[^0-9,]/g, "");
    }
  }

  public perpareFoUI() {
    if ((typeof this.artical.vk == "string") && (this.artical.vk != null)) {
      this.artical.vk = (this.artical.vk).replace('.', ',');
    }
    if ((typeof this.artical.wa == "string") && (this.artical.wa != null)) {
      this.artical.wa = (this.artical.wa).replace('.', ',');
    }
    if ((typeof this.auf == "string") && (this.auf != null)) {
      this.auf = (this.auf).replace('.', ',');
    }
    if ((typeof this.artical.ek == "string") && (this.artical.ek != null)) {
      this.artical.ek = (this.artical.ek).replace('.', ',');
    }
    if ((typeof this.blp == "string") && (this.blp != null)) {
      this.blp = (this.blp.toString()).replace('.', ',');
    }
    if ((typeof this.articlRg == "string") && (this.articlRg != null)) {
      this.articlRg = (this.articlRg.toString()).replace('.', ',');
    }
    if (this.exauf != null) {
      this.exauf = (this.exauf.toString()).replace('.', ',');
    }

  }

  public prepareFodb() {
    if ((typeof this.artical.vk == "string") && (this.artical.vk != null)) {
      this.artical.vk = (this.artical.vk).replace(',', '.');
    }
    if ((typeof this.artical.wa == "string") && (this.artical.wa != null)) {
      this.artical.wa = (this.artical.wa).replace(',', '.');
    }

    if ((typeof this.auf == "string") && (this.auf != null)) {
      this.auf = (this.auf).replace(',', '.');
    }

    if ((typeof this.artical.ek == "string") && (this.artical.ek != null)) {
      this.artical.ek = (this.artical.ek).replace(',', '.');
    }

    if ((typeof this.blp == "string") && (this.blp != null)) {
      this.blp = (this.blp.toString()).replace(',', '.');
    }


    if ((typeof this.articlRg == "string") && (this.articlRg != null)) {
      this.articlRg = (this.articlRg.toString()).replace(',', '.');
    }

    if (this.exauf != null) {
      this.exauf = (this.exauf.toString()).replace(',', '.');
    }
  }

  //English to german
  public prepareFodbGerman() {
    if ((typeof this.artical.vk == "string") && (this.artical.vk != null)) {
      this.artical.vk = (this.artical.vk).replace('.', ',');
    }
    if ((typeof this.artical.wa == "string") && (this.artical.wa != null)) {
      this.artical.wa = (this.artical.wa).replace('.', ',');
    }

    if ((typeof this.auf == "string") && (this.auf != null)) {
      this.auf = (this.auf).replace('.', ',');
    }

    if ((typeof this.artical.ek == "string") && (this.artical.ek != null)) {
      this.artical.ek = (this.artical.ek).replace('.', ',');
    }

    if (this.blp != null) {
      this.blp = (this.blp.toString()).replace('.', ',');
    }
    if ((typeof this.articlRg == "string") && (this.articlRg != null)) {
      this.articlRg = (this.articlRg.toString()).replace('.', ',');
    }

    if (this.exauf != null) {
      this.exauf = (this.exauf.toString()).replace('.', ',');
    }

  }

  /**
 * converts the vk with tax into the vk without tax = BLP (Brutto List Price)
 * @param vk The vk with tax
 * 
 * @return The vk without tax
 */
  public intialCaluclation(vk) {
    let MWST_MULTIPLIKATOR = 1.19;
    let CRITICAL_ROUND_VALUE = 2500;
    let rg = 0;
    if (this.selectedRg) {
      rg = this.selectedRg.code;
    }
    let vkTemp = vk;
    let blp;
    let wa = this.artical.wa;
    let ek = this.artical.ek;
    let aufschlag;

    // Adjust vk
    if (vkTemp > CRITICAL_ROUND_VALUE) {
      // Aufrunden zum vollen Euro
      vkTemp = Math.floor((vkTemp + 99.0001) / 100) * 100;
    } else {
      // Aufrunden zu vollen 10 Cent
      vkTemp = Math.round((vkTemp + 0.0001) / 10) * 10;
    }
    // determine blp from vk and round with two digits
    blp = vkTemp / MWST_MULTIPLIKATOR;
    blp = Math.round(blp + 0.0001);
    this.blp = (blp / 100).toFixed(2);;
    this.articlRg = (this.blp - this.artical.wa / 100).toFixed(2);
    //wa = blp * (1 - rg);
    wa = Math.round(wa + 0.0001);
    // check if EK is to calculate
    if (ek == 0) {
      if (aufschlag == -1) {
        // Bad joke
      }
      if (aufschlag == 0) {
        aufschlag = 0.35;
      }
      // determine EK from WA and Aufschlag and truncate with two digits
      ek = wa / (1 + aufschlag);
      ek = Math.round(ek + 0.0001);
    }
    // adjust Aufschlag
    if (ek != 0) {
      aufschlag = (wa - ek) / ek;
    }
    this.artical.wa = wa;
    if (Number.isNaN(aufschlag)) {
      aufschlag = 0;
    }
    this.auf = this.toFixedCustom((aufschlag * 100), 1);
    this.setAufschlagColor = this.auf;
    this.prepareEVWValu();
    this.priceLanguageChange(this.presentLanguage);
  }


  //Get the price
  public getPrise() {

    this.articleService.preisData(this.emittedArticleId).subscribe(item => {
      let data = item.json()
      this.priceList = [{ name: null, code: null, id: null }];
      this.selectedPricesAllData = data.data;
      //this.selectedPricesAllData = this.sortPriceByDate(this.selectedPricesAllData);
      if (this.selectedPricesAllData.length > 0) {
        this.priceList = [];
        this.selectedPricesAllData.forEach(item => {
          this.priceList.push({ name: this.datepipe.transform(new Date(item.datum), 'yyyy-MM-dd'), code: item.datum, id: item.articleID });
        });

        this.selectedPrices = this.priceList[0];
        this.initialyear = this.priceList[this.priceList.length-1].name.toString();
        this.gultinvonChange = true;
        this.ekYear = new Date(this.selectedPrices.name).getFullYear();
        this.selectedPricesByDate();
      } else {
        this.priceList = [{ name: null, code: null, id: null }];
      }
    });
  }
  //Prise sort by date
  public sortPriceByDate(item) {
    let sortedRG = item.sort((data1, data2) => {
      let value1 = data1.datum;
      let value2 = data2.datum;
      if (value1 > value2) {
        return 1;
      }
      if (value1 > value2) {
        return -1;
      }
      return 0;
    });

    return sortedRG;
  }
  //Select price by Date
  public selectedPricesByDate() {
    let data = this.selectedPricesAllData.find(x => x.datum === this.selectedPrices.code);
    this.priceDeltaPercentage(data);
    this.artical.vk = data.vk;
    this.artical.wa = data.wa;
    this.artical.ek = data.ek;
    this.selectedRg = this.rgList.find(x => x.id === data.rg);
    this.ekYear = data.year;//new Date(this.selectedPrices.name).getFullYear();
    this.intialCaluclation(this.artical.vk);
  }

  //Change in Rabatt
  public selectedRabatt() {
    this.auf = null;
    this.artical.wa = "0,00";
    this.articlaChange = false;
    if (this.artical.status != 'Idee' && this.artical.status != 'Spezifikation') {
      this.gultigVonCaluclation = true;
    }
  }

  //Change in Rabatt
  public changeEkVkWa() {
    this.articlaChange = false;
    if (this.artical.status != 'Idee' && this.artical.status != 'Spezifikation') {
      this.gultigVonCaluclation = true;
    }

  }
  //Price table updating
  public updatePriceTable(data) {
    this.articleService.preisDataSave(data).subscribe(item => {
      this.gultigVonCaluclation = false;
      return item;
    });
  }
  //Prise % caluclation 
  public priceDeltaPercentage(data) {
    let index = this.selectedPricesAllData.findIndex(x => x.datum == data.datum);
    if (index < this.selectedPricesAllData.length - 1) {
      let vkGT = data.vk;
      let VkLt = this.selectedPricesAllData[index + 1].vk;
      let delta = (vkGT / VkLt) - 0.999999;
      this.deltaPrice = (this.toFixedCustom(delta * 100, 1).toString()).replace('.', ',');
      if (this.deltaPrice == Infinity) {
        this.deltaPrice = null;
      }
    } else {
      this.deltaPrice = null;
    }
  }
  //Price Language transaltion
  public priceLanguageChange(lang) {
    if (lang == 'en') {
      this.prepareFodb();
      this.languageConversion(lang);
      
    }
    if (lang == 'de') {
      this.prepareFodbGerman();
      this.languageConversion(lang);     
    }
    this.gethistorydata(this.emittedArticleId);
    
  }

  public languageConversion(lang: any) {
    this.artical.vk = formateCurrency(parseValue(this.artical.vk, lang), lang);
    this.artical.ek = formateCurrency(parseValue(this.artical.ek, lang), lang);
    this.artical.wa = formateCurrency(parseValue(this.artical.wa, lang), lang);
    this.blp = formateCurrency(parseValue(this.blp, lang), lang);
    this.articlRg = formateCurrency(parseValue(this.articlRg, lang), lang);
    this.exauf = formateCurrency(parseValue(this.exauf, lang), lang);  
    // if (this.deltaPrice !== null) {
    //   this.deltaPrice = formateCurrency(parseValue(this.deltaPrice, lang), lang);
    // }
  }

  sendToPresto() {
    this.prestoBackendConform = true;
  }

  submitPrestoToBackend() {
    this.tabViewService.sendToPresto(this.emittedArticleId).subscribe(item => {
      if (item) {
        this.messageService.add({ detail: this.translate.instant('article_presto_err'), key: 'c' });
        this.tabViewService.emitConfig(this.emittedArticleId);
      } else {
        this.messageService.add({ detail: this.translate.instant('article_presto_sucss'), key: 'c' });
        this.tabViewService.emitConfig(this.emittedArticleId);
      }
    });
    this.prestoBackendConform = false;
  }

  //Showing the Buyer popup

  public showBuyer() {
    this.assignmentBuyer = true;
    this.showConform = false;
    this.articleService.getBuyerList().subscribe(item => {
      this.assignmentBuyerList = item;
    });
  }
  //
  //Showing the Conformation popup
  public conformUpdatEk() {
    this.buyerErrorMessage = false
    if (this.assignmentBuyerSelect.name != null) {
      this.buyerEkConform = true;
      this.assignmentBuyer = false;
    } else {
      this.buyerErrorMessage = true;
    }
  }

  //Showing update status
  public showBuyerStatus(status) {
    this.buyerEkConform = false;
    let marginUnderRunDTO = {
      "artikelID": this.emittedArticleId,
      "teileNummer": this.artical.teilenummer,
      "benennungEtas": this.artical.namede,
      "ek": this.artical.ek,
      "aufschlagRelative": this.auf,
      "aufschlagAbsolut": this.exauf,
      "wa": this.artical.wa,
      "rgRelative": this.selectedRg.code,
      "blp": this.blp,
      "vk": this.artical.vk,
      "abteilungName": this.emailDto.abteilungName,
      "abteilungUserEmeaIdEmail": this.emailDto.abteilungUserEmeaIdEmail,
      "marge": this.emailDto.marge,
      "reason": this.reason

    }
    let aufschlag = (this.auf).toString().replace(',', '.');
    if (parseFloat(aufschlag) > 30) {
      this.updateFreigabe(status, this.assignmentBuyerSelect.name, {});
    } else {
      this.updateFreigabe(status, this.assignmentBuyerSelect.name, marginUnderRunDTO);
    }

  }

  //Change wa
  public changeWa() {
    if (this.presentLanguage == 'de') {
      this.artical.vk = "0,00";
      this.blp = "0,00";
      this.auf = "0,00";
      this.exauf = "0,00";
    } else {
      this.artical.vk = "0.00";
      this.blp = "0.00";
      this.auf = "0.00";
      this.exauf = "0.00";
    }

  }
  //Change VK
  public changeVk() {
    if (this.presentLanguage == 'de') {
      this.artical.wa = "0,00";
      this.auf = "0,00";
      this.exauf = "0,00";
    } else {
      this.artical.wa = "0.00";
      this.auf = "0.00";
      this.exauf = "0.00";
    }
  }

  omit_Alphabet_char(event) {
    var k;
    document.all ? k = event.keyCode : k = event.which;
    return (k == 8 || k == 44 || k == 45 || k == 46 || (k >= 48 && k <= 57));
  }

  public toFixedCustom(num: any, precision: any) {
    var numSplit = num.toString().split('.');
    if (numSplit.length > 1) {
      var result = parseFloat(numSplit[0] + "." + numSplit[1].substring(0, precision)).toFixed(precision);
      return result;
    } else {
      return num;
    }
  }

  zuordButtonClick() {
    this.Zurdng = true;
    this.getZuordList();
  }

}

class PriceData {
  articleID: null;
  wa: number;
  vk: number;
  rg: number;
  ek: number;
  year: any;
  datum: any;
}