import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Literals } from '../literals/literals-service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';
import { UserService } from 'app/services/UserService';
import { User } from 'dto/UserModel';
import { MenuService } from '../menu/menu.service'
import { Constants } from 'app/constants';
import { MsalService } from '@azure/msal-angular';

interface Internationalisaton{
  name: string;
  code: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

    languages: SelectItem[];
    selectedLanguage: Internationalisaton;
    user : User;
    firstNm : String='';
    lastNm : String='';
    isShow: boolean = true;
  response: any;

  private userDetails = {} as User;

  constructor(private literal:Literals,
    private translate: TranslateService, private loginService: LoginService, private router: Router,
    private userService : UserService, private menuservice:MenuService,private authService: MsalService) {
      translate.use('de');

     this.languages = [
       {label:'German ', value:{id:1, name: 'German', code: 'de'}},
       {label:'English ', value:{id:2, name: 'English', code: 'en'}}
       ];
  }

  
  ngOnInit() {
    this.userService.loggedUser.subscribe( value  =>{
      if(value != null)
      {
        console.log(JSON.parse(localStorage.getItem('user')));
      this.user = value;
      this.firstNm = this.user.FIRST_NAME;
      this.lastNm = this.user.LAST_NAME;
      }
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        console.log(JSON.parse(localStorage.getItem('user')));
        if(this.user != null)
        {
        this.firstNm = this.user.FIRST_NAME;
        this.lastNm = this.user.LAST_NAME;
        }
        else{
          console.log(JSON.parse(localStorage.getItem('user')));
          this.getUserDetais();
        }
      } 
     });
  }


   switchLanguage() {
     this.menuservice.changPrice(this.selectedLanguage.code);
     this.translate.use(this.selectedLanguage.code);
   }

  //  logout(){
  //    localStorage.clear();
  //    sessionStorage.clear();
  //   //  window.location.href="/"

  //  }
  

  logout(){
    this.authService.logout();
  }

  public getUserDetais() {
    this.loginService.loginDetails().subscribe(item => {
      console.log("login",item);
      this.user=item;
      // this.userService.setUser=this.userDetails;
      this.firstNm = this.user.FIRST_NAME;
        this.lastNm = this.user.LAST_NAME;
      this.userService.emitConfig(item);
      localStorage.setItem("user",JSON.stringify(this.userDetails) );
      console.log("get user",this.userService.getUser);
    });
  }


}
