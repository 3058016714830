import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';

import { RaberttGruppen } from '../../dto/Lieferant';

import { RabattgrupenService } from '../services/rabbattgrupen.service';

import { MessageService } from 'primeng/api';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { TabViewService } from '../tab-view/tab-view.service';

@Component({
  selector: 'app-rabattgruppen',
  templateUrl: './rabattgruppen.component.html',
  styleUrls: ['./rabattgruppen.component.scss'],
  providers: [MessageService]
})
export class RabattgruppenComponent implements OnInit {
  display: boolean = false;
  rabartGruppen = {} as RaberttGruppen;
  rabartGruppens: RaberttGruppen[] = [];
  rabartGruppensReset: RaberttGruppen[] = [];

  selectedRow = {} as RaberttGruppen;
  enternumber:boolean=false;
  editable: boolean = true;
  create: boolean = true;
  saveRb: boolean = true;
  rgTest: boolean = true;
  prtTest: boolean = true;

  validId: boolean = false;
  validPr: boolean = false;

  editeOprtn: boolean = false;

  columnSelected: boolean = false;
  deleterbtGp: boolean = false;

  newCreate: boolean = false;
  first:any;
  x: number = null;

  table:any;
  constructor(private messageService: MessageService, private menuService: MenuService, private rabattgrupenService: RabattgrupenService, private tabViewService:TabViewService,
    public translate: TranslateService) {
      this.menuService.rabattgruppen.subscribe(item => {
      this.rabartGruppens=[];
      if(this.table != undefined){
        this.table.reset();
      }
      this.getAllrabattgrupen();
      this.create = true;
      this.validId = false;
      this.validPr = false;
      this.newCreate = false;
      this.editeOprtn = false;

      this.columnSelected = false;
      this.deleterbtGp = false;
      this.rgTest = true;
      this.prtTest = true;

      this.validId = false;
      this.validPr = false;
      this.columnSelected = false;
      this.selectedRow = null;
      this.x = null;
      this.first=0;
      this.enternumber = false;

    });
  }

  ngOnInit() {
  }

  setTable(dt){
   this.table=dt;
  }
  add(dt) {
    this.newCreate = true;
    this.rgTest = true;
    this.prtTest = true;
    if (this.create) {
      var newArr = [];

      newArr = [{
        id: { rg: null },
        prozent: null,
        createSt: true,
        editSt: false
      }]

      this.rabartGruppens = newArr.concat(this.rabartGruppens);
      dt.reset();
      this.create = false;
      this.saveRb = false;
    }
  }

  intialigation() {
    this.rabartGruppen = {
      id: { rg: null },
      prozent: null
    }
  }

  edite() {
    this.editable = false;
    this.rgTest = true;
    this.prtTest = true;
    this.selectedRow['editSt'] = true;
    this.editeOprtn = true;

  }

  save() {
    this.rgTest = false;
    this.prtTest = false;
    if (this.editeOprtn) {
      this.rgTest = true;

      var rabrt2 = this.rabartGruppensReset.find(item => item.prozent == this.selectedRow.prozent);
      if (rabrt2 == undefined) {
        this.prtTest = true;
      }
      if (this.prtTest) {

        delete this.selectedRow['createSt']
        delete this.selectedRow['editSt']
        this.rabattgrupenService.createRabattgrupen(this.selectedRow).subscribe(item => {
          this.create = true;
          this.saveRb = true;
          this.editeOprtn = false;
          this.columnSelected = false;
          this.getAllrabattgrupen();
          this.tabViewService.emitRabert("update");
          this.messageService.add({ detail: this.translate.instant('rabt_updt'), key: 'c' });
        });
      }
    }
    else {
      var rabrt1 = this.rabartGruppensReset.find(item => item.id.rg == this.rabartGruppens[0].id.rg);
      var rabrt2 = this.rabartGruppensReset.find(item => item.prozent == this.rabartGruppens[0].prozent);

      if (rabrt1 == undefined) {
        this.rgTest = true;
      }
      if (rabrt2 == undefined) {
        this.prtTest = true;
      }

      if (this.rgTest && this.prtTest) {

        delete this.rabartGruppens[0]['createSt']
        delete this.rabartGruppens[0]['editSt']
        this.rabattgrupenService.createRabattgrupen(this.rabartGruppens[0]).subscribe(item => {
          this.create = true;
          this.saveRb = true;
          this.columnSelected = false;
          this.getAllrabattgrupen();
          this.newCreate = false;

          this.tabViewService.emitRabert("create");
          this.messageService.add({ detail: this.translate.instant('rabt_create'), key: 'c' });
        });
      }
    }
  }

  setColumn(rbg, i) {
    this.selectedRow = rbg;
    this.x = i;

  }

  onRowSelect(event, i) {
    this.columnSelected = true;
    this.selectedRow = event;
    this.x = i;
  }

  getAllrabattgrupen() {
    setTimeout(() =>{
      this.display = true;
      },300);
    this.x = null;
    this.selectedRow = null;
    this.columnSelected = false;
    this.rabattgrupenService.getAllRabattgrupen().subscribe(item => {
      this.rabartGruppensReset = item.data;

      this.rabartGruppensReset = this.rabartGruppensReset.sort((data1, data2) => {
        let value1 = data1.prozent;
        let value2 = data2.prozent;
        if (value1 > value2) {
          return 1;
        }

        if (value1 < value2) {
          return -1;
        }

        return 0;
      });

      this.rabartGruppens = JSON.parse(JSON.stringify(this.rabartGruppensReset));
      this.rabartGruppens.forEach(item => {
        item['createSt'] = false;
        item['editSt'] = false;
      });
    });
  }


  validPrt(data) {

    this.validPr = false;
    
    if (data.target.value < 0 || data.target.value > 100) {

      this.validPr = true;
      this.editeOprtn = false;
    }
    else{
      this.saveRb=false;
    }

  }

  validIds(data) {

    this.validId = false;
  
    if (data.target.value < 0) {
      this.validId = true;
      this.editeOprtn = false;
    }
    else{
      this.saveRb = false;
    }

  }

  
  chkinpt(e) {
    var regexnumber = "^[0-9]+$";
    if (e.target.value.match(regexnumber)) {
      this.enternumber = false;
      return true;
    }
    else {
      this.enternumber = true;
      return false;
    }
  }

  deleteRabertGropen() {
    this.rabattgrupenService.deleteRabertGrupen(this.selectedRow.id.rg).subscribe(item => {
      this.getAllrabattgrupen();
      // if (item == false) {
      if (item != null) {
        this.deleterbtGp = false;
        this.messageService.add({ detail: this.translate.instant('rabt_del'), key: 'c' });
      }
      else {
        this.deleterbtGp = false;
        this.messageService.add({ severity: 'error', detail: this.translate.instant('rabt_del_artassociation'), key: 'e' });
      }
    });
  }
}
