import { Injectable, } from '@angular/core';
import { Subject } from 'rxjs';

export class DeshboardMetaData {
  action: string = 'home';
  projection: string;
  criteria: any;
}


@Injectable({
  providedIn: 'root'
})
export class DashboardTableService {
  private callback: Function;

  private tableDataForTable;


  public subscribe(callbackI: Function) {
    if (this.callback === undefined) {
      this.callback = callbackI;
      this.callback(new DeshboardMetaData());
      return;
    }
    this.callback = callbackI;

  }
  constructor() { }

  public loadTableData(value?: DeshboardMetaData) {
    if (this.callback === undefined) {
      return;
    }
    if (value === undefined) {
      this.callback(new DeshboardMetaData());
    } else {
      this.callback(value);

    }
  }

  public resetTableData() {
    this.callback(null);
  }



}

