import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Literals } from '../literals/literals-service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { MenuService } from './menu.service';
import { TabViewService } from '../tab-view/tab-view.service';
import * as $ from 'jquery';
import { Flatpricedto } from '../../dto/flatpricedto';
import * as moment from "moment";
import { Router } from '@angular/router';
import { ArticleService } from '../services/article.service';
import { MessageService } from 'primeng/api';
import { DashboardTableService, DeshboardMetaData } from '../component/dashboard-table/dashboard-table.service';
import { isNotPermittedByRole } from '../app.controller-permission.direcitve';
import { UserService } from 'app/services/UserService';
import { User } from 'dto/UserModel';
import { ModelSeries } from 'dto/ModelSeries';
import { ManageuserDTO } from '../../dto/manageuser-dto';


//declare var moment: any;

interface Role {
    name: string;
    code: string;
}
interface City {
    name: string;
    code: string;
}

export class DashboardMetaData {
    action: string = 'home';
    projection: string;
    criteria: any;
}

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService]
})
export class MenuComponent implements OnInit {

    translator: LangChangeEvent;
    userrole: Role[];

    selectedCity: City;

    selectedCities: City[];

    items: MenuItem[];

    flatpricedetail: Flatpricedto;

    articlestatuserror: boolean = false;
    validPer: boolean = false;
    validnegPer: boolean = false;
    flatPriceAdjustment: boolean = false;
    start: any;
    end: any;
    prestoCheck: boolean = true;
    firstNm : String='';
    lastNm : String='';


    minDateValue: Date;
    dateerror: boolean = false;
    dateerror1: boolean = false;
    enterperval: boolean = false;
    prestoerr: boolean = false;
    prestoerr1: boolean = false;
    prestoerr2: boolean = false;
    resulterr: boolean = false;
    dateTime: Date;
    currentRole: String = '';
    user: User;
    productmngervalues: any[] = [];
    productmanagerItems: any[] = [];
    abteilung: ModelSeries[] = [];
    product = [];
    manageuser: ManageuserDTO;
    departmentId;
    userRole;
    userId;
    tnumResp: String;
    tnumRespFlag: boolean = false;
    benutzerResponseString: string = '';

    constructor(public translate: TranslateService, private menuService: MenuService, private tabviewservice: TabViewService, private dashboardService: DashboardTableService,
        private articleservice: ArticleService, private messageservice: MessageService, private router: Router, private userService: UserService) {
        this.userrole = [
            { name: '', code: '' },
            { name: 'SUPERUSER', code: 'SU' },
            { name: 'VIEWER', code: 'VW' },
            { name: 'PM', code: 'PM' },

        ];
        this.dateTime = new Date();
        this.dateTime.setDate(1);
        this.dateTime.setMonth(0);
        this.dateTime.setFullYear(2019);

        this.articleservice.getAllProductManagers().subscribe(res => {
            this.productmngervalues = [{ name: '', code: '' }]
            res.forEach((element) => {
            this.productmngervalues.push({ name: element, code: element });
            this.productmanagerItems = this.productmngervalues;
            });
            this.productmanagerItems = this.productmanagerItems.slice(1);
        });

    }


    // downloadFile(){
    //             let link = document.createElement("a");
    //             link.download = "German.pdf";
    //             link.href = "/assets/German.pdf";
    //             link.click();
    //     }

        downloadFile1(){
            window.open("assets/Nuzuco_application_description.pdf" );
            }
    initialization() {
        let d = new Date();
        d.setMonth(11);
        d.setDate(31);
        d.getFullYear();
        let adjustdate = new Date();
        adjustdate.setDate(1);
        adjustdate.setMonth(0);
        adjustdate.setFullYear(adjustdate.getFullYear() + 1);
        this.flatpricedetail = {
            changeInPercent: 3,
            basicDate: d,
            adjustmentDate: adjustdate,
            prestoCheck: true,
            articleId: '',
            status: ''
        }
        this.prestoerr = false;
        this.prestoerr1 = false;
        this.prestoerr2 = false;
        if (this.flatpricedetail.prestoCheck) {
            let d1 = this.flatpricedetail.basicDate;
            let adjustdate = moment(d1).add(4, 'days').toDate();
            this.minDateValue = adjustdate;
        }
        else {
            this.minDateValue = null;
        }
    }

    manageuserinitialization() {
        this.manageuser = {
            departmentId: null,
            userRole: '',
            userId: ''
        }
    }


    ngOnInit() {
        this.initialization();
        this.manageuserinitialization();
        this.getDepartment();
        this.userService.loggedUser.subscribe(value => {
            this.user = value;
            this.currentRole = this.user.USER_ROLE;
            this.tnumResp = this.user.RESP_PERSON;
            if (this.tnumResp !== null) {
                this.tnumRespFlag = true;
            } else {
                this.tnumRespFlag = false;
            }
            this.menuItems();
        });

    //    this.user = JSON.parse(localStorage.getItem('user'))
    //    this.firstNm = this.user.FIRST_NAME;
    //    this.lastNm = this.user.LAST_NAME;

        this.translate.onLangChange.subscribe(
            (translate: LangChangeEvent) => {
                this.translator = translate;
                this.menuItems();
            }
        );
        $(document).ready(function () {
            $('.ui-submenu-icon').removeClass('pi-caret-down')
            $('.ui-submenu-icon').addClass('fa-angle-down')
            $('.ui-menubar .ui-submenu-list .ui-menuitem-link .ui-submenu-icon ').removeClass('fa-angle-down')
            $('.ui-menubar .ui-submenu-list .ui-menuitem-link .ui-submenu-icon ').addClass('fa-angle-right')
        });

    }

    datecheck() {
        this.resulterr = false;
        if (this.flatpricedetail.prestoCheck) {
            let d1 = this.flatpricedetail.basicDate;
            let adjustdate = moment(d1).add(4, 'days').toDate();
            this.minDateValue = adjustdate;
        }
        else {
            this.minDateValue = null;
        }
    }

    showDialog(tab: String) {
        this.menuService.emitConfig(tab);
    }
    showRabattguppen() {
        this.menuService.emiteRabattgruppen();
    }
    showSearchDialog() {
        this.menuService.emiteSearchArtical();
    }

    showLefrent() {
        this.menuService.emiteLeifrent();
    }
    showAufbrauch() {
        this.menuService.emitAufbrauch();
    }
    showReichweit() {
        this.menuService.emitReichweit();
    }

    showFlatPriceAdjustment() {
        this.resulterr = false;
        let articlestatus = localStorage.getItem("articlestatus");
        this.initialization();
        if (articlestatus == "Freigabe/Aktiv" || articlestatus == 'Auf Aufbrauch') {
            this.flatPriceAdjustment = true;
        }
        else {
            this.articlestatuserror = true;
        }
    }

    searchItemsWithoutPriceAdjustment: boolean = false;

    showSearchItemsWithoutPriceAdjustment() {
        this.searchItemsWithoutPriceAdjustment = true;
    }

    completeExport: boolean = false;

    showCompleteExport() {
        this.completeExport = true;
    }
    showKorrek() {
        this.menuService.emitKorrek();
    }
    showUbermit() {
        this.menuService.emitUbermit();
    }
    showPreis() {
        this.menuService.emitPreis();
    }

    showImports(vlaue) {
        this.menuService.emiteImport(vlaue);
    }
    showTeileMenu() {
        this.tabviewservice.emitMenuTeile();
    }

    showPartNum() {
        this.menuService.emitPartNum();
    }

    showArchive() {
        this.menuService.emitArchive();
    }
    showEmail() {
        this.menuService.emitEmail();
    }

    showDateiZuordDialog() {
        this.menuService.emitDateiZuord();
    }

    telienumberZuweisen: boolean = false;

    showTelienumberZuweisen(value) {
        this.menuService.emitPartNumAssignment(value);
    }

    benutzerRechteVerwalten: boolean = false;

    showBenutzerRechteVerwalten() {
        this.departmentId = null;
        this.userRole = null;
        this.userId = null;
        this.benutzerResponseString = null;
        this.benutzerRechteVerwalten = true;
    }

    showBaureiheZuord() {
        this.menuService.emitBaureiheZuord();
    }

    showDeckung() {
        this.menuService.emitDeckung();
    }
    showArtikelUber() {
        this.menuService.emitArticleUber();
    }

    showAssignStaff() {
        this.menuService.emitStaff();
    }
    validpercentage(x) {

        this.validPer = false;
        this.validnegPer = false;
        this.enterperval = false;
        this.resulterr = false;
        if (x.target.value < -0.99) {
            this.validnegPer = true;
        }
        if (x.target.value > 100) {
            this.validPer = true;
        }
    }

    senddata() {
        this.flatpricedetail.articleId = localStorage.getItem('article_id');
        this.flatpricedetail.status = localStorage.getItem('articlestatus');
        if (this.flatpricedetail.status == 'Freigabe/Aktiv') {
            this.flatpricedetail.status = 'F';
        }
        if (this.flatpricedetail.status == 'Auf Aufbrauch') {
            this.flatpricedetail.status = 'A';
        }
        if (!this.flatpricedetail.changeInPercent) {
            this.enterperval = true;
        }

        else {
            this.enterperval = false;
            if (this.flatpricedetail.changeInPercent > -99 || this.flatpricedetail.changeInPercent < 100) {
                this.articleservice.evaluateflatprice(this.flatpricedetail).subscribe(res => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                    let finalobj = JSON.parse(resJSON._body);
                    if (finalobj.result == true) {
                        this.resulterr = false;
                        this.flatPriceAdjustment = false;
                        this.messageservice.add({ detail: this.translate.instant('article_change'), key: 'c' });
                    }
                    else if (finalobj.result == false || finalobj.result == null) {
                        if (finalobj.statusString == "OK") {
                            this.resulterr = true;
                        }
                        else if (finalobj.statusString == "FAIL") {
                            if (finalobj.error == "PRESTO-Benachrichtigung fehlgeschlagen !") {
                                this.prestoerr = true;
                            }
                            else if (finalobj.error == "Data not available") {
                                this.prestoerr1 = true;
                            }
                            else {
                                this.prestoerr2 = true;
                            }
                       }
                    }
                });
            }
        }
        this.tabviewservice.emitUpdatServic(localStorage.getItem('article_id'));
    }


    public getDepartment() {
        this.articleservice.getDepartment().subscribe(item => {
            this.abteilung = [];
            var deprtLst: ModelSeries[] = [];
            deprtLst = [{ id: '', name: '' }];
            item.forEach(dp => {
            var department: ModelSeries = new ModelSeries();
            department.id = dp.abteilung_id;
            department.name = dp.name;
            deprtLst.push(department);
        });
            this.abteilung = deprtLst;
        });
    }

    submituserright() {
        this.manageuser.departmentId = this.departmentId.id;
        this.manageuser.userRole = this.userRole.name;
        this.manageuser.userId = this.userId;
        this.articleservice.senduserrights(this.manageuser).subscribe((res) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        let finalobj = JSON.parse(resJSON._body);
        //this.benutzerRechteVerwalten = false;
        if(finalobj.result == 'In der DB gibt es einen Benutzer mit diesen Parametern.')
        {
        this.benutzerResponseString = 'There is a user with these parameters in the database.';
        }
        else if(finalobj.result == 'Benutzer wurde erfolgreich zur Datenbank hinzugefügt.')
            {
            this.benutzerResponseString = 'User has been successfully added to the database.';
            }
            else {
                this.benutzerResponseString  = finalobj.result ;
            }
        })
    }

    menuItems() {
        this.items = [
            {
                label: this.translator.translations['FILE'],
                items: [
                    {
                        disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER', 'NUZUCO.VIEWER']), label: this.translator.translations['Zuordnung'], command: (event) => { this.showDateiZuordDialog(); }
                    },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER', 'NUZUCO.VIEWER']), label: this.translator.translations['Neue_Suche'], command: (event) => { this.menuService.emitDialog('search_artical'); } }
                ]
            },
            {
                label: this.translator.translations['PART_NUMBERS'],
                items: [
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER']) || this.tnumRespFlag, label: this.translator.translations['Teilenummern_verwalten'], command: (event) => { this.showPartNum(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER']) || this.tnumRespFlag, label: this.translator.translations['Teilenummern_zuweisen'], command: (event) => { this.menuService.emitDialog('partNumAssign'); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER']) || this.tnumRespFlag, label: this.translator.translations['Teilenummernliste_exportieren'], command: (event) => { this.showTeileMenu(); } }
                ]
            },
            {
                label: this.translator.translations['IMPORT'],
                icon: 'fa-angle-down',
                disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']),
                command: (event) => { this.showImports("ek"); }
            },
            {
                label: this.translator.translations['EVALUATION'],
                items: [
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Pauschale_Preisanpassungen'], command: (event) => { this.showFlatPriceAdjustment(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Artikel_ohne_Preisanpassung_suchen'], command: (event) => { this.menuService.emitDialog('preisanpassung'); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER']), label: this.translator.translations['Deckungsbeitragsrechnung'], command: (event) => { this.menuService.emitDialog('deckung'); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER', 'NUZUCO.VIEWER']), label: this.translator.translations['Reichweitenanalyse'], command: (event) => { this.menuService.emitDialog('reichweit'); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER', 'NUZUCO.VIEWER']), label: this.translator.translations['Aufbrauchlisten'], command: (event) => { this.menuService.emitDialog('aufbrauch'); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER']), label: this.translator.translations['Komplettexport'], command: (event) => { this.menuService.emitDialog('komplettexport'); } }
                ]
            },
            {
                label: this.translator.translations['USER_MANAGEMENT'],
                items: [
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER']), label: this.translator.translations['Artikel_ubergeben'], command: (event) => { this.showArtikelUber(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Benutzer/Rechte_verwalten'], command: (event) => { this.showBenutzerRechteVerwalten(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR']), label: this.translator.translations['Assign_Staff'], command: (event) => { this.showAssignStaff(); } }
                ]
            },
            {
                label: this.translator.translations['ADMINISTRATION'],
                items: [
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.PRODMGR', 'NUZUCO.SUPERUSER', 'NUZUCO.VIEWER']), label: this.translator.translations['Lieferanten'], command: (event) => { this.showLefrent(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Baureihen/Zuordnungen'], command: (event) => { this.showBaureiheZuord(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Rabattgruppen'], command: (event) => { this.showRabattguppen(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Archivierte_Dokumente'], command: (event) => { this.showArchive(); } },
                    { disabled: isNotPermittedByRole(this.currentRole, ['NUZUCO.SUPERUSER']), label: this.translator.translations['Email_Verwaltung'], command: (event) => { this.showEmail(); } }]
            },
            {
                label: this.translator.translations['HELP'],
                items: [
                     { label: this.translator.translations['NuZuCo_Hilfe'], command: (event) => { this.downloadFile1(); }},
                    // { label: this.translator.translations['NuZuCo_Info'], command: (event) => { this.downloadFile();} }
                   // { label: this.translator.translations['NuZuCo_Hilfe'], url: 'http://localhost:8082/Nuzuco_application_description.pdf', target: '_blank' },
                    
                ]
            }
        ]
    }

    omit_special_char(event) {
        var k;
        document.all ? k = event.keyCode : k = event.which;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

}
