import { Component, OnInit } from '@angular/core';


import { Flatpricedto } from '../../dto/flatpricedto';
import * as moment from "moment";
import { Router } from '@angular/router';

import { ArticleService } from '../services/article.service';

import { MessageService } from 'primeng/api';

import { DashboardTableService, DeshboardMetaData } from '../component/dashboard-table/dashboard-table.service';
import { MenuService } from '../menu/menu.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

export class DashboardMetaData {
  action: string = 'home';
  projection: string;
  criteria: any;
}


interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-komplettexport',
  templateUrl: './komplettexport.component.html',
  styleUrls: ['./komplettexport.component.scss']
})
export class KomplettexportComponent implements OnInit {
  criteria: Search = new Search();
  projection: string[] = [];
  cities: City[];
  productmngervalues: any[] = [];
  productmanagerItems: any[] = [];
  userdetl: any;

  constructor(private dashboardService: DashboardTableService,
    private articleservice: ArticleService, private router: Router,
    private menuService: MenuService,private spinnerService: Ng4LoadingSpinnerService) {
    this.cities = [
      { name: 'Bettina Junghanss', code: 'NY' },
      { name: 'Britta Zalder', code: 'RM' },
      { name: 'Christina Simon', code: 'LDN' },
      { name: 'Christina Schroder', code: 'IST' },
      { name: 'Eric Thiam', code: 'PRS' },
      { name: 'Fabian Schilling', code: 'USA' },
      { name: 'Frank Bauer', code: 'IND' },
      { name: 'Gerhard Senger', code: 'ERP' },
      { name: 'Gunter Paul', code: 'GP' },
      { name: 'Ingo Wimmer', code: 'IW' },
      { name: 'Joachim Lukas', code: 'JL' },
      { name: 'Jochen Kirschbaun', code: 'JK' }
    ];
    this.articleservice.getAllProductManagers().subscribe(res => {
      this.productmngervalues = [{ name: '', code: '' }]
      res.forEach((element) => {
        this.productmngervalues.push({ name: element, code: element });
        this.productmanagerItems = this.productmngervalues;
  });
      this.productmanagerItems = this.productmanagerItems.slice(1);
      let userdetaildept = localStorage.getItem("user");
      this.userdetl = JSON.parse(userdetaildept);
      this.productmanagerItems.forEach(element => {
        if (element.name == this.userdetl.USER_ID) {
            this.criteria.productManager = [{
            name: this.userdetl.USER_ID, code: this.userdetl.USER_ID
          }]
        }
      });
  });
  }

  ngOnInit() {
    var d = new Date();
    var currMonth = d.getMonth();
    var currYear = d.getFullYear()
    let date1 = new Date(currYear , 0 , 1);
    let date2 = new Date(currYear-1 , 0 , 1);
    let date3 = new Date(currYear-2 , 0 , 1);
    this.criteria.valueDatesList = [date1, date2, date3];
    this.criteria.selectedStatuslist = ["F","A"];
  }

  onHide() {
    this.menuService.emitDialog('');
  }
  //PA search
  public searchCompletExport() {
    const meta = new DashboardMetaData();
    if (this.criteria.selectedStatuslist.length == 0) {
      this.criteria.selectedStatuslist = ["F", "A", "Z", "X", "S", "A"];
    }
    meta.action = 'NEWSEARCH';
    const criteria = this.criteria.clone();
    if (criteria.productManager) {
      let productManger = [];
      criteria.productManager.forEach(item => {
        productManger.push(item.code);
      });
      criteria.productManager = productManger;
    }
    meta.projection += "teilenummer,status,pm,produktidee,Name,Benennung,NAMEDE,DATUMFREIGABE,"
    +"NAMEFR, NAMEES, NAMEEN,teileinfo1,teileinfo2,macode,sortimentsklasse,Erstdispositio,"
    +"bestand,vk,rg,wa,ek,LAST_YEAR_ABGANG,last_year_1_ABGANG,last_year_2_ABGANG,last_year_3_ABGANG,benennung,"
    +"vk1,rg1,wa1,ek1,vk2,rg2,wa2,ek2";
    meta.criteria = criteria;
    this.spinnerService.show();
    this.dashboardService.loadTableData(meta);
    this.spinnerService.hide();
    this.onHide();

  }

}

class Search {
  branch: 0;
  department: 0;
  productManager: any[];
  supplier: 0;
  selectedAssignmentsList: any[];
  patsFamily: 0;
  assortmentGroup: 0;
  selectedStatuslist: any[];
  prestoSystem: false;
  requiredColumnNames: any;
  valueDatesList: any;

  clone(): Search {
    return Object.assign({}, this) as Search;
  }
}