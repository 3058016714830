import { Directive, HostListener, ElementRef, OnInit, Input } from "@angular/core";
import { PartNumber } from "../pipe/part-number-pipe";



@Directive({
  selector: '[appFormatnumber]'
})

export class FormatnumberDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: PartNumber
  ) {
    this.el = this.elementRef.nativeElement;
    this.el.value = this.currencyPipe.transform(this.el.value);

  }

  ngOnInit() {
    this.el.value.replace(/\s/g, "");
    this.el.value = this.currencyPipe.transform(this.el.value);
  }


  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    value = value.replace(/ +/g, "");
    this.el.value = this.currencyPipe.transform(value); // opossite of transform
  }
  
  @HostListener("change", ["$event.target.value"])
  onChange(value) {
    value = value.replace(/ +/g, "");
    this.el.value = this.currencyPipe.transform(value); // opossite of transform
  }
}
