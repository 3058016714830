import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable, from } from 'rxjs';
import { Constants } from '../constants';
import {User} from '../../dto/UserModel';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
    
   }

    headers = new HttpHeaders()
  .set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`) ;


   
   login(id) : Observable<any>{
    return this.http.get(`${Constants.HOST}/login/${id}` , {withCredentials: true}); 
  }

  customLogout(): Observable<any>{
    return this.http.get(`${Constants.HOST}/logout`);
  }

  public loginDetails(): Observable<any>{
    return this.http.get(`${Constants.HOST}/loginDetails`, { 'headers': this.headers });
  }


  //  options = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
     // // 'Access-Control-Allow-Origin': 'http://localhost:4200'
  //   })
  // };


  public logout(): Observable<any>{
    return this.http.get(`${Constants.HOST}/customLogout`,{ responseType: 'text' });
  }

}
