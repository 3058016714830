import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
  })
  export class RoleService {
      userRole : String;
      articleOwnerPM : String;
      loggedInUserID : String;
      statusOfArticle : String;
  constructor(private http: HttpClient) {
    
   }

   get getUserRole(): String {
    return this.userRole;
}

set setUserRole(userRoleVar: String) {
    this.userRole=userRoleVar ;
}

get getArticleOwnerPM(): String {
    return this.articleOwnerPM;
}

set setArticleOwnerPM(articleOwnerPMVar: String) {
    this.articleOwnerPM=articleOwnerPMVar ;
}

get getLoggedInUserID(): String {
    return this.loggedInUserID;
}

set setLoggedInUserID(loggedInUserIDVar: String) {
    this.loggedInUserID=loggedInUserIDVar ;
}

get getStatusOfArticle(): String {
    return this.statusOfArticle;
}

set setStatusOfArticle(statusOfArticleVar: String) {
    this.statusOfArticle=statusOfArticleVar ;
}
}