import { Component, OnInit } from '@angular/core';
import { MenuService } from 'app/menu/menu.service';
import { TabViewService } from 'app/tab-view/tab-view.service';
import { TreeNode } from 'primeng/api';
import { element } from '@angular/core/src/render3/instructions';
import { BaureihePostDto } from 'dto/BaureihePosDto';
import { BauvariantePostDto } from 'dto/BauvariantePostDto';
import { BauvariantentypPostDto } from 'dto/BauvariantentypPostDto';
import { ZuordnungService } from 'app/services/zuordnung.service';
import { DeleteBautypDto } from 'dto/DeleteBautypDto';
import { DeleteBauvariantDto } from 'dto/DeleteBauvariantDto';
import { DeleteBaureiheDto } from 'dto/DeleteBaureiheDto';
import { TranslateService } from '@ngx-translate/core';

import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-baureihe-zuordnungen',
  templateUrl: './baureihe-zuordnungen.component.html',
  styleUrls: ['./baureihe-zuordnungen.component.scss'],
  providers: [MessageService]
})
export class BaureiheZuordnungenComponent implements OnInit {

  baureiheZuord: boolean;
  files: TreeNode[];

  baureiheList: Dropdowns[] = [];
  bauvariantentypList: Dropdowns[] = [];
  bauvarianteList: Dropdowns[] = [];
  selectedBauvariante: Dropdowns;
  selectedBauvariantentyp: Dropdowns;
  selectedBaureihe: Dropdowns;

  dropdowns: TreeNode[];
  selectedFile: TreeNode;
  variantDisableFlag: boolean = false;
  bautypDisableFlag: boolean = false;
  textempty: boolean = false;

  constructor(private messageService: MessageService, private menuService: MenuService, private tabViewService: TabViewService, private zuordnungService: ZuordnungService,
    public translate: TranslateService) {
    this.menuService.baureiheZuord.subscribe(item => {
      this.textempty = false;
      this.getBaureiheData();

    });
  }

  ngOnInit() {

  }

  copyNodeLabelToAntherLabel(node) {
    node.forEach(e => {
      e.extra = e.label;
      if (e.children && e.children instanceof Array) {
        this.copyNodeLabelToAntherLabel(e.children);
      }
    });
  }

  getBaureiheData() {
    this.baureiheZuord = true;
    this.tabViewService.getAdminBaureiheData().subscribe(res => {
      this.files = <TreeNode[]>res.data;
      this.copyNodeLabelToAntherLabel(this.files);
      this.dropdowns = <TreeNode[]>res.data;
      this.baureiheList = [];
      this.dropdowns.forEach(node => {
        var baureihen: Dropdowns = new Dropdowns();
        baureihen.name = node.label;
        baureihen.code = node.data;

        this.baureiheList.push(baureihen);

        var childrenbautypList: Dropdowns[] = [];
        if (node.children) {
          node.children.forEach(element => {
            var bauvariantentyp: Dropdowns = new Dropdowns();
            bauvariantentyp.name = element.label;
            bauvariantentyp.code = element.data;

            childrenbautypList.push(bauvariantentyp);
            var bauvarChildrenList: Dropdowns[] = [];
          });
        }
      });
      var selBaureihe: TreeNode;
      var anelgen: Dropdowns = new Dropdowns();
      anelgen.name = "anelgen";
      anelgen.code = 'AN_BAUREIHE';
      this.baureiheList.push(anelgen);
      this.selectedBaureihe = this.baureiheList[0];
      if (this.selectedBaureihe.name !== "anelgen") {
        selBaureihe = this.dropdowns.find(x => x.label === this.selectedBaureihe.name);
        this.bauvariantentypDropdownSel(selBaureihe);
      }
    });
  }

  focusOutMethod(node: TreeNode) {

    if (node.label == '' || node.label == null) {
      this.textempty = true;
    }
    node.label = node.label.trim();
    if (node.label.length > 0) {
      node.label = node.label;
    }
    else {
      this.textempty = true;
      return;
    }
    if (node.label === (node as any).extra) {
      return;
    }

    if (!((node as any).extra && (node as any).extra.length > 0)) {
      if (node.label === 'Add New Baureihe') {
        return;
      }
    }
    this.selectedFile = node;
    var childrenbautypList: Dropdowns[] = [];
    if (node.parent) {
      let splitArray = node.parent.data.split(",");
      if (splitArray.length === 1) {
        var bauvariantentypDto: BauvariantentypPostDto = new BauvariantentypPostDto();
        bauvariantentypDto.baureiheId = +(splitArray[0]);
        bauvariantentypDto.benennung = node.label;
        if (node.data) {
          let splitBautyp = node.data.split(",");
          bauvariantentypDto.bauvariantentypId = +splitBautyp[1];
        } else {
          bauvariantentypDto.bauvariantentypId = null;
        }
        if (this.checkValidations(bauvariantentypDto.benennung)) {
          this.zuordnungService.createBauvariantetyp(bauvariantentypDto).subscribe(
            (res: any) => {
              if (res) {
                var bautyp = res;
                this.selectedBauvariantentyp.name = bautyp.benennung;
                this.selectedBauvariantentyp.code = bautyp.id.bauvariantentypId;
                this.messageService.add({ detail: ((node as any).extra && (node as any).extra.length > 0) ? this.translate.instant('Erfolgreich_geupdated') : this.translate.instant('bau_create'), key: 'c' });
                this.getBaureiheData();
              }
            });
        } else {
          return this.getBaureiheData();
        }

      } else if (splitArray.length === 2) {
        var bauvarianteDto: BauvariantePostDto = new BauvariantePostDto();
        bauvarianteDto.bauvariantentypId = +(splitArray[1]);
        bauvarianteDto.benennung = node.label;
        if (node.data) {
          let splitVariant = node.data.split(",");
          bauvarianteDto.bauvarianteId = +splitVariant[2];
        } else {
          bauvarianteDto.bauvarianteId = null;
        }
        if (this.checkValidations(bauvarianteDto.benennung)) {
          this.zuordnungService.createBauvariante(bauvarianteDto).subscribe(
            (res: any) => {
              if (res) {
                var bauvar = res;
                this.selectedBauvariante.name = bauvar.benennung;
                this.selectedBauvariante.code = bauvar.id.bauvariantenId;
                this.messageService.add({ detail: ((node as any).extra && (node as any).extra.length > 0) ? this.translate.instant('Erfolgreich_geupdated') : this.translate.instant('bauriente'), key: 'c' });
                this.getBaureiheData();
              }
            });
        }
      } else {
        return this.getBaureiheData();
      }
    } else {
      var baureiheDto: BaureihePostDto = new BaureihePostDto();
      baureiheDto.benennung = node.label;
      if (node.data) {
        baureiheDto.id = node.data;
      } else {
        baureiheDto.id = null;
      }

      if (this.checkValidations(baureiheDto.benennung)) {
        this.zuordnungService.createBaureihe(baureiheDto).subscribe(
          (res: any) => {
            if (res) {
              var baureihe = res;
              this.selectedBaureihe.name = baureihe.benennung;
              this.selectedBaureihe.code = baureihe.id.baureihenId;
              this.messageService.add({ detail: ((node as any).extra && (node as any).extra.length > 0) ? this.translate.instant('Erfolgreich_geupdated') : this.translate.instant('baurie'), key: 'c' });
              this.getBaureiheData();
            }
          });
      } else {
        return this.getBaureiheData();
      }
    }
  }

  keyupmethod() {
    this.textempty = false;
  }

  templateClicked() {
    alert("TemplateClicked");
  }

  bauvariantentypDropdownSel(baureihe: TreeNode) {
    this.bauvariantentypList = [];
    var childrenbautypList: Dropdowns[] = [];
    this.variantDisableFlag = false;
    if (baureihe) {
      baureihe.children.forEach(element => {
        var bauvariantentyp: Dropdowns = new Dropdowns();
        bauvariantentyp.name = element.label;
        bauvariantentyp.code = element.data;
        childrenbautypList.push(bauvariantentyp);
      });

      if (baureihe.children.length === 0) {
        var createBauTyp: Dropdowns = new Dropdowns();
        createBauTyp.name = null;
        createBauTyp.code = "null";
        this.bauvariantentypList.push(createBauTyp);
        this.variantDisableFlag = true;
      }
    }
    var anelgenBauvariantentyp: Dropdowns = new Dropdowns();
    anelgenBauvariantentyp.name = "anelgen";
    anelgenBauvariantentyp.code = 'AN_BAUTYP';

    if (childrenbautypList.length > 0) {
      this.bauvariantentypList = childrenbautypList;
    }
    this.bauvariantentypList.push(anelgenBauvariantentyp);
    var selBauvariantentyp: TreeNode;
    this.selectedBauvariantentyp = this.bauvariantentypList[0];
    if (baureihe) {
      if (baureihe.children && this.selectedBauvariantentyp.name !== "anelgen") {
        selBauvariantentyp = baureihe.children.find(x => x.label === this.selectedBauvariantentyp.name);
      }
    }
    this.bauvarianteDrpdwnSel(selBauvariantentyp);
  }

  bauvarianteDrpdwnSel(bauvariantentyp: TreeNode) {
    this.bauvarianteList = [];
    var bauvarChildrenList: Dropdowns[] = [];
    if (bauvariantentyp) {
      if (bauvariantentyp.children) {
        bauvariantentyp.children.forEach(ele => {
          var bauvarianten: Dropdowns = new Dropdowns();
          bauvarianten.name = ele.label;
          bauvarianten.code = ele.data;
          bauvarChildrenList.push(bauvarianten);
        });
      }
    }
    if (bauvariantentyp) {
      if (bauvariantentyp.children.length === 0) {
        var createBau: Dropdowns = new Dropdowns();
        createBau.name = null;
        createBau.code = "null";
        this.bauvarianteList.push(createBau);
      }
    }
    var anelgenBauvarianten: Dropdowns = new Dropdowns();
    anelgenBauvarianten.name = "anelgen";
    anelgenBauvarianten.code = 'AN_BAUVARIANT';
    if (bauvarChildrenList.length > 0) {
      this.bauvarianteList = bauvarChildrenList;
    }
    this.bauvarianteList.push(anelgenBauvarianten);
    this.selectedBauvariante = this.bauvarianteList[0];
  }

  selectedBaureiheInDrpdwn() {
    if (this.selectedBaureihe.name !== "anelgen") {
      var selBaureihe: TreeNode;
      selBaureihe = this.dropdowns.find(x => x.label === this.selectedBaureihe.name);
      this.selectedFile = selBaureihe;
      $("#zuordnungenTree").animate({ scrollTop: $("#" + this.selectedFile.data)[0].offsetTop - 100 }, 900);
    } else if (this.selectedBaureihe.name === "anelgen") {
      var newBaureihe: TreeNode = {};
      newBaureihe.label = "Add New Baureihe";
      this.files.push(newBaureihe);
      this.selectedFile = newBaureihe;
      this.selectedFile.expanded;
      $("#zuordnungenTree").animate({ scrollTop: 1000 }, 900);

    }
    this.bauvariantentypDropdownSel(selBaureihe);
  }

  selectedBauvariantentypInDrpdwn() {
    var seleBauvariantentyp: TreeNode;
    var selBaureihe: TreeNode;
    selBaureihe = this.dropdowns.find(x => x.label === this.selectedBaureihe.name);
    if (this.selectedBauvariantentyp.name !== "anelgen") {
      this.selectedFile.expanded = true;
      if (selBaureihe.children) {
        seleBauvariantentyp = selBaureihe.children.find(x => x.label === this.selectedBauvariantentyp.name);
      }
      this.bauvarianteDrpdwnSel(seleBauvariantentyp);
      this.selectedFile = seleBauvariantentyp;
      $("#zuordnungenTree").animate({ scrollTop: $("#" + seleBauvariantentyp.data)[0].offsetTop }, 900);
    } else if (this.selectedBauvariantentyp.name === "anelgen") {
      var baureiheSel: TreeNode = {};
      baureiheSel = this.dropdowns.find(x => x.label === this.selectedBaureihe.name);
      if (baureiheSel.children) {
        var newBauvatyp: TreeNode = {};
        newBauvatyp.label = "Add New Bauvariantentyp";
        baureiheSel.children.push(newBauvatyp);
        this.selectedFile = baureiheSel;
        this.selectedFile.expanded = true;
        $("#zuordnungenTree").animate({ scrollTop: $("#" + seleBauvariantentyp.data)[0].offsetTop }, 900);
      }
    }
  }

  selectedauvariantenInDrpdwn() {
    var baureiheSel: TreeNode = {};
    var bauvariantentypSel: TreeNode = {};
    var newBauvariant: TreeNode = {};
    this.selectedFile.expanded = true;
    if (this.selectedBauvariante.name !== "anelgen") {
      baureiheSel = this.dropdowns.find(x => x.label === this.selectedBaureihe.name);
      bauvariantentypSel = baureiheSel.children.find(x => x.label === this.selectedBauvariantentyp.name);
      $("#zuordnungenTree").animate({ scrollTop: $("#" + bauvariantentypSel.data)[0].offsetTop }, 900);
    } else {
      baureiheSel = this.dropdowns.find(x => x.label === this.selectedBaureihe.name);
      bauvariantentypSel = baureiheSel.children.find(x => x.label === this.selectedBauvariantentyp.name);
      newBauvariant.label = "Add New Bauvariante";
      bauvariantentypSel.children.push(newBauvariant);
      this.selectedFile = bauvariantentypSel;
      this.selectedFile.expanded = true;
      $("#zuordnungenTree").animate({ scrollTop: $("#" + bauvariantentypSel.data)[0].offsetTop }, 900);

    }
  }

  deleteBaureihe() {
    var baureiheDelDto: DeleteBaureiheDto = new DeleteBaureiheDto();
    baureiheDelDto.baureiheId = +this.selectedBaureihe.code;
    baureiheDelDto.name = this.selectedBaureihe.name;

    this.tabViewService.deleteSelectedBaureihe(baureiheDelDto).subscribe(
      (res: any) => {
        if (res) {
          this.messageService.add({ detail: this.translate.instant('baureihe_del'), key: 'c' });
          this.getBaureiheData();
        }
      }
    );

  }

  deleteBauvariantentyp() {
    var bauvarTypDel: DeleteBautypDto = new DeleteBautypDto();
    let splitArray = this.selectedBauvariantentyp.code.split(",");
    bauvarTypDel.bauTypId = +splitArray[1];
    bauvarTypDel.baureiheId = +this.selectedBaureihe.code;
    bauvarTypDel.name = this.selectedBauvariantentyp.name;

    this.tabViewService.deleteSelectedBauvarTyp(bauvarTypDel).subscribe(
      (res: any) => {
        if (res) {
          this.messageService.add({ detail: this.translate.instant('bauraih_type'), key: 'c' });
          this.getBaureiheData();
        }
      }
    );

  }

  deleteBauvariante() {
    var deleteBauvariant: DeleteBauvariantDto = new DeleteBauvariantDto();
    let splitArray = this.selectedBauvariante.code.split(",");
    deleteBauvariant.bauTypId = +splitArray[1];
    deleteBauvariant.bauvarId = +splitArray[2];
    deleteBauvariant.name = this.selectedBauvariante.name;

    this.tabViewService.deleteSelectedBauvariante(deleteBauvariant).subscribe(
      (res: any) => {
        if (res) {
          this.messageService.add({ detail: this.translate.instant('bauri_del'), key: 'c' });
          this.getBaureiheData();
        }
      }
    );

  }


  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }

  checkValidations(value) {
    var flag = true;
    if (value === null || value === "undefined" || value === 'undefined' || value === "") {
      flag = false;
    }
    return flag;
  }
}

class Dropdowns {
  name: string;
  code: string;
}
