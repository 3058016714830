import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component'
import { LoginComponentComponent } from './login-component/login-component.component';
import { SearchArticalComponent } from './search-artical/search-artical.component';
import { ReichweitComponent } from './reichweit/reichweit.component';
import { DeckungComponent } from './deckung/deckung.component';
import { ArtikelPreisanpassungComponent } from './artikel-preisanpassung/artikel-preisanpassung.component';

import { KomplettexportComponent } from './komplettexport/komplettexport.component';
import { LogoutComponent } from './logout/logout.component';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [{ path: 'dashboard', component: DashboardComponent },
{
  path: 'dashboard',
  component: DashboardComponent
},
{
  path: 'login',
  component: LoginComponentComponent,
},
{
  path: 'logout',
  component: LogoutComponent,
}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
