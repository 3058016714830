import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { ArticleService } from '../services/article.service';
import { MessageService } from 'primeng/api';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  assignstaff: boolean = false;
  productmanager: any[];
  productmngervalues: any[];
  selectedproductmanagers: any;
  beneuid:boolean= false;
  constructor(private messageService: MessageService, private menuService: MenuService,
    private articleService: ArticleService, public translate: TranslateService) {
    this.menuService.AssignStaff.subscribe(value => {
    this.showDialog();
    this.selectedproductmanagers = [{ name: '', code: '' }];
    })
  }

  selectedpm() {
    this.beneuid = false;
  }
  submit() {
    // if (this.selectedproductmanagers == null) {
    //   this.beneuid = true;
    // }
    // else if (this.selectedproductmanagers.name == "") {
    //   this.beneuid = true;
    // }
    // else
    // {
    this.articleService.assignTNum(this.selectedproductmanagers.code).subscribe(res => {
    this.assignstaff = false;
    if (res.result === true) {
      this.messageService.add({ detail: this.translate.instant('per_success'), key: 'c' });
    } else if (res.result === false) {
      this.messageService.add({ severity: 'error', detail: this.translate.instant('personal_err'), key: 'e' });
    }

    });
  //}
  }

  showDialog() {
    this.assignstaff = true;
    this.productmngervalues = [{ name: 'Kein Mitarbeiter zugeordnet', code:null }]
    this.articleService.getAllProductManagersForTNum().subscribe(res => {
    res.result.forEach((element) => {
    this.productmngervalues.push({ name: element, code: element });
    this.productmanager = this.productmngervalues;
    let userdtl = JSON.parse(localStorage.getItem("user"));
    if (userdtl.RESP_PERSON == null) {
       this.selectedproductmanagers = [{ name: '', code: '' }];
    }
    else {
        this.selectedproductmanagers = { name: userdtl.RESP_PERSON, code: userdtl.RESP_PERSON };
      }
      });
    });
  }
  ngOnInit() {
  }

}
