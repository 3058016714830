import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { ArticleService } from '../services/article.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DashboardTableService, DeshboardMetaData } from '../component/dashboard-table/dashboard-table.service';

export class DashboardMetaData {
  action: string = 'home';
  projection: string;
  criteria: any;
}
@Component({
  selector: 'app-article-delete',
  templateUrl: './article-delete.component.html',
  styleUrls: ['./article-delete.component.scss'],
  providers: [MessageService]
})
export class ArticleDeleteComponent implements OnInit {

  @Output('dataModal')
  dataModal: EventEmitter<string> = new EventEmitter<string>();
  display: boolean = false;
  errselrow: boolean = false;
  articleId = '';
  articles: any = [];
  constructor(private menuService: MenuService, private articleService: ArticleService, private router: Router,private dashboardService: DashboardTableService,
    private messageService: MessageService, public translate: TranslateService, private spinnerService: Ng4LoadingSpinnerService) {

    this.menuService.configObservable.subscribe(value => {
      if (value == 'delete') {
        this.showDialog();
      }
    })
  }

  ngOnInit() {
  }
  showDialog() {
    this.display = true;
  }
  submit() {
    this.articleId = localStorage.getItem('article_id');
    this.spinnerService.show();
    this.articleService.deleteArticle(this.articleId).subscribe(
      (res: any) => {
        if (res) {
          this.spinnerService.hide();
          this.display = false;
          this.loadTable();
          this.messageService.add({ detail: this.translate.instant('article_delete'), key: 'c' });
        }
      }
    );
  }

  //Refrreshing table
  public loadTable(){
    const meta = new DashboardMetaData();  
    meta.action = 'delete';
    this.dashboardService.loadTableData(meta);
  }
}