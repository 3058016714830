export const de_literals = {
  FILE: 'Datei',
  PART_NUMBERS: 'Teilenummer',
  PRINTING_HOUSE: 'DrucKerei',
  IMPORT: 'Import',
  EVALUATION: 'Auswertung',
  USER_MANAGEMENT: 'Benutzerverwaltung',
  ADMINISTRATION: 'Administration',
  HELP: 'Hilfe'
};
