import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'partNumber'})
export class PartNumber implements PipeTransform {
  transform(value: string): string {
    if(value === "ATn"){
      return '-angefordert-';
    }
  let x=2;
  let y=2;

  
    let newStr: string = "";
    if(value!=null){
    for (var i = 0; i <= value.length - 1; i++) {
    if(i==x){
    y++;
    x+=y;
    newStr +=" "
    }
      newStr += value.charAt(i);
      
    }
    }
    
    return newStr;
  }
}

