import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { HttpModule, BrowserXhr } from '@angular/http';
import {TableModule} from 'primeng/table';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {MenubarModule} from 'primeng/menubar';
import { MenuComponent } from './menu/menu.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import {ToolbarModule} from 'primeng/toolbar';
import { AtricleStatusComponent } from './atricle-status/atricle-status.component';
import { TabViewComponent } from './tab-view/tab-view.component';
import {TabViewModule} from 'primeng/tabview';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { Literals } from './literals/literals-service';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import { AppRoutingModule } from './app-routing.module';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import { ArticleService } from './services/article.service';
import { AtricleCreateComponent } from './atricle-create/atricle-create.component';
import { ArticleDeleteComponent } from './article-delete/article-delete.component';
import { routes } from './route/route.config';
import { SearchArticalComponent } from './search-artical/search-artical.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MultiSelectModule} from 'primeng/multiselect';
import {PartNumber} from './pipe/part-number-pipe';
import {TreeviewModule} from 'ngx-treeview';
import { SupplierComponent } from './supplier/supplier.component';
import {LieferantService} from '../app/services/lieferant.service';
import { ExcelService} from '../app/services/excel.service'
import { from } from 'rxjs';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { RabattgruppenComponent } from './rabattgruppen/rabattgruppen.component'; 
import {RabattgrupenService} from '../app/services/rabbattgrupen.service'
import {ListboxModule} from 'primeng/listbox';
import {ToastModule} from 'primeng/toast';
import {InputMaskModule} from 'primeng/inputmask';
import {TooltipModule} from 'primeng/tooltip';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { AufBrauchComponent } from './auf-brauch/auf-brauch.component';
import { ReichweitComponent } from './reichweit/reichweit.component';
import { ImportComponent } from './import/import.component';
import { MenuArchiveComponent } from './menu-archive/menu-archive.component';
import { MenuEmailComponent } from './menu-email/menu-email.component';
import { CsvService} from '../app/services/csv.service';
import { DateiZuordnungComponent } from './datei-zuordnung/datei-zuordnung.component';
import { FormatnumberDirective } from './directive/formatnumber.directive';
import {TreeModule} from 'primeng/tree';
import {TreeNode, MessageService} from 'primeng/api';
//Document service
import {DocumentService} from "../app/services/documentFile.service"
import { ZuordnungService } from './services/zuordnung.service';
import { BaureiheZuordnungenComponent } from './baureihe-zuordnungen/baureihe-zuordnungen.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { LoginService } from './services/login.service';
import { DeckungComponent } from './deckung/deckung.component';
import { ArticleuberComponent } from './articleuber/articleuber.component';
import { CustomBrowserXhr } from 'auth/CustomBrowserXhr';
import { DashboardTableComponent } from './component/dashboard-table/dashboard-table.component';
import { KomplettexportComponent } from './komplettexport/komplettexport.component';
import { ArtikelPreisanpassungComponent } from './artikel-preisanpassung/artikel-preisanpassung.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import {UserService} from '../app/services/UserService';
import { AppControllerPermission } from './app.controller-permission.direcitve';
import { PersonalComponent } from './personal/personal.component';
import { PartNumAssignComponent } from './part-num-assign/part-num-assign.component';
import { NumberFormatterPipe } from './pipe/number-formatter-pipe';
import { LogoutComponent } from './logout/logout.component';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ToolbarComponent,
    AtricleStatusComponent,
    TabViewComponent,
    AtricleCreateComponent,
    ArticleDeleteComponent,
    SearchArticalComponent,
    PartNumber,
    SupplierComponent,
    RabattgruppenComponent,
    AufBrauchComponent,
    ReichweitComponent,
    ImportComponent,
    MenuArchiveComponent,
    MenuEmailComponent,
    DateiZuordnungComponent,
    FormatnumberDirective,
    BaureiheZuordnungenComponent,
    LoginComponentComponent,
    DeckungComponent,
    ArticleuberComponent,
    DashboardTableComponent,
    KomplettexportComponent,
    ArtikelPreisanpassungComponent,
    AppControllerPermission,
    PersonalComponent,
    PartNumAssignComponent,
    NumberFormatterPipe,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RadioButtonModule,
    AppRoutingModule,
    RouterModule.forRoot(routes, {useHash : true}), 
    MsalModule.forRoot({
      auth: {
        clientId: 'e927044b-2a9e-4524-a294-6bdffbc2ff8e',
        authority: "https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1",
        validateAuthority: true,
        redirectUri: "https://tad-int.aftersales.daimlertruck.com/",
        postLogoutRedirectUri: "https://tad-int.aftersales.daimlertruck.com/",
        // redirectUri: "http://localhost:4200/",
        // postLogoutRedirectUri: "http://localhost:4200/",
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        popUp: !isIE,
        consentScopes: [
          "openid",
          "profile",
          "email"
        ],
        protectedResourceMap,
        extraQueryParameters: {}
      }
    ),
    TreeviewModule.forRoot(),
    DialogModule,
    TableModule,
    MenubarModule,
    ToolbarModule,
    TabViewModule,
    HttpClientModule,
    TreeModule,
    AngularFontAwesomeModule,
    DropdownModule,
    BrowserAnimationsModule,
	CheckboxModule,
	ButtonModule,
  CalendarModule,
  ToastModule,
  InputMaskModule,
  MessagesModule,
  MessageModule,
  ReactiveFormsModule,
  Ng4LoadingSpinnerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AutoCompleteModule,
    MultiSelectModule,
    NgHttpLoaderModule.forRoot(),
  ListboxModule,
  TooltipModule,
  
  ],
  providers: [Literals,ArticleService,LieferantService,ExcelService,RabattgrupenService,CsvService,DocumentService,ZuordnungService,LoginService,UserService,MessageService,
    {provide : BrowserXhr, 
        useClass: CustomBrowserXhr },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

